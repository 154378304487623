import React, { useMemo } from "react";
import { graphql } from "gatsby";
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image";
import withPreview from "../../utility/with-preview";
import classNames from "classnames";
import { Link } from "../link";
import Slider from "react-slick";
import Cta from "../elements/cta";

const BoxVantaggio = ({ item, infoBoxesLink, singleBox }) => {
  return (
    <div className="scopri-di-piu__box d-flex align-items-center flex-column flex-md-row">
      <Img
        className="scopri-di-piu__img"
        image={getImage(item.content.boxImage.node)}
        alt={item.content.boxImage.value?.description || ""}
      />
      <div className={classNames("scopri-di-piu__text flex-grow-1 text-left", singleBox && "px-0")}>
        <div className="scopri-di-piu__title" style={{ color: item.content?.colorBoxTitle?.value }}>
          {item.value}
        </div>
        {item.content?.boxContent?.value && (
          <div
            className="scopri-di-piu__desc"
            dangerouslySetInnerHTML={{ __html: item.content.boxContent.value }}
          />
        )}
        {item.content?.readMoreLink?.value && item.content.readMoreLink.value !== "#" && (
          <Link
            to={item.content.readMoreLink.value}
            title="Leggi tutto"
            className="scopri-di-piu__link"
            target="_blank"
          >
            {infoBoxesLink?.value}
          </Link>
        )}
      </div>
    </div>
  );
};

const ScopriDipiu = ({ data }) => {
  const { title, subtitle, boxTitle, infoBoxesLink, cta } = data;

  const settings = useMemo(
    () => ({
      arrows: false,
      slidesPerRow: 1,
      rows: boxTitle?.length,
      responsive: [
        {
          breakpoint: 769,
          settings: {
            className: "center",
            centerMode: false,
            infinite: false,
            slidesToShow: 1,
            rows: 1,
            speed: 500,
            swipe: true,
            dots: true,
            adaptiveHeight: false,
          },
        },
      ],
    }),
    [boxTitle]
  );

  return (
    <section className="section scopri-di-piu wrapper-container">
      <div className="container">
        {title?.value && (
          <div className="row">
            <div className="col-12">
              <h1
                className="section__title text-center"
                style={{ color: title.content?.colorTitle?.value }}
              >
                {title.value}
              </h1>
            </div>
          </div>
        )}
        {subtitle?.value && (
          <div className="row">
            <div
              className={classNames("col-md-12", {
                "text-left": subtitle.content?.subtitlePosition?.value?.[0] === "align-left",
                "text-center":
                  subtitle.content?.subtitlePosition?.value?.[0] === "align-center" ||
                  !subtitle.content?.subtitlePosition?.value?.length,
                "text-right": subtitle.content?.subtitlePosition?.value?.[0] === "align-right",
              })}
            >
              <h3
                className="scopri-di-piu__subtitle"
                dangerouslySetInnerHTML={{ __html: subtitle.value }}
              />
            </div>
          </div>
        )}
        {boxTitle?.length && (
          <div className="row">
            <div className="col-12">
              <Slider className="box-vantaggio__slider" {...settings}>
                {boxTitle.map((item, i) => (
                  <div key={i}>
                    <BoxVantaggio
                      item={item}
                      singleBox={boxTitle?.length === 1}
                      infoBoxesLink={infoBoxesLink}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        )}
        <div className="row mt-3">
          <div className="col-12 text-center">
            <Cta cta={cta} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(ScopriDipiu);
export const fragment = graphql`
  fragment JskScopriDiPiuFragment on LiferayJskScopriDiPiu {
    liferayFields {
      siteId
      articleId
    }
    title {
      value
      content {
        colorTitle {
          value
        }
      }
    }
    subtitle {
      value
      content {
        subtitlePosition {
          value
        }
      }
    }
    boxTitle {
      value
      content {
        colorBoxTitle {
          value
        }
        boxImage {
          node {
            gatsbyImageData
          }
          value {
            description
          }
          content {
            linkImg {
              value
            }
          }
        }
        boxContent {
          value
        }
        readMoreLink {
          value
        }
      }
    }
    infoBoxesLink {
      value
    }
    cta {
      value
      content {
        ctaLink {
          value
        }
        colorebottone {
          value
        }
        webContent {
          value {
            ... on LiferayJskLayoutPaginaGenerica {
              link
            }
            ... on LiferayJskPaginaLink {
              externalLink: link {
                value
              }
            }
          }
        }
      }
    }
  }
`;
