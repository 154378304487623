import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import classNames from "classnames";
import { throttle } from "lodash";
import Cta from "../elements/cta";
import withPreview from "../../utility/with-preview";

const Timeline = ({ data }) => {
  const { title, step, timelineColor, cta } = data;
  const [textHeight, setTextHeight] = useState();
  const getStepIcon = (step) => {
    return withArtDirection(getImage(step.content.icon.node), [
      {
        media: "(max-width: 992px)",
        image: getImage(step.content.icon.nodeMobile),
      },
    ]);
  };

  useEffect(() => {
    const listener = throttle(() => {
      const textElems = Array.from(document.getElementsByClassName("timeline__step-text"));
      setTextHeight(Math.max(...textElems?.map((elem) => elem.getBoundingClientRect().height)));
    }, 200);
    window.addEventListener("resize", listener);
    listener();
    return () => window.removeEventListener("resize", listener);
  }, []);

  return (
    <section className="section timeline">
      <div className="wrapper-container">
        <div className="container-fluid">
          {title?.value && (
            <div className="row">
              <div className="col-md-12 text-center">
                <h2
                  className="section__title"
                  style={{ color: title.content?.colorTitle?.value }}
                  dangerouslySetInnerHTML={{ __html: title.value }}
                ></h2>
              </div>
            </div>
          )}
          {step?.length ? (
            <div className="row">
              <div className="col-md-12">
                <div className={`d-flex flex-column flex-lg-row num-step-${step.length}`}>
                  {step.map((item, i) => (
                    <div
                      key={i}
                      className={classNames(
                        "timeline__step",
                        "d-lg-flex flex-column justify-content-end align-items-lg-center",
                        {
                          "timeline__step--first": i === 0,
                        }
                      )}
                    >
                      <span
                        className="timeline__step-before"
                        style={{ backgroundColor: timelineColor?.value }}
                      ></span>
                      {item.content?.icon?.node && (
                        <div
                          className="timeline__step-icon d-inline-flex justify-content-center align-items-center"
                          style={{ backgroundColor: timelineColor?.value }}
                        >
                          <GatsbyImage image={getStepIcon(item)} alt="" />
                        </div>
                      )}
                      <div className="timeline__step-num">{i + 1}.</div>
                      <div
                        className="timeline__step-text"
                        dangerouslySetInnerHTML={{ __html: item.value }}
                        style={{ minHeight: textHeight }}
                      />
                      {item.content?.mobileDescription?.value && (
                        <div className="timeline__step-desc mb-auto">
                          {item.content.mobileDescription.value}
                        </div>
                      )}
                      <span
                        className="timeline__step-after"
                        style={{ backgroundColor: timelineColor?.value }}
                      ></span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}
          <Cta cta={cta} />
        </div>
      </div>
    </section>
  );
};

export default withPreview(Timeline);
export const fragment = graphql`
  fragment JskTimelineFragment on LiferayJskTimeline {
    liferayFields {
      siteId
      articleId
    }
    title {
      value
      content {
        colorTitle {
          value
        }
      }
    }
    timelineColor {
      value
    }
    step {
      value
      content {
        mobileDescription {
          value
        }
        icon {
          node {
            gatsbyImageData(formats: PNG, width: 50, layout: FIXED)
          }
          nodeMobile: node {
            gatsbyImageData(formats: PNG, width: 30, layout: FIXED)
          }
        }
      }
    }
    cta {
      value
      content {
        colorebottone {
          value
        }
        ctaLink {
          value
        }
      }
    }
  }
`;
