import React, { useState } from "react";
import { graphql } from "gatsby";
import classNames from "classnames";
import withPreview from "../../utility/with-preview";
import NavTabs from "../elements/nav-tabs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "../link";
import { handleContentAccessibility } from "../../utility/accessibility";

const ProdottoSlide = ({ slide, image, slidesNumber, formId, inSlider }) => {
  return (
    <div
      className={classNames("box-opzioni d-flex flex-column", {
        "box-opzioni--single": slidesNumber === 1,
        "box-opzioni--double": slidesNumber === 2,
        "box-opzioni--in-slider": inSlider,
      })}
      style={{ color: slide.backgroundColorBox?.value }}
    >
      <div
        className="box-opzioni__head d-flex justify-content-center align-items-center"
        style={{ backgroundImage: slide.colorBox?.value }}
      >
        {image && (
          <div className="box-opzioni__head-img-container">
            <GatsbyImage className="box-opzioni__head-img" image={getImage(image)} alt="icon" />
          </div>
        )}
        <div
          className="box-opzioni__title"
          dangerouslySetInnerHTML={{ __html: slide.titleBox?.value }}
        ></div>
      </div>
      <div className="box-opzioni__body flex-fill d-flex flex-column">
        <div
          className={classNames(
            "box-opzioni__description-box",
            slide.radioSection?.value !== "yes" && "mb-auto"
          )}
        >
          {handleContentAccessibility(slide.descriptionBox?.value)}
        </div>
        {slide.radioSection?.value === "yes" && (
          <>
            {slide.lineBox?.map((item, k) => (
              <div
                className="box-opzioni__line-box d-flex justify-content-center align-items-center mx-auto p-4"
                key={k}
              >
                <div className="box-opzioni__cross-wrapper">
                  <div
                    className="box-opzioni__cross box-opzioni__cross--horizontal"
                    style={{ background: slide.colorBox?.value }}
                  ></div>
                  <div
                    className="box-opzioni__cross box-opzioni__cross--vertical"
                    style={{ background: slide.colorBox?.value }}
                  ></div>
                </div>
                <div className="flex-fill">
                  <div className="box-opzioni__section-content">
                    {item.content?.newSectionBox?.value}
                  </div>

                  {item.content?.newSectionBox?.content?.sectionDetail?.value && (
                    <div
                      className="box-opzioni__section-detail pt-3"
                      dangerouslySetInnerHTML={{
                        __html: item.content.newSectionBox.content.sectionDetail.value,
                      }}
                    ></div>
                  )}
                  {item.content?.newSectionBox?.content?.infoDetail?.value && (
                    <div className="container-info tooltipInfo">
                      <span>
                        <img className="imagine-info-detail" alt="info" />
                      </span>
                      <span
                        className="tooltiptext"
                        dangerouslySetInnerHTML={{
                          __html: item.content?.newSectionBox?.content?.infoDetail?.value,
                        }}
                      ></span>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </>
        )}
        {slide.radioPrice?.value === "yes" ? (
          <div className="box-opzioni__price-box mt-auto text-center">
            <div className="d-flex justify-content-center">
              {slide.priceBox?.content?.isHtml?.value &&
              slide.priceBox?.content?.htmlPrice?.value ? (
                <div
                  className="box-opzioni__price-html"
                  dangerouslySetInnerHTML={{
                    __html: slide.priceBox.content.htmlPrice.value,
                  }}
                />
              ) : (
                slide.priceBox?.value && (
                  <div className="box-opzioni__price">{slide.priceBox.value} €</div>
                )
              )}
            </div>
            {slide.priceBox?.content?.priceDescription?.value && (
              <div
                className="box-opzioni__price-detail"
                dangerouslySetInnerHTML={{
                  __html: slide.priceBox.content.priceDescription.value,
                }}
              />
            )}
            {slide.radioButton?.value === "yes" && (
              <div className="mt-4">
                <Link
                  to={
                    formId?.value?.includes("form") ||
                    slide.linkButton.value.startsWith(
                      "#p_p_id_Contatti_WAR_Contattiportlet_INSTANCE_"
                    )
                      ? "#FormContattiAnchor"
                      : slide.linkButton.value
                  }
                  className="box-opzioni__btn cta-link d-inline"
                >
                  {slide.buttonBox.value}
                </Link>
              </div>
            )}
          </div>
        ) : (
          slide.radioButton?.value === "yes" && (
            <div className="text-center my-4">
              <Link
                to={
                  formId?.value?.includes("form") ||
                  slide.linkButton.value.startsWith(
                    "#p_p_id_Contatti_WAR_Contattiportlet_INSTANCE_"
                  )
                    ? "#FormContattiAnchor"
                    : slide.linkButton.value
                }
                className="box-opzioni__btn cta-link d-inline"
              >
                {slide.buttonBox.value}
              </Link>
            </div>
          )
        )}
      </div>
    </div>
  );
};

const OpzioniProdotto = ({ data }) => {
  const {
    liferayFields: { articleId },
    /*idUnivoco,*/
    mainTitle,
    imageBox,
    formId,
  } = data;
  const [selected, setSelected] = useState(0);

  return (
    <section
      className="section opzioni-prodotto wrapper-container opzioni-prodotto--modello-mobile-tab"
      style={{ overflowX: "hidden" }}
    >
      <div className="container">
        {mainTitle?.value && (
          <div className="row">
            <div className="col-md-12 text-center">
              <div
                className="section__title opzioni-prodotto__title"
                dangerouslySetInnerHTML={{ __html: mainTitle.value }}
              ></div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12 px-0 px-md-3">
            <NavTabs
              tabs={imageBox?.map((item) => ({ value: item?.content?.titleBox?.value }))}
              selectedTab={selected}
              setTab={setSelected}
              className="d-lg-none"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-center flex-wrap flex-lg-nowrap px-0 px-md-3">
            {imageBox?.map((slide, j) => (
              <div
                className={classNames(
                  "opzioni-prodotto__slide-container mx-lg-3 mb-5 mb-lg-0",
                  j === selected && "opzioni-prodotto__slide-container--selected"
                )}
                key={j}
              >
                <ProdottoSlide
                  articleId={articleId}
                  slide={slide.content}
                  j={j}
                  image={slide.node}
                  slidesNumber={imageBox.length}
                  formId={formId}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(OpzioniProdotto);
export const fragment = graphql`
  fragment JskOpzioniProdottoFragment on LiferayJskOpzioniProdotto {
    liferayFields {
      siteId
      articleId
    }
    mainTitle {
      value
    }
    mainTitle {
      value
    }
    imageBox {
      node {
        gatsbyImageData(width: 80, layout: FIXED)
      }
      content {
        titleBox {
          value
        }
        colorBox {
          value
        }
        backgroundColorBox {
          value
        }
        radioLabel {
          value
        }
        labelBox {
          value
        }
        titleBox {
          value
        }
        descriptionBox {
          value
        }
        radioSection {
          value
        }
        lineBox {
          content {
            newSectionBox {
              value
              content {
                sectionDetail {
                  value
                }
                infoDetail {
                  value
                }
              }
            }
          }
        }
        radioPrice {
          value
        }
        priceBox {
          value
          content {
            priceDescription {
              value
            }
          }
        }
        radioButton {
          value
        }
        buttonBox {
          value
        }
        newPage {
          value
        }
        linkButton {
          value
        }
        customAdvId {
          value
        }
      }
    }
    formId {
      value
    }
  }
`;
