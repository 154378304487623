import React, { useState } from "react";
import { graphql } from "gatsby";
import InfoDialog from "../info-dialog";
import SeeMore from "../elements/see-more";
import withPreview from "../../utility/with-preview";

const ContattiCanali = ({ data }) => {
  const { title, channel } = data;
  const [modalIdx, setModalIdx] = useState(-1);
  const showModal = (event) => setModalIdx(parseInt(event.currentTarget.name));
  const setModalVisible = (value, i) =>
    setModalIdx((current) => (value ? i : current === i ? -1 : current));
  return (
    <section className="section contatti-canali wrapper-container">
      <div className="container-fluid">
        {title?.value && (
          <div className="row">
            <div className="col-12">
              <div className="contatti-canali__title">{title.value}</div>
            </div>
          </div>
        )}
        <div className="row justify-content-center">
          {channel.slice(0, 2).map((item, i) => (
            <div className="col-md-3 d-flex justify-content-center mb-4 mb-md-0" key={i}>
              <button
                className="contatti-canali__item text-center d-flex flex-md-column mb-md-2 align-items-center"
                aria-label={item.value}
                name={i}
                onClick={(e) => item.content?.channelMessage?.value && showModal(e)}
              >
                <div className="contatti-canali__item-title d-inline-flex justify-content-center align-items-center">
                  <h3>{item.value}</h3>
                </div>
                {item.content?.channelSubtitle?.value && (
                  <div
                    className="contatti-canali__item-subtitle pl-3 pl-md-0 pt-md-3"
                    dangerouslySetInnerHTML={{
                      __html: item.content.channelSubtitle.value,
                    }}
                  ></div>
                )}
                <SeeMore color="light-blue" className="d-none d-lg-inline-block" />
              </button>
              <InfoDialog
                show={modalIdx === i}
                setShow={(value) => setModalVisible(value, i)}
                text={item.content?.channelMessage?.value}
                link={item.content?.link?.value}
                label={item.content?.label?.value}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default withPreview(ContattiCanali);
export const contattiCanaliFragment = graphql`
  fragment JskContattiCanaliFragment on LiferayJskContattiCanali {
    liferayFields {
      siteId
      articleId
    }
    title {
      value
    }
    channel {
      value
      content {
        channelSubtitle {
          value
        }
        channelMessage {
          value
        }
        link {
          value
        }
        label {
          value
        }
      }
    }
  }
`;
