import React from "react";
import parse, { domToReact } from "html-react-parser";
import classNames from "classnames";

const overlayTextParser = (text, setPrivacyModalVisible, theme) => {
  const opt = {
    replace: ({ attribs, children }) => {
      if (attribs?.class?.indexOf("open-overlay") >= 0) {
        return (
          <>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href=""
              onClick={(event) => {
                event.preventDefault();
                setPrivacyModalVisible(true);
              }}
              className={classNames(theme === "nj" && "contact-form__privacy-link")}
            >
              {domToReact(children, opt)}
            </a>
          </>
        );
      }
    },
  };

  return text && parse(text, opt);
};

const PrivacyCheckboxInput = ({ register, text, setPrivacyModalVisible, theme }) => {
  return (
    <>
      {theme === "nj" ? (
        <input
          type="checkbox"
          name="privacy"
          id="privacy"
          {...register("privacy", {
            required: {
              value: true,
              message: "Acconsentire al trattamento dei dati.",
            },
          })}
        />
      ) : (
        <span className="form__checkbox-wrapper">
          <input
            type="checkbox"
            name="privacy"
            id="privacy"
            {...register("privacy", {
              required: {
                value: true,
                message: "Acconsentire al trattamento dei dati.",
              },
            })}
          />
          <span className="checkmark"></span>
        </span>
      )}
      {theme === "nj" && (
        <span className="nj-checkbox__decorator">
          <span className="nj-checkbox__check"></span>
        </span>
      )}
      {text ? (
        overlayTextParser(text, setPrivacyModalVisible, theme)
      ) : (
        <>
          <strong>Acconsento&nbsp;</strong>l'utilizzo dei dati personali per le finalità
          <button
            type="button"
            onClick={() => setPrivacyModalVisible(true)}
            className={classNames(theme === "nj" ? "contact-form__privacy-link" : "")}
          >
            indicate
          </button>
        </>
      )}
    </>
  );
};

const PrivacyCheckbox = ({ register, error, setPrivacyModalVisible, text, theme = "default" }) => {
  return theme === "nj" ? (
    <>
      <div className="nj-form-group">
        <div className="nj-checkbox">
          <label className="contact-form__privacy" htmlFor="privacy">
            <PrivacyCheckboxInput
              register={register}
              text={text}
              setPrivacyModalVisible={setPrivacyModalVisible}
              theme={theme}
            />
          </label>
        </div>
      </div>
      {error && <span className="contact-form__warning">{error.message}</span>}
    </>
  ) : (
    <div className="form__element form__element--checkbox">
      <label className="form__label" htmlFor="privacy">
        <PrivacyCheckboxInput
          register={register}
          text={text}
          setPrivacyModalVisible={setPrivacyModalVisible}
          theme={theme}
        />
      </label>
      {error && <p className="form__error">{error.message}</p>}
    </div>
  );
};

export default PrivacyCheckbox;
