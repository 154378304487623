import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage as Img, getImage, withArtDirection } from "gatsby-plugin-image";
import classNames from "classnames";
import Cta from "../elements/cta";
import withPreview from "../../utility/with-preview";

const SlideHeaderSameB2C = ({ data }) => {
  const { titolo, descrizione, descrizioneMobile, slide, slideTablet, slideMobile, cta } = data;
  const headerImg = withArtDirection(
    getImage(slide?.node),
    [
      slideMobile?.node && {
        media: "(max-width: 768px)",
        image: getImage(slideMobile.node),
      },
      slideTablet?.node && {
        media: "(max-width: 992px)",
        image: getImage(slideTablet.node),
      },
    ].filter(Boolean)
  );
  return (
    <section className="slide-header-same-b2c">
      <Img
        image={headerImg}
        imgClassName="slide-header-same-b2c__img"
        alt={slide.value?.description || ""}
        className="slide-header-same-b2c__img-wrapper"
      />
      <div className="slide-header-same-b2c__content">
        <div className="slide-header-same-b2c__bar"></div>
        <h1
          className="slide-header-same-b2c__title"
          dangerouslySetInnerHTML={{ __html: titolo?.value }}
        ></h1>
        <div
          className={classNames(
            "slide-header-same-b2c__desc",
            !descrizioneMobile?.value && "d-none d-lg-block"
          )}
          dangerouslySetInnerHTML={{ __html: descrizione?.value }}
        ></div>
        {cta?.value && cta?.content?.ctaLink?.value /*&& cta?.content?.ctaLink?.value !== '#'*/ && (
          <div className="row">
            <div className="col-12">
              <Cta
                cta={{
                  ...cta,
                  content: {
                    ...cta?.content,
                    coloreBottone: {
                      value: cta?.content?.ctaColor?.value,
                    },
                  },
                }}
              />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default withPreview(SlideHeaderSameB2C);
export const slideHeaderSameB2CFragment = graphql`
  fragment SlideHeaderSameB2CFragment on LiferaySlideHeaderSameB2C {
    liferayFields {
      articleId
      siteId
    }
    titolo {
      value
    }
    descrizione {
      value
    }
    descrizioneMobile {
      value
    }
    slide {
      node {
        gatsbyImageData
      }
      value {
        description
      }
    }
    slideTablet {
      node {
        gatsbyImageData
      }
    }
    slideMobile {
      node {
        gatsbyImageData
      }
    }
    cta {
      value
      content {
        ctaColor {
          value
        }
        ctaLink {
          value
        }
      }
    }
  }
`;
