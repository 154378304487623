import React, { useCallback, useState } from "react";
import { graphql } from "gatsby";
import withPreview from "../../utility/with-preview";
import { useForm } from "react-hook-form";
import PrivacyModal from "../form/privacy-modal";
import { FormFileInput, FormSelect, FormTextInput } from "../form/form-input";
import PrivacyCheckbox from "../form/privacy-checkbox";

const FORM_PARTNER = process.env.GATSBY_FORM_PARTNER;


const FormPartnerCommerciale = ({ data }) => {
  const { formId, title, ctaLabel, testoFlagPrivacy, successMessage } = data;
  const [submitted, setSubmitted] = useState();
  const [remoteError, setRemoteError] = useState();
  const [privacyModalVisible, setPrivacyModalVisible] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submit = useCallback(async (values) => {
    const formData = new FormData();
    console.log(values);
    formData.append("tipologia", values.tipologia);
    formData.append("codiceFiscale_piva", values.codiceFiscale);
    formData.append("nome", values.nome);
    formData.append("cognome", values.cognome);
    formData.append("email", values.email);
    formData.append("telefono", values.telefono);
    formData.append("mercatoPrevalente", values.mercatoPrevalente);
    formData.append("categoria", "PMIDiventaPartner");
    formData.append("cv", values.cv[0]);
    formData.append("privacy", values.privacy);

    const res = await fetch(FORM_PARTNER, {
        method: "POST",
        body: formData,
    });
    if (!res.ok) {
      throw new Error(`Error: ${res.status} ${res.statusText}`);
    }
    const result = await res.json();
    console.log(result);
   
    const error = false;
    if (!error) {
      setSubmitted(true);
    } else {
      setSubmitted(false);
      setRemoteError(true);
    }
  }, []);

  return (
    <section className="section contatti-form wrapper-container" id={formId}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3>{title?.value}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p>{title?.content?.subtitle?.value}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {submitted ? (
              successMessage?.value ? (
                <div
                  className="contatti-form__message"
                  dangerouslySetInnerHTML={{
                    __html: successMessage?.value,
                  }}
                />
              ) : (
                <div className="contatti-form__message">Grazie per aver lasciato i tuoi dati.</div>
              )
            ) : remoteError ? (
              <div className="contatti-form__message">
                Si è verificato un errore nell'invio dei dati, riprova più tardi.
              </div>
            ) : (
              <form onSubmit={handleSubmit(submit)} className="form">
                <div className="row">
                  <div className="col-md-6">
                    <FormSelect
                      register={register}
                      name="tipologia"
                      id="partnerFormTipologia"
                      placeholder="Tipologia"
                      validation={{
                        required: { value: true, message: "Specificare la tipologia." },
                      }}
                      error={errors.tipologia}
                      options={[
                        { label: "Agente", value: "agente" },
                        { label: "Agenzia", value: "agenzia" },
                      ]}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormTextInput
                      register={register}
                      name="codiceFiscale"
                      id="partnerFormCodiceFiscale"
                      placeholder="Codice Fiscale/P. IVA"
                      validation={{
                        required: {
                          value: true,
                          message: "Specificare un codice fiscale / partita iva.",
                        },
                      }}
                      error={errors.codiceFiscale}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <FormTextInput
                      register={register}
                      name="nome"
                      id="partnerFormNome"
                      placeholder="Nome"
                      validation={{
                        required: {
                          value: true,
                          message: "Specificare un nome.",
                        },
                        pattern: {
                          value: /^[a-zA-Z\s]*$/,
                          message: "Specificare un nome (solo caratteri e/o spazi).",
                        },
                      }}
                      error={errors.nome}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormTextInput
                      register={register}
                      name="cognome"
                      id="partnerFormCognome"
                      placeholder="Cognome"
                      validation={{
                        required: {
                          value: true,
                          message: "Specificare un cognome.",
                        },
                        pattern: {
                          value: /^[a-zA-Z\s]*$/,
                          message: "Specificare un cognome (solo caratteri e/o spazi).",
                        },
                      }}
                      error={errors.cognome}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <FormTextInput
                      register={register}
                      name="email"
                      id="partnerFormEmail"
                      placeholder="Indirizzo E-Mail"
                      validation={{
                        required: {
                          value: true,
                          message: "Specificare un indirizzo email.",
                        },
                        pattern: {
                          value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                          message: "Specificare un indirizzo email valido.",
                        },
                      }}
                      error={errors.email}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormTextInput
                      register={register}
                      name="telefono"
                      id="partnerFormTelefono"
                      placeholder="Numero di telefono"
                      validation={{
                        required: {
                          value: true,
                          message: "Specificare un numero di telefono.",
                        },
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Specificare un numero di telefono valido.",
                        },
                      }}
                      error={errors.telefono}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <FormSelect
                      register={register}
                      name="mercatoPrevalente"
                      id="partnerFormMercatoPrevalente"
                      placeholder="Mercato prevalente"
                      validation={{
                        required: { value: true, message: "Specificare il mercato prevalente." },
                      }}
                      error={errors.mercatoPrevalente}
                      options={[
                        {
                          label: "Residenziale Microbusiness",
                          value: "redisenziale_microbusiness",
                        },
                        { label: "PMI Aziende", value: "pmi_aziende" },
                      ]}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormFileInput
                      register={register}
                      name="cv"
                      id="partnerFormCV"
                      placeholder="CV"
                      validation={{
                        required: {
                          value: true,
                          message: "Caricare un CV o una presentazione aziendale.",
                        },
                      }}
                      error={errors.cv}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <PrivacyCheckbox
                      register={register}
                      error={errors.privacy}
                      setPrivacyModalVisible={setPrivacyModalVisible}
                      text={testoFlagPrivacy?.value}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form__submit">
                      <button type="submit" className="cta-link">
                        {ctaLabel?.value}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
        <PrivacyModal
          show={privacyModalVisible}
          setShow={setPrivacyModalVisible}
          text={testoFlagPrivacy?.content?.privacyDin?.value}
        />
      </div>
    </section>
  );
};

export default withPreview(FormPartnerCommerciale);
export const fragment = graphql`
  fragment JskFormPartnerCommercialeFragment on LiferayJskFormPartnerCommerciale {
    liferayFields {
      siteId
      articleId
    }
    formId {
      value
    }
    title {
      value
    }
    ctaLabel {
      value
    }
    testoFlagPrivacy {
      value
      content {
        privacyDin {
          value
        }
      }
    }
    successMessage {
      value
    }
  }
`;
