import React from "react";
import useBlockScroll from "../../utility/useBlockScroll";
import Modal from "react-overlays/Modal";
import logo from "../../images/logo-engie-blue.svg";
import classNames from "classnames";
import parse, { domToReact } from "html-react-parser";

const defaultPrivacyPolicy = (
  <div className="privacy">
    <div className="privacy__logo text-center">
      <img alt="Engie Italia" src={logo} style={{ height: "59px", width: "160px" }} />
    </div>
    <div className="privacy__text text-center container">
      <h2 className="privacy__title align-center">Informativa Privacy</h2>
      <hr />
      <p align="center" style={{ marginLeft: "-2.85pt" }}>
        <b>INFORMATIVA PRIVACY PER RICHIESTA DI CONTATTO TELEFONICO</b>
      </p>
      <br />
      <p align="center" style={{ marginLeft: "-2.85pt" }}>
        &nbsp;
      </p>
      <p align="center" style={{ marginLeft: "-2.85pt" }}>
        &nbsp;
      </p>
      <p style={{ marginLeft: "-2.85pt" }}>
        Se sei arrivato fin qui significa che vuoi sapere cosa fa ENGIE Italia S.p.A. (
        <b>"ENGIE"</b>) con i dati personali ("Dati Personali" o "Dati") che ti vengono chiesti
        nell'ambito della richiesta di contatto telefonico. Qui di seguito alcune informazioni che
        contribuiscono a chiarire questo aspetto e che vogliamo darti in linea con quanto previsto
        dall'art.13 del Regolamento Generale Europeo sulla Protezione dei Dati (<b>"Regolamento"</b>
        ).
        <br />
      </p>
      <p>&nbsp;</p>
      <p>
        <b>1. A chi sto dando i miei Dati?</b>
      </p>
      <p>&nbsp;</p>
      <p>
        A ENGIE Italia S.p.A., P.IVA 06289781004, con sede legale a Milano, Via Chiese 72 la quale
        agisce come titolare del trattamento.
      </p>
      <p>&nbsp;</p>
      <p>
        <b>2. A chi posso chiedere informazioni circa il trattamento dei miei Dati?</b>
      </p>
      <p style={{ marginLeft: "36.0pt" }}>&nbsp;</p>
      <p>
        Al Data Protection Officer (responsabile della protezione dei dati) di ENGIE all’indirizzo
        email{" "}
        <a className="blu transition" href="mailto:privacy-ita@ENGIE.com">
          privacy-ita@ENGIE.com
        </a>
        .
      </p>
      <p>
        A questo indirizzo potranno essere chieste informazioni su come ENGIE tratta i tuoi Dati
        Personali ed altre informazioni al riguardo.
      </p>
      <p>&nbsp;</p>
      <p>
        <b>3. Quali sono i Dati oggetto di trattamento?</b>
      </p>
      <p style={{ marginLeft: "-2.85pt" }}>&nbsp;</p>
      <p style={{ marginLeft: "-2.85pt" }}>
        Oggetto di trattamento sono i tuoi dati anagrafici (nome e cognome), il tuo numero di
        telefono e la tua e-mail, oltre alla Ragione Sociale e Partita IVA, ed in alcuni casi il
        Comune, dell’azienda per conto della quale vuoi chiedere informazioni.
      </p>
      <p style={{ marginLeft: "-2.85pt" }}>&nbsp;</p>
      <p>
        <b>4. Per quale finalità vengono chiesti i miei Dati?</b>
      </p>
      <p style={{ marginLeft: "-2.85pt" }}>&nbsp;</p>
      <p style={{ marginLeft: "-2.85pt" }}>
        Il trattamento dei tuoi Dati Personali avverrà solamente per dare seguito alla tua richiesta
        di contatto al fine di ottenere informazioni su un determinato servizio specifico o una
        determinata offerta di ENGIE Italia S.p.A. o di ENGIE Servizi S.p.A.
      </p>
      <p style={{ marginLeft: "-2.85pt" }}>
        Se temi che ENGIE voglia utilizzare i tuoi dati per altre finalità, ti rassicuriamo. Non è
        così.
      </p>
      <p>&nbsp;</p>
      <p>
        <b>
          5. Quale è la ragione per cui vengono trattati i miei Dati e cosa succede se non autorizzo
          ENGIE?{" "}
        </b>
      </p>
      <p>&nbsp;</p>
      <p>
        Il trattamento dei tuoi Dati Personali è basato sul tuo consenso quindi sei del tutto libero
        di decidere se e come procedere.
      </p>
      <p>
        Se vuoi essere contattato da ENGIE e ci autorizzi in tal senso, verrai chiamato al numero di
        telefono che hai fornito e ti daremo le informazioni che desideri.
      </p>
      <p>Se invece non vuoi essere contattato, basta che non ci autorizzi.</p>
      <p>
        Può capitare di cambiare idea e quindi potrai anche revocare un consenso già fornito. In tal
        caso però, chiaramente, sono fatte salve le eventuali attività svolte sulla base del
        consenso dato.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <b>6. Per quanto tempo vengono conservati i miei Dati personali?</b>
      </p>
      <p>&nbsp;</p>
      <p>
        I tuoi Dati Personali saranno conservati negli archivi informatici di ENGIE e protetti da
        idonee misure di sicurezza, per il tempo necessario al raggiungimento delle finalità di cui
        al precedente paragrafo 4. A seconda delle circostanze, è difficile quantificare
        precisamente il periodo; in ogni caso useremo criteri di proporzionalità, ragionevolezza e
        minimizzazione solo per gestire il contatto telefonico che ci richiedi.
      </p>
      <p>
        I tuoi Dati Personali potrebbero essere conservati per un periodo successivo in caso di
        eventuali contenziosi, richieste delle Autorità competenti o ai sensi di legge.
      </p>
      <p>&nbsp;</p>
      <p>
        <b>7. Come vengono trattati i miei Dati Personali? </b>
      </p>
      <p>&nbsp;</p>
      <p>
        Il trattamento dei Dati Personali avviene mediante strumenti informatici e manuali, con
        logiche strettamente correlate alla finalità di contatto telefonico che ci chiedi e,
        comunque, in modo da garantire la sicurezza degli stessi e sempre nel rispetto delle
        previsioni di legge.
      </p>
      <p>&nbsp;</p>
      <p>
        <b>8. Chi sono i soggetti che accedono ai miei Dati Personali?</b>
      </p>
      <p>&nbsp;</p>
      <p>
        I tuoi Dati Personali sono trattati da personale autorizzato di ENGIE ma anche da soggetti
        esterni specificamente incaricati da ENGIE (per esempio call center o consulenti esterni)
      </p>
      <p>
        Non si può escludere che in determinati casi, i tuoi Dati Personali potrebbero essere
        comunicati all’Autorità di Pubblica Sicurezza, o altre pubbliche autorità, a seguito di una
        richiesta di quest’ultima di cui ENGIE sia destinataria.
      </p>
      <p>
        I tuoi Dati Personali non verranno trasferiti al di fuori dell’Unione Europea e non vengono
        ceduti o diffusi.
      </p>
      <p>&nbsp;</p>
      <p>
        <b>9. Ho qualche diritto in relazione al fatto che vi lascio i miei Dati Personali?</b>
      </p>
      <p>&nbsp;</p>
      <p>
        Tenendo conto del campo di applicazione e dei limiti imposti dal Regolamento, in qualsiasi
        momento, avrai diritto di:
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          ottenere da ENGIE la conferma che sia o meno in corso un trattamento dei tuoi Dati
          Personali e in tal caso, di ottenere l’accesso alle informazioni di cui all’art. 15 del
          Regolamento;
        </li>
        <li>
          ottenere la rettifica dei Dati inesatti che ti riguardano, ovvero, tenuto conto delle
          finalità del trattamento, l’integrazione dei dati personali incompleti;
        </li>
        <li>
          ottenere la cancellazione dei tuoi Dati Personali, in presenza di uno dei motivi di cui
          all’art. 17 del Regolamento;
        </li>
        <li>
          ottenere la limitazione del trattamento dei tuoi dati personali nei casi previsti dalla
          legge applicabile;
        </li>
        <li>
          opporti al trattamento dei propri Dati Personali per particolari motivi ove non ci siano
          legittimi interessi cogenti di ENGIE;
        </li>
        <li>
          ricevere in un formato strutturato (es. dvd, usb), di uso comune e leggibile da
          dispositivo automatico i Dati Personali che ti riguardano forniti nonché di trasmettere
          tali dati a un altro titolare del trattamento senza impedimenti da parte di ENGIE se
          tecnicamente possibile, nei casi e nei limiti di cui all’art. 20 del Regolamento. A
          seconda dei casi e delle quantità o complessità delle informazioni richieste potrebbe
          esserti richiesto un contributo spese proporzionato al caso.
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        Per esercitare i Suoi diritti, Lei potrà seguire le istruzioni presenti alla pagina web{" "}
        <a className="blu transition" href="http://www.engie.it" target="blank">
          https://www.ENGIE.it
        </a>{" "}
        nella sezione privacy, ed inviare una richiesta, indicando in oggetto il riferimento
        "PRIVACY", tramite posta cartacea all’indirizzo ENGIE Italia S.p.A. via Chiese 72, 20126
        Milano, oppure tramite e-mail all'indirizzo{" "}
        <a className="blu transition" href="mailto:privacy-ita@ENGIE.com">
          privacy-ita@ENGIE.com
        </a>
        .
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        Fatto salvo ogni altro ricorso amministrativo o giurisdizionale, hai inoltre il diritto di
        proporre reclamo ad un’Autorità di controllo (per l’Italia: il Garante per la protezione dei
        dati personali), qualora tu ritenga che il trattamento che Ti riguarda sia effettuato in
        violazione del Regolamento generale sulla protezione dei dati. Ulteriori informazioni sono
        disponibili sul sito internet{" "}
        <a className="blu transition" href="http://www.garanteprivacy.it" target="blank">
          http://www.garanteprivacy.it
        </a>
        . Considera che comunque potrai rivolgerti a noi per risolvere, tempestivamente e in modo
        efficace, un Tuo eventuale problema “privacy” con la nostra Società; comunque ci impegniamo
        a gestire le Tue eventuali richieste con la massima cortesia e discrezione.&nbsp;
      </p>
      <p style={{ marginLeft: "-2.85pt" }}>
        Se sei arrivato fin qui significa che vuoi sapere cosa fa ENGIE Italia S.p.A. (
        <b>"Engie"</b>) con i dati personali ("Dati Personali" o "Dati") che ti vengono chiesti
        nell'ambito della richiesta di contatto telefonico. Qui di seguito alcune informazioni che
        contribuiscono a chiarire questo aspetto e che vogliamo darti in linea con quanto previsto
        dal Regolamento Generale Europeo sulla Protezione dei Dati (<b>"Regolamento"</b>).
        <br />
      </p>
      <p>&nbsp;</p>
      <p>
        <b>1. A chi sto dando i miei Dati?</b>
      </p>
      <p>&nbsp;</p>
      <p>
        A ENGIE Italia S.p.A., P.IVA 06289781004, con sede legale a Milano, Via Chiese 72 la quale
        agisce come titolare del trattamento.
      </p>
      <p>&nbsp;</p>
      <p>
        <b>2. A chi posso chiedere informazioni circa il trattamento dei miei Dati?</b>
      </p>
      <p style={{ marginLeft: "36pt" }}>&nbsp;</p>
      <p>
        Al Data Protection Officer (responsabile della protezione dei dati) di ENGIE all’indirizzo
        email dpo-ita@engie.com.
      </p>
      <p>
        A questo indirizzo potranno essere chieste informazioni su come ENGIE tratta i suoi Dati
        Personali ed altre informazioni al riguardo.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>3. Quali sono i Dati oggetto di trattamento?</strong>
      </p>
      <p style={{ marginLeft: "-2.85pt" }}>&nbsp;</p>
      <p style={{ marginLeft: "-2.85pt" }}>
        Oggetto di trattamento sono i tuoi dati anagrafici (nome e cognome), il Comune rispetto al
        quale vuoi chiedere informazioni, il tuo numero di telefono e la tua e-mail.
      </p>
      <p style={{ marginLeft: "-2.85pt" }}>&nbsp;</p>
      <p>
        <strong>4. Per quale finalità mi vengono chiesti i miei Dati?</strong>
      </p>
      <p style={{ marginLeft: "-2.85pt" }}>&nbsp;</p>
      <p style={{ marginLeft: "-2.85pt" }}>
        Il trattamento dei tuoi Dati Personali avverrà solamente per dare seguito alla tua richiesta
        di contatto al fine di ottenere informazioni su un determinato servizio specifico o una
        determinata offerta.
      </p>
      <p style={{ marginLeft: "-2.85pt" }}>
        Se temi che ENGIE voglia utilizzare i tuoi dati per altre finalità, ti rassicuriamo. Non è
        così.
      </p>
      <p>&nbsp;</p>
      <p>
        <b>
          5. Quale è la ragione per cui vengono trattati i miei Dati e cosa succede se non autorizzo
          ENGIE?{" "}
        </b>
      </p>
      <p>&nbsp;</p>
      <p>
        Il trattamento dei tuoi Dati Personali è basato sul tuo consenso quindi sei del tutto libero
        di decidere se e come procedere.
      </p>
      <p>
        Se vuoi essere contattato da ENGIE e ci autorizzi in tal senso, verrai chiamato al numero di
        telefono che hai fornito e ti daremo le informazioni che desideri.
      </p>
      <p>Se invece non vuoi essere contattato, basta che non ci autorizzi.</p>
      <p>
        Può capitare di cambiare idea e quindi potrai anche revocare un consenso già fornito. In tal
        caso però, chiaramente, sono fatte salve le eventuali attività svolte sulla base del
        consenso dato.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <b>6. Per quanto tempo vengono conservati i miei Dati personali?</b>
      </p>
      <p>&nbsp;</p>
      <p>
        I tuoi Dati Personali saranno conservati negli archivi informatici di ENGIE e protetti da
        idonee misure di sicurezza, per il tempo necessario al raggiungimento delle finalità di cui
        al precedente paragrafo 4. A seconda delle circostanze, è difficile quantificare
        precisamente il periodo; in ogni caso useremo criteri di proporzionalità, ragionevolezza e
        minimizzazione solo per gestire il contatto telefonico che ci richiedi.
      </p>
      <p>
        I tuoi Dati Personali potrebbero essere conservati per un periodo successivo in caso di
        eventuali contenziosi, richieste delle Autorità competenti o ai sensi di legge.
      </p>
      <p>&nbsp;</p>
      <p>
        <b>7. Come vengono trattati i miei Dati Personali? </b>
      </p>
      <p>&nbsp;</p>
      <p>
        Il trattamento dei Dati Personali avviene mediante strumenti informatici e manuali, con
        logiche strettamente correlate alla finalità di contatto telefonico che ci chiedi e,
        comunque, in modo da garantire la sicurezza degli stessi e sempre nel rispetto delle
        previsioni di legge.
      </p>
      <p>&nbsp;</p>
      <p>
        <b>8. Chi sono i soggetti che accedono ai miei Dati Personali?</b>
      </p>
      <p>&nbsp;</p>
      <p>
        I tuoi Dati Personali sono trattati da personale autorizzato di ENGIE ma anche da soggetti
        esterni specificamente incaricati da ENGIE (per esempio call center o consulenti esterni)
      </p>
      <p>
        Non si può escludere che in determinati casi, i tuoi Dati Personali potrebbero essere
        comunicati all’Autorità di Pubblica Sicurezza, o altre pubbliche autorità, a seguito di una
        richiesta di quest’ultima di cui ENGIE sia destinataria.
      </p>
      <p>
        I tuoi Dati Personali non verranno trasferiti al di fuori dell’Unione Europea e non vengono
        ceduti o diffusi.
      </p>
      <p>&nbsp;</p>
      <p>
        <b>9. Ho qualche diritto in relazione al fatto che vi lascio i miei Dati Personali?</b>
      </p>
      <p>&nbsp;</p>
      <p>In qualsiasi momento, avrai diritto di:</p>
      <p>&nbsp;</p>
      <ul>
        {" "}
        <li>
          ottenere da ENGIE la conferma che sia o meno in corso un trattamento dei tuoi Dati
          Personali e in tal caso, di ottenere l’accesso alle informazioni di cui all’art. 15 del
          Regolamento;
        </li>{" "}
        <li>
          ottenere la rettifica dei Dati inesatti che ti riguardano, ovvero, tenuto conto delle
          finalità del trattamento, l’integrazione dei dati personali incompleti;
        </li>{" "}
        <li>
          ottenere la cancellazione dei tuoi Dati Personali, in presenza di uno dei motivi di cui
          all’art. 17 del Regolamento;
        </li>{" "}
        <li>
          ottenere la limitazione del trattamento dei tuoi dati personali nei casi previsti dalla
          legge applicabile;
        </li>{" "}
        <li>
          opporti al trattamento dei propri Dati Personali per particolari motivi ove non ci siano
          legittimi interessi cogenti di ENGIE;
        </li>{" "}
        <li>
          ricevere in un formato strutturato (es. dvd, usb), di uso comune e leggibile da
          dispositivo automatico i Dati Personali che ti riguardano forniti nonchè di trasmettere
          tali dati a un altro titolare del trattamento senza impedimenti da parte di ENGIE se
          tecnicamente possibile, nei casi e nei limiti di cui all’art. 20 del Regolamento. A
          seconda dei casi e delle quantità o complessità delle informazioni richieste potrebbe
          esserti richiesto un contributo spese proporzionato al caso.
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>Tali diritti potranno essere esercitati inviando:</p>
      <p>&nbsp;</p>
      <ul>
        <li>una comunicazione a mezzo posta elettronica all’indirizzo privacy-ita@engie.com.</li>
      </ul>
      <p>&nbsp;</p>
      <p>
        Fatto salvo ogni altro ricorso amministrativo o giurisdizionale, hai inoltre il diritto di
        proporre reclamo ad un’Autorità di controllo (per l’Italia: il Garante per la protezione dei
        dati personali), qualora tu ritenga che il trattamento che Ti riguarda sia effettuato in
        violazione del Regolamento generale sulla protezione dei dati. Ulteriori informazioni sono
        disponibili sul sito internet{" "}
        <a className="blu transition" href="http://www.garanteprivacy.it" target="blank">
          http://www.garanteprivacy.it
        </a>
        . Considera che comunque potrai rivolgerti a noi per risolvere, tempestivamente e in modo
        efficace, un Tuo eventuale problema “privacy” con la nostra Società; comunque ci impegniamo
        a gestire le Tue eventuali richieste con la massima cortesia e discrezione.&nbsp;
      </p>
    </div>
  </div>
);

const overlayTextParser = (text, setShow) => {
  const opt = {
    replace: ({ attribs, children }) => {
      if (attribs?.class?.indexOf("close-overlay") >= 0) {
        return (
          <>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href=""
              className={attribs.class}
              onClick={(event) => {
                event.preventDefault();
                setShow(false);
              }}
            >
              {domToReact(children, opt)}
            </a>
          </>
        );
      }
    },
  };

  return text && parse(text, opt);
};

const PrivacyModal = ({ show, setShow, text, small }) => {
  const renderBackdrop = (props) => (
    <button
      className="privacy-modal__backdrop"
      open={show}
      onClick={() => {
        setShow(false);
      }}
      {...props}
    />
  );

  useBlockScroll(show);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      className={classNames("privacy-modal", small && "privacy-modal--small")}
      renderBackdrop={renderBackdrop}
    >
      {text ? (
        <>
          <div className="privacy-modal__content"> {overlayTextParser(text, setShow)}</div>
        </>
      ) : (
        <>
          <button className="privacy-modal__xBtn" onClick={() => setShow(false)}>
            X
          </button>
          <div>{defaultPrivacyPolicy}</div>
          <div className="d-flex justify-content-center">
            <button
              onClick={() => setShow(false)}
              className="privacy-modal__closeBtn icon-btn"
              aria-label="Chiudi"
            >
              Chiudi
            </button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default PrivacyModal;
