export const breadcrumbSteps = ["fornitura", "tariffa-luce", "pacchetto-assicurativo"];

export const stepCookies = {
  fornitura: "switchinCommodity",
  "tariffa-luce": "switchinProdotto",
  "pacchetto-assicurativo": "switchinAssicurazione",
};

export const fornituraAttivaCheckMapping = {
  ele: "luceAttivo",
  gas: "gasAttivo",
  dual: "dualAttivo",
};

export const fornituraCategoriaMapping = {
  ele: "luce",
  gas: "gas",
  dual: "luce-gas",
};

export const offerteMapping = {
  en30light12mesi: {
    fasce: "E3L12",
    monoraria: "E3M12",
  },
  energia30light: {
    fasce: "EN3PL",
    monoraria: "EN3LM",
  },
};

const commoditiesConfiguration = (flussoConfig) => [
  { id: "ele", status: flussoConfig?.luceAttivo === "true" },
  { id: "gas", status: flussoConfig?.gasAttivo === "true" },
  { id: "dual", status: flussoConfig?.dualAttivo === "true" },
];

export const initData = (flussoConfig) =>
  commoditiesConfiguration(flussoConfig).filter((comm) => comm.status)?.length === 1
    ? { fornitura: commoditiesConfiguration(flussoConfig).find((comm) => comm.status).id }
    : {};

export const flussiInit = {
  flussoEnergia: (...[, flussoConfig]) => {
    return {
      listaStep: ["fornitura", "accedi-spazio-clienti", "credenziali-spazio-clienti"],
      data: initData(flussoConfig),
    };
  },
  flussoSceltaCliente: (...[, flussoConfig]) => {
    return {
      listaStep: ["accedi-spazio-clienti", "credenziali-spazio-clienti"],
      data: initData(flussoConfig),
    };
  },
};

export const elencoProdottiBreadcrumb = new Map([
  ["eflex", "eFLEX"],
  ["plpef", "Placet Fissa - Luce Non Domestici"],
  ["plpev", "Placet Variabile - Luce Non Domestici"],
  ["plpgf", "Placet Fissa - Gas Altri Usi"],
  ["plpgv", "Placet Variabile - Gas Altri Usi"],
  ["plcgf", "Placet Fissa - Gas Condomini"],
  ["plcgv", "Placet Variabile - Gas Condomini"],
]);
