import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image";

import withPreview from "../../utility/with-preview";
import Slider from "react-slick";
import { Link } from "../link";

const PrevArrow = ({ onClick, className }) => (
  <button onClick={onClick} className={`icon-btn ${className}`}>
    <i className="nj-icon nj-icon-arrowleft nj-icon--border nj-icon--blue-corporate size-40"></i>
  </button>
);
const NextArrow = ({ onClick, className }) => (
  <button onClick={onClick} className={`icon-btn ${className}`}>
    <i className="nj-icon nj-icon-arrowright nj-icon--border nj-icon--blue-corporate size-40"></i>
  </button>
);

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: true,
  swipe: true,
  slidesToShow: 3,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const ProdottiCorrelati = ({ data }) => {
  const { mainTitle, carouselImage } = data;

  const effectiveSettings = {
    ...settings,
    infinite: carouselImage.length > 3,
  };

  return (
    <section className="section wrap">
      <div className="container">
        {mainTitle?.value && (
          <div className="row">
            <div className="col-12 text-center mb-5">
              {mainTitle.value ? <h3 className="carousel__title">{mainTitle.value}</h3> : null}
              {mainTitle?.content?.mainSubtitle?.value ? (
                <p className="text-blue-corporate font-weight-bold">
                  {mainTitle.content.mainSubtitle.value}
                </p>
              ) : null}
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12">
            <Slider {...effectiveSettings} className="carousel__slider">
              {carouselImage.map((item, i) => (
                <div key={i}>
                  <Link
                    to={item.content?.imageLink?.value}
                    className="carousel__slide d-flex flex-column align-items-start justify-content-end mx-md-2"
                  >
                    <Img
                      className="carousel__img w-100 h-100"
                      image={getImage(item.node)}
                      alt={item.content?.imageDescription.value || item.value?.description || ""}
                    />
                    <div className="carousel__content p-4">
                      {item.content && item.content.imageDescription.value && (
                        <h4 className="carousel__text text-white">
                          {item.content.imageDescription.value}
                        </h4>
                      )}
                    </div>
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(ProdottiCorrelati);
export const fragment = graphql`
  fragment ProdottiCorrelatiFragment on LiferayProdottiCorrelati {
    liferayFields {
      siteId
      articleId
    }
    mainTitle {
      value
      content {
        mainSubtitle {
          value
        }
      }
    }
    carouselImage {
      value {
        description
      }
      node {
        gatsbyImageData
      }
      content {
        imageDescription {
          value
        }
        imageLink {
          value
        }
        textPosition {
          value
        }
      }
    }
  }
`;
