import Cookies from "js-cookie";
const FORM_INFORMATION = process.env.GATSBY_FORM_INFORMATION_ENDPOINT;


export const submitFormInformation = async (values, data, location) => {
  const {
    nome, 
    cognome,
    telefono,
    privacy,
    grecaptcha
  } = values;
  const { advName } = data;

  const submitData = {
    companyId: "10201",
    groupId: "2441430",
    nome,
    cognome,
    telefono,
    email: "",
    comune: "",
    origin: encodeURIComponent(`ENGIE_IMPRESE@@@${Cookies.get("__utmz")}`),
    fasciaOrariaRicontatto: "",
    advName: advName?.value,
    privacy,
    urlProv: encodeURIComponent(location.pathname),
    ...(grecaptcha ? { grecaptcha: grecaptcha } : null),
  };
  console.log("submitData: ", submitData);
  const fetchUrl = FORM_INFORMATION;
  const dataParams = new URLSearchParams();
  dataParams.append("jsonInfo", JSON.stringify(submitData));
  const res = await fetch(fetchUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: dataParams,
  });
  if (!res.ok) {
    throw new Error(`Error: ${res.status} ${res.statusText}`);
  }
  const result = await res.json();
  const resultData = JSON.parse(result);
  //console.log("Result: ", resultData);
  if (resultData.respCode === "ok") {
    return resultData;
  } else {
    throw new Error(`Form response not ok`);
  }
};
