import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image";
import withPreview from "../../utility/with-preview";

const Highlights3Items = ({ data }) => {
  const {
    immagine1,
    titolo1,
    sottotitolo1,
    immagine2,
    titolo2,
    sottotitolo2,
    immagine3,
    titolo3,
    sottotitolo3,
  } = data;

  return (
    <section className="section wrapper-container">
      <div className="container-fluid">
        <div className="row">
          {titolo1?.value && (
            <div className="col-12 col-sm-4">
              <div className="card-highlights">
                <div className="card-highlights__wrap-img">
                  <Img
                    image={getImage(immagine1.node)}
                    alt={immagine1.node.description || ""}
                    className="card-highlights__img"
                  />
                </div>
                <div className="card-highlights__text">
                  <div
                    className="card-highlights__title"
                    dangerouslySetInnerHTML={{ __html: titolo1.value }}
                  />
                  {sottotitolo1?.value && (
                    <div dangerouslySetInnerHTML={{ __html: sottotitolo1.value }} />
                  )}
                </div>
              </div>
            </div>
          )}
          {titolo2?.value && (
            <div className="col-12 col-sm-4">
              <div className="card-highlights">
                <div className="card-highlights__wrap-img">
                  <Img
                    image={getImage(immagine2.node)}
                    alt={immagine1.node.description || ""}
                    className="card-highlights__img"
                  />
                </div>
                <div className="card-highlights__text">
                  <div
                    className="card-highlights__title"
                    dangerouslySetInnerHTML={{ __html: titolo2.value }}
                  />
                  {sottotitolo2?.value && (
                    <div dangerouslySetInnerHTML={{ __html: sottotitolo2.value }} />
                  )}
                </div>
              </div>
            </div>
          )}
          {titolo3?.value && (
            <div className="col-12 col-sm-4">
              <div className="card-highlights">
                <div className="card-highlights__wrap-img">
                  <Img
                    image={getImage(immagine3.node)}
                    alt={immagine1.node.description || ""}
                    className="card-highlights__img"
                  />
                </div>
                <div className="card-highlights__text">
                  <div
                    className="card-highlights__title"
                    dangerouslySetInnerHTML={{ __html: titolo3.value }}
                  />
                  {sottotitolo3?.value && (
                    <div dangerouslySetInnerHTML={{ __html: sottotitolo3.value }} />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default withPreview(Highlights3Items);
export const fragment = graphql`
  fragment Highlights3ItemsFragment on LiferayHighlights3Items {
    liferayFields {
      articleId
      siteId
    }
    immagine1 {
      node {
        gatsbyImageData(width: 463)
      }
    }
    titolo1 {
      value
    }
    sottotitolo1 {
      value
    }
    immagine2 {
      node {
        gatsbyImageData(width: 463)
      }
    }
    titolo2 {
      value
    }
    sottotitolo2 {
      value
    }
    immagine3 {
      node {
        gatsbyImageData(width: 463)
      }
    }
    titolo3 {
      value
    }
    sottotitolo3 {
      value
    }
  }
`;
