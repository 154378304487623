import React from "react";
import { graphql } from "gatsby";
import withPreview from "../../utility/with-preview";
import HubProdotto from "../elements/hub-prodotto";

const HubProdottoItem = ({ data }) => {
  const { titolointro, descrizioneintro, linkpagina } = data;

  return (
    <HubProdotto
      titolointro={titolointro}
      descrizioneintro={descrizioneintro}
      linkpagina={linkpagina}
    />
  );
};

export default withPreview(HubProdottoItem);
export const fragment = graphql`
  fragment HubProdottoItemFragment on LiferayHubProdottoItem {
    liferayFields {
      articleId
      siteId
    }
    titolointro {
      value
    }
    descrizioneintro {
      value
    }
    linkpagina {
      content {
        immagine {
          node {
            gatsbyImageData
          }
        }
        nomeprodotto {
          value
        }
        descrizioneprodotto {
          value
        }
        caratteristicheprodotto {
          value
        }
        prezzoprodotto {
          value
        }
        linkedContent {
          value {
            ... on LiferayJskLayoutPaginaGenerica {
              link
            }
            ... on LiferayJskPaginaLink {
              externalLink: link {
                value
              }
            }
          }
        }
      }
      value
    }
  }
`;
