import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image";
import { Link } from "../link";
import withPreview from "../../utility/with-preview";

const LaunchLink = ({ link, children }) => {
  return link && link !== "#" ? (
    <Link to={link} className="launch-box__container mb-4">
      {children}
    </Link>
  ) : (
    <div className="launch-box__container mb-4">{children}</div>
  );
};

const LaunchBox = ({ data: { mainTitle, cardTitle } }) => {
  return (
    <div className="launch-box">
      <div className="container">
        {mainTitle &&
        (mainTitle.value ||
          (mainTitle.content &&
            mainTitle.content.mainSubtitle &&
            mainTitle.content.mainSubtitle.value)) ? (
          <div className="row">
            <div className="col-12 text-center">
              {mainTitle.value && (
                <div className="mb-4 mb-md-5">
                  <div>
                    <i className="nj-icon nj-icon-building-search nj-icon--blue-corporate size-126"></i>
                  </div>
                  <div
                    className="launch-box__mainTitle"
                    dangerouslySetInnerHTML={{ __html: mainTitle.value }}
                  ></div>
                </div>
              )}
              {mainTitle.content &&
              mainTitle.content.mainSubtitle &&
              mainTitle.content.mainSubtitle.value ? (
                <div className="row">
                  <div
                    className="col-md-8 offset-md-2 text-center"
                    dangerouslySetInnerHTML={{ __html: mainTitle.content.mainSubtitle.value }}
                  ></div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
        <div className="row justify-content-md-center">
          {cardTitle.map((card, i) => (
            <div key={i} className="col-12 col-md-6 mb-6">
              <LaunchLink link={card.content.cardLink?.value}>
                <Img
                  className="launch-box__img"
                  image={getImage(card.content.cardImage.node)}
                  alt={card.content.cardImage.node.description || ""}
                />
                <div className="launch-box__title">
                  {/*
                  {card.content && card.content.cardLink && card.content.cardLink.value ? (
                    <i className="nj-icon nj-icon-arrowright nj-icon--circle nj-icon--blue-corporate size-60"></i>
                  ) : null}
                  */}
                  <h3>{card.value}</h3>
                </div>
              </LaunchLink>
              {card.content.cardSubtitle && card.content.cardSubtitle.value ? (
                <div className="text-center mx-sm-5 mx-2 mb-0">
                  <p
                    className="text-blue-corporate font-weight-bold mb-1"
                    dangerouslySetInnerHTML={{ __html: card.content.cardSubtitle.value }}
                  ></p>
                </div>
              ) : null}
              {card.content.cardDescription && card.content.cardDescription.value ? (
                <div
                  className="launch-box__description text-center mx-sm-5 mx-2 mt-4"
                  dangerouslySetInnerHTML={{ __html: card.content.cardDescription.value }}
                ></div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default withPreview(LaunchBox);
export const launchBoxFragment = graphql`
  fragment ChoiceselectorFragment on LiferayChoiceSelector {
    liferayFields {
      siteId
      articleId
    }
    mainTitle {
      value
      content {
        mainSubtitle {
          value
        }
      }
    }
    cardTitle {
      value
      content {
        cardSubtitle {
          value
        }
        cardImage {
          node {
            description
            gatsbyImageData(width: 538)
          }
        }
        linkIconPresent {
          value
        }
        cardDescription {
          value
        }
        cardLink {
          value
        }
      }
    }
  }
`;
