import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { FixedSizeList as List } from "react-window";
import useClickOut from "../../utility/useClickOut";

const Row = ({ index, style, data }) => {
  const option = data.options[index];
  const focused = data.focusedIndex === index;
  const searchText = data.searchText;
  const lowerCaseLabel = option.label.toLowerCase();
  const matchIndex = lowerCaseLabel.indexOf(searchText);
  return (
    <li
      className={classNames("select-autocomplete__result", {
        highlighted: false,
      })}
      style={style}
    >
      <button
        className={classNames(focused && "highlighted")}
        name={option.value}
        onClick={data.onClick}
      >
        {matchIndex >= 0 ? (
          <>
            <span>{option.label.substring(0, matchIndex)}</span>
            <mark>{option.label.substring(matchIndex, matchIndex + searchText.length)}</mark>
            <span>{option.label.substring(matchIndex + searchText.length)}</span>
          </>
        ) : (
          <span>{option.label}</span>
        )}
      </button>
    </li>
  );
};

const SelectAutocomplete = ({ value = "", onChange, options, placeholder }) => {
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(true);
  const handleSearchChange = (e) => setSearchText(e.target.value);
  const handleSelect = useCallback(
    (e) => {
      onChange(e.currentTarget.name);
      setSearchText("");
      setOpen(false);
    },
    [onChange]
  );
  const filteredOptions = useMemo(
    () => options.filter((option) => option.label.toLowerCase().includes(searchText.toLowerCase())),
    [options, searchText]
  );
  const [focused, setFocused] = useState();
  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setFocused((current) => {
        if (current) {
          const idx = filteredOptions.findIndex((option) => option === current);
          return idx >= 0
            ? filteredOptions[(idx + 1) % filteredOptions.length]
            : filteredOptions[0];
        } else {
          return filteredOptions[0];
        }
      });
    }
    if (e.key === "ArrowUp") {
      setFocused((current) => {
        if (current) {
          const idx = filteredOptions.findIndex((option) => option === current);
          return idx >= 0
            ? filteredOptions[(idx - 1 + filteredOptions.length) % filteredOptions.length]
            : filteredOptions[0];
        } else {
          return filteredOptions[0];
        }
      });
    }
    if (e.key === "Enter" && focused) {
      handleSelect({
        currentTarget: {
          name: focused.value,
        },
      });
    }
  };

  const itemData = useMemo(
    () => ({
      options: filteredOptions,
      onClick: handleSelect,
      searchText: searchText.toLowerCase(),
      focusedIndex: focused ? filteredOptions.findIndex((option) => option === focused) : -1,
    }),
    [focused, searchText, filteredOptions, handleSelect]
  );
  const dropdownRef = useClickOut(open, setOpen);

  const inputRef = useRef();
  useEffect(() => {
    if (open) {
      inputRef.current?.focus();
    }
  }, [open]);

  return (
    <div className="select-autocomplete">
      <button className="select-autocomplete__value" onClick={() => setOpen(true)}>
        {value || placeholder}
      </button>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        ref={dropdownRef}
        className={classNames("select-autocomplete__drop", {
          open: open,
        })}
        onKeyDown={handleKeyDown}
      >
        <div className="select-autocomplete__search">
          <input
            ref={inputRef}
            className=""
            value={searchText}
            onChange={handleSearchChange}
            autoComplete="off"
            tabIndex={0}
          />
        </div>
        <List
          className="select-autocomplete__results"
          height={150}
          itemCount={filteredOptions.length}
          itemSize={30}
          width={270}
          itemData={itemData}
        >
          {Row}
        </List>
      </div>
    </div>
  );
};

export default SelectAutocomplete;
