import React, { useCallback, useState } from "react";
import { graphql } from "gatsby";
import withPreview from "../../utility/with-preview";
import { useForm } from "react-hook-form";
import PrivacyModal from "../form/privacy-modal";
import { FormCheckbox, FormSelect, FormTextInput } from "../form/form-input";
import classNames from "classnames";
import PrivacyCheckbox from "../form/privacy-checkbox";
import { submitFormPMI } from "../../utility/config-form-pmi";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const FormSectionEnergiaRinnovabile = ({ register, errors, watchAltro }) => {
  return (
    <div className="row">
      <div className="col-md-6">
        <p className="form__text">
          Servizi richiesti (è possibile selezionare più di una risposta)
        </p>
        <div className="contatti-form__checkbox-wrapper">
          <div className="contatti-form__checkbox">
            <FormCheckbox
              name="fotovoltaico"
              label="Fotovoltaico"
              register={register}
              error={errors.fotovoltaico}
            />
          </div>
          <div className="contatti-form__checkbox">
            <FormCheckbox name="altro" label="Altro" register={register} error={errors.altro} />
            {watchAltro && (
              <FormTextInput
                register={register}
                name="altroValue"
                id="pmiContattiFormAltroValue"
                placeholder="Specificare"
                validation={{
                  required: {
                    value: true,
                    message: "Specificare un servizio",
                  },
                }}
                error={errors.altroValue}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const FormSectionEfficienzaEnergetica = ({ register, errors, watchAltro }) => {
  return (
    <div className="row">
      <div className="col-md-6">
        <p className="form__text">
          Servizi richiesti (è possibile selezionare più di una risposta)
        </p>
        <div className="contatti-form__checkbox-wrapper">
          <div className="contatti-form__checkbox">
            <FormCheckbox
              name="checkupEnergetico"
              label="Check up energetico"
              register={register}
              error={errors.checkupEnergetico}
            />
          </div>
          <div className="contatti-form__checkbox">
            <FormCheckbox
              name="relamping"
              label="Relamping"
              register={register}
              error={errors.relamping}
            />
          </div>
          <div className="contatti-form__checkbox">
            <FormCheckbox name="altro" label="Altro" register={register} error={errors.altro} />
            {watchAltro && (
              <FormTextInput
                register={register}
                name="altroValue"
                id="pmiContattiFormAltroValue"
                placeholder="Specificare"
                validation={{
                  required: {
                    value: true,
                    message: "Specificare un servizio",
                  },
                }}
                error={errors.altroValue}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const FormSectionBuildingRenovation = ({ register, errors }) => {
  return (
    <div className="row">
      <div className="col-md-6">
        <p className="form__text">
          Servizi richiesti (è possibile selezionare più di una risposta)
        </p>
        <div className="contatti-form__checkbox-wrapper">
          <div className="contatti-form__checkbox">
            <FormCheckbox
              name="buildingRenovation"
              label="Building Renovation"
              register={register}
              error={errors.buildingRenovation}
            />
          </div>
          <div className="contatti-form__checkbox">
            <FormCheckbox
              name="eMobility"
              label="E-Mobility"
              register={register}
              error={errors.eMobility}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const FormSectionGasPower = ({ register, errors, watchFornitura, type = "default" }) => {
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <FormSelect
            register={register}
            name="fornitura"
            id="pmiContattiFormFornitura"
            placeholder="Fornitura richiesta"
            validation={{
              required: {
                value: true,
                message: "Specificare la fornitura richiesta.",
              },
            }}
            error={errors.fornitura}
            options={[
              { label: "Gas", value: "Gas" },
              { label: "Power", value: "Power" },
              { label: "Gas e Power", value: "Gas e Power" },
            ]}
          />
        </div>
        <div className="col-md-6">
          <FormTextInput
            register={register}
            name="provincia"
            id="pmiContattiFormProvincia"
            placeholder="Provincia di fornitura"
            validation={{
              required: {
                value: true,
                message: "Specificare provincia di fornitura.",
              },
            }}
            error={errors.provincia}
          />
        </div>
      </div>
      {
        <div className="row">
          <div className={classNames(type === "2volteleggero" ? "col-12" : "col-md-6")}>
            {watchFornitura && (
              <div className="row">
                {watchFornitura !== "Power" && (
                  <div className={classNames(type === "2volteleggero" ? "col-md-6" : "col-12")}>
                    <FormSelect
                      register={register}
                      name="consumoGas"
                      id="pmiContattiFormConsumoGas"
                      placeholder="Consumo annuo gas"
                      validation={{
                        required: {
                          value: true,
                          message: "Specificare il consumo annuo.",
                        },
                      }}
                      error={errors.consumoGas}
                      options={[
                        { label: "<10.000 smc", value: "<10.000 smc" },
                        {
                          label: "tra 10.000 smc e 200.000 smc",
                          value: "tra 10.000 smc e 200.000 smc",
                        },
                        { label: ">200.000 smc", value: ">200.000 smc" },
                      ]}
                    />
                  </div>
                )}
                {watchFornitura !== "Gas" && (
                  <div className={classNames(type === "2volteleggero" ? "col-md-6" : "col-12")}>
                    <FormSelect
                      register={register}
                      name="consumoPower"
                      id="pmiContattiFormConsumoPower"
                      placeholder="Consumo annuo power"
                      validation={{
                        required: {
                          value: true,
                          message: "Specificare il consumo annuo.",
                        },
                      }}
                      error={errors.consumoPower}
                      options={[
                        { label: "<15.000 kWh", value: "<15.000 kWh" },
                        {
                          label: "tra 15.000 kWh e 1.000.000 kWh",
                          value: "tra 15.000 kWh e 1.000.000 kWh",
                        },
                        { label: ">1.000.000 kWh", value: ">1.000.000 kWh" },
                      ]}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          {type !== "2volteleggero" && (
            <div className="col-md-6">
              <FormSelect
                register={register}
                name="prodotto"
                id="pmiContattiFormProdotto"
                placeholder="Prodotto richiesto"
                validation={{
                  required: {
                    value: true,
                    message: "Specificare il prodotto richiesto.",
                  },
                }}
                error={errors.prodotto}
                options={[
                  { label: "QuotaMinima", value: "QuotaMinima" },
                  { label: "2VolteLeggero", value: "ECO1S" },
                  ...(!watchFornitura || watchFornitura === "Power"
                    ? [
                        {
                          label: "ProdottoPUN",
                          value: "ProdottoPUN",
                        },
                      ]
                    : []),
                ]}
              />
            </div>
          )}
        </div>
      }
    </>
  );
};

const FormContattiPmi = ({ data, location }) => {
  const { formId, formType, title, ctaLabel, successMessage, testoFlagPrivacy } = data;
  const [submitted, setSubmitted] = useState();
  const [remoteError, setRemoteError] = useState();
  const [privacyModalVisible, setPrivacyModalVisible] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const watchFornitura = watch("fornitura");
  const watchAltro = watch("altro");
  const { executeRecaptcha } = useGoogleReCaptcha();

  const submit = useCallback(
    async (values) => {
      if (!executeRecaptcha) {
        console.warn("Execute recaptcha not yet available");
        return;
      }
      const token = await executeRecaptcha("formImprese");

      try {
        const result = await submitFormPMI({ ...values, grecaptcha: token }, formType, location);
        console.log("Submit result: ", result);
        setSubmitted(true);
      } catch (err) {
        setSubmitted(false);
        setRemoteError(true);
        console.error("Error submit: ", err);
      }
    },
    [formType, location, executeRecaptcha]
  );

  return (
    <section
      className="section contatti-form contatti-form--pmi wrapper-container"
      id="anchor-for-form-contatti"
      data-id={formId?.value}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3>{title?.value}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p>{title?.content?.subtitle?.value}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {submitted ? (
              successMessage?.value ? (
                <div
                  className="contatti-form__message"
                  dangerouslySetInnerHTML={{
                    __html: successMessage?.value,
                  }}
                />
              ) : (
                <div className="contatti-form__message">Grazie per aver lasciato i tuoi dati.</div>
              )
            ) : remoteError ? (
              <div className="contatti-form__message">
                Si è verificato un errore nell'invio dei dati, riprova più tardi.
              </div>
            ) : (
              <form onSubmit={handleSubmit(submit)} className="form">
                <div className="row">
                  <div className="col-md-6">
                    <FormTextInput
                      register={register}
                      name="ragioneSociale"
                      id="pmiContattiFormRagioneSociale"
                      placeholder="Ragione sociale"
                      validation={{
                        required: {
                          value: true,
                          message: "Specificare ragione sociale.",
                        },
                      }}
                      error={errors.ragioneSociale}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormTextInput
                      register={register}
                      name="piva"
                      id="pmiContattiFormPartitaIva"
                      placeholder="P. IVA"
                      validation={{
                        required: {
                          value: true,
                          message: "Specificare partita iva.",
                        },
                      }}
                      error={errors.piva}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <FormTextInput
                      register={register}
                      name="nome"
                      id="pmiContattiFormNome"
                      placeholder="Nome"
                      validation={{
                        required: {
                          value: true,
                          message: "Specificare un nome.",
                        },
                        pattern: {
                          value: /^[a-zA-Z\s]*$/,
                          message: "Specificare un nome (solo caratteri e/o spazi).",
                        },
                      }}
                      error={errors.nome}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormTextInput
                      register={register}
                      name="cognome"
                      id="pmiContattiFormCognome"
                      placeholder="Cognome"
                      validation={{
                        required: {
                          value: true,
                          message: "Specificare un cognome.",
                        },
                        pattern: {
                          value: /^[a-zA-Z\s]*$/,
                          message: "Specificare un cognome (solo caratteri e/o spazi).",
                        },
                      }}
                      error={errors.cognome}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <FormTextInput
                      register={register}
                      name="email"
                      id="pmiContattiFormEmail"
                      placeholder="Indirizzo E-Mail"
                      validation={{
                        required: {
                          value: true,
                          message: "Specificare un indirizzo email.",
                        },
                        pattern: {
                          value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                          message: "Specificare un indirizzo email valido.",
                        },
                      }}
                      error={errors.email}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormTextInput
                      register={register}
                      name="telefono"
                      id="pmiContattiFormTelefono"
                      placeholder="Numero di telefono"
                      validation={{
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Specificare un numero di telefono valido.",
                        },
                      }}
                      error={errors.telefono}
                    />
                  </div>
                </div>
                {
                  {
                    "gas-power": (
                      <FormSectionGasPower
                        register={register}
                        errors={errors}
                        watchFornitura={watchFornitura}
                      />
                    ),
                    "gas-power-2volteleggero": (
                      <FormSectionGasPower
                        register={register}
                        errors={errors}
                        watchFornitura={watchFornitura}
                        type="2volteleggero"
                      />
                    ),
                    "energia-rinnovabile": (
                      <FormSectionEnergiaRinnovabile
                        register={register}
                        errors={errors}
                        watchAltro={watchAltro}
                      />
                    ),
                    "efficienza-energetica": (
                      <FormSectionEfficienzaEnergetica
                        register={register}
                        errors={errors}
                        watchAltro={watchAltro}
                      />
                    ),
                    "building-renovation": (
                      <FormSectionBuildingRenovation register={register} errors={errors} />
                    ),
                  }[formType.value[0]]
                }
                <div className="row">
                  <div className="col-12">
                    <PrivacyCheckbox
                      register={register}
                      error={errors.privacy}
                      setPrivacyModalVisible={setPrivacyModalVisible}
                      text={testoFlagPrivacy?.value}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form__submit">
                      <button type="submit" className="cta-link">
                        {ctaLabel?.value}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
        <PrivacyModal
          show={privacyModalVisible}
          setShow={setPrivacyModalVisible}
          text={testoFlagPrivacy?.content?.privacyDin?.value}
        />
      </div>
    </section>
  );
};

export default withPreview(FormContattiPmi);
export const fragment = graphql`
  fragment JskFormContattiPmiFragment on LiferayJskFormContattiPmi {
    liferayFields {
      siteId
      articleId
    }
    formId {
      value
    }
    formType {
      value
    }
    title {
      value
      content {
        subtitle {
          value
        }
      }
    }
    ctaLabel {
      value
    }
    testoFlagPrivacy {
      value
      content {
        privacyDin {
          value
        }
      }
    }
    successMessage {
      value
    }
  }
`;
