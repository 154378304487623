const FORM_PMI_CONTATTI = process.env.GATSBY_FORM_PMI_CONTATTI_ENDPOINT;

const getCustomData = (formTypePMI, values) => {
  if (formTypePMI === "gas-power" || formTypePMI === "gas-power-2volteleggero") {
    const {
      fornitura,
      provincia,
      prodotto = "ECO1S",
      consumoGas = "",
      consumoPower = "",
    } = values;
    return {
      resourceId: "gasPower",
      categoria: "PMIContattiGasPower",
      fornituraRichiesta: fornitura,
      fornituraRichiestaGas: consumoGas,
      fornituraRichiestaPower: consumoPower,
      comune_fornitura: provincia,
      prodottoRichiesto: prodotto,
    };
  } else if (formTypePMI === "energia-rinnovabile") {
    const { fotovoltaico, altro = false, altroValue = "" } = values;
    return {
      resourceId: "energiaRinnovabile",
      categoria: "PMIContattiEnergiaRinnovabile",
      fotovoltaico,
      altro,
      specificare: altroValue,
    };
  } else if (formTypePMI === "efficienza-energetica") {
    const { checkupEnergetico, relamping, altro = false, altroValue = "" } = values;
    return {
      resourceId: "checkup",
      categoria: "PMIContattiEfficienzaEnergetica",
      check_up_energetico: checkupEnergetico,
      relamping,
      altro,
      specificare: altroValue,
    };
  } else if (formTypePMI === "facility-management") {
    return {
      resourceId: "facility",
      categoria: "PMIContattiFacilityManagement",
    };
  } else if (formTypePMI === "building-renovation") {
    const { buildingRenovation, eMobility } = values;
    return {
      resourceId: "building",
      categoria: "PMIBuildingRenovation",
      building_renovation: buildingRenovation,
      engie_move: eMobility,
    };
  }
  return {};
};

export const submitFormPMI = async (values, formType, location) => {
  const formTypePMI = formType.value[0];
  console.log("formTypePMI: ", formTypePMI);
  console.log("values: ", values);
  const companyId = "10201";
  const groupId = "2441430";
  const { ragioneSociale, piva, nome, cognome, email, telefono, privacy, grecaptcha } = values;
  const customData = getCustomData(formTypePMI, values);
  const submitData = {
    companyId,
    groupId,
    ragioneSociale,
    piva,
    nome,
    cognome,
    telefono,
    email,
    pmi: true,
    urlProv: encodeURIComponent(location.pathname),
    privacy,
    ...customData,
    ...(grecaptcha ? { grecaptcha: grecaptcha } : null),
  };
  console.log("submit params: ", submitData);
  const fetchUrl = FORM_PMI_CONTATTI;
  const dataParams = new URLSearchParams();
  dataParams.append("request", JSON.stringify(submitData));
  const res = await fetch(fetchUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: dataParams,
  });
  if (!res.ok) {
    throw new Error(`Error: ${res.status} ${res.statusText}`);
  }
  const result = await res.json();
  const resultData = JSON.parse(result);
  console.log("Result: ", resultData);
  if (resultData.respCode === "ok") {
    return resultData;
  } else {
    throw new Error(`Form response not ok`);
  }
};
