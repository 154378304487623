import React, { Fragment, useMemo } from "react";
import { graphql } from "gatsby";
import classNames from "classnames";
import { Link } from "../link";
import SeeMore from "../elements/see-more";
import ImageWrapper from "../elements/image-wrapper";
import withPreview from "../../utility/with-preview";

const AllMediaItems = ({ list }) => {
  const rows = useMemo(() => {
    const result = [];
    const length = list.length;
    if (length > 0) {
      result.push(list.slice(0, 1));
    }
    if (length > 1) {
      result.push(list.slice(1, 3));
    }
    if (length > 3) {
      result.push(list.slice(3, 6));
    }
    if (length > 6) {
      result.push(list.slice(6, 10));
    }
    return result;
  }, [list]);
  const firstItem = list[0];
  return (
    <section className="section news wrapper-container px-2">
      <div className="container">
        {firstItem && (
          <Link
            className="news__link"
            to={firstItem.externalLink?.value || firstItem.detailPage?.link}
            aria-label={firstItem.liferayFields.title}
          >
            <article>
              <div className="row">
                <div className="col-sm-6 px-0">
                  <div className="news__text news__text--featured news__text--blue">
                    <div className="news__category">News</div>
                    <div className="news__title news__title--featured clan-bold">
                      {firstItem.liferayFields.title}
                    </div>
                    <SeeMore color="white" />
                  </div>
                </div>
                <div className="col-sm-6 px-0">
                  {firstItem.featuredImage?.node && (
                    <ImageWrapper
                      className="news__img news__img--featured"
                      image={firstItem.featuredImage.node}
                      alt=""
                    />
                  )}
                </div>
              </div>
            </article>
          </Link>
        )}
        {rows.slice(1).map((row, i) => (
          <div className="row justify-content-center" key={i}>
            {row.map((item, j) => (
              <Fragment key={item.liferayFields.articleId}>
                {row.length > 2 && (i > 1 || j > 0) ? (
                  <div className="col-sm-3 px-0">
                    <Link
                      className="news__link"
                      to={item.externalLink?.value || item.detailPage?.link}
                      aria-label={item.liferayFields.title}
                    >
                      <article>
                        <div
                          className={classNames("news__text", {
                            "news__text--blue": (i === 1 && j === 1) || (i === 2 && j === 0),
                            "news__text--gray": (i === 1 && j === 2) || (i === 2 && j === 2),
                          })}
                        >
                          <div className="news__category">News</div>
                          <div className="news__title clan-bold">{item.liferayFields.title}</div>
                          <SeeMore />
                        </div>
                      </article>
                    </Link>
                  </div>
                ) : (
                  <div className="col-sm-6 px-0">
                    <Link
                      className="news__link"
                      to={item.externalLink?.value || item.detailPage?.link}
                      aria-label={item.liferayFields.title}
                    >
                      <article className="row mx-0">
                        <div className="col-sm-6 px-0">
                          {item.featuredImage?.node && (
                            <ImageWrapper
                              className="news__img"
                              image={item.featuredImage.node}
                              alt=""
                            />
                          )}
                        </div>
                        <div className="col-sm-6 px-0">
                          <div className="news__text">
                            <div className="news__category">News</div>
                            <div className="news__title clan-bold">{item.liferayFields.title}</div>
                            <SeeMore />
                          </div>
                        </div>
                      </article>
                    </Link>
                  </div>
                )}
              </Fragment>
            ))}
          </div>
        ))}
      </div>
    </section>
  );
};

const ThreeMediaItems = ({ title, list, link }) => {
  const firstItem = list[0];
  return (
    <section className="section news wrapper-container px-0">
      <div className="container">
        {title?.value && (
          <div className="row">
            <div className="col-12">
              <div className="news__section-title">{title.value}</div>
            </div>
          </div>
        )}
        {firstItem && (
          <Link
            className="news__link"
            to={firstItem.externalLink?.value || firstItem.detailPage?.link}
            aria-label={firstItem.liferayFields.title}
          >
            <article>
              <div className="row">
                <div className="col-sm-6 px-0 order-sm-2">
                  {firstItem.featuredImage?.node && (
                    <ImageWrapper
                      className="news__img news__img--featured"
                      image={firstItem.featuredImage.node}
                      alt=""
                    />
                  )}
                </div>
                <div className="col-sm-6 px-0">
                  <div className="news__text news__text--featured news__text--blue">
                    <div className="news__category">News</div>
                    <div className="news__title news__title--featured clan-bold">
                      {firstItem.liferayFields.title}
                    </div>
                    <SeeMore color="white" />
                  </div>
                </div>
              </div>
            </article>
          </Link>
        )}
        {list.length > 1 && (
          <div className="row justify-content-center">
            {list.slice(1).map((item) => (
              <div className="col-sm-6 px-0" key={item.liferayFields.articleId}>
                <Link
                  className="news__link"
                  to={item.externalLink?.value || item.detailPage?.link}
                  aria-label={item.liferayFields.title}
                >
                  <article className="row mx-0">
                    <div className="col-sm-6 px-0">
                      {item.featuredImage?.node && (
                        <ImageWrapper
                          className="news__img"
                          image={item.featuredImage.node}
                          alt=""
                        />
                      )}
                    </div>
                    <div className="col-sm-6 px-0">
                      <div className="news__text">
                        <div className="news__category">News</div>
                        <div className="news__title clan-bold">{item.liferayFields.title}</div>
                        <SeeMore />
                      </div>
                    </div>
                  </article>
                </Link>
              </div>
            ))}
          </div>
        )}
        {link && (
          <div className="row">
            <div className="col-sm-9 offset-sm-3 mb-4 px-0">
              <Link to={link} className="news__see-all d-flex align-items-center">
                Vedi altre news <SeeMore />
              </Link>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

const AggregatoreNews = ({ data }) => {
  const { title, list, linkPage } = data;
  const model = data.model?.value?.[0];

  return {
    allmediaitems: <AllMediaItems list={list.slice(0, 9)} />,
    "3mediaitems": (
      <ThreeMediaItems
        title={title}
        list={list.slice(list.length === 3 ? 1 : 0, 3)}
        link={linkPage?.value?.link || linkPage?.value?.externalLink?.value}
      />
    ),
  }[model];
};

export default withPreview(AggregatoreNews);
export const fragment = graphql`
  fragment AggregatoreNewsFragment on LiferayJskAggregatoreNews {
    liferayFields {
      siteId
      articleId
    }
    title {
      value
    }
    model {
      value
    }
    linkPage {
      value {
        ... on LiferayJskLayoutPaginaGenerica {
          link
        }
        ... on LiferayJskLayoutPaginaNews {
          link
        }
        ... on LiferayJskLayoutPaginaPrivacy {
          link
        }
        ... on LiferayJskPaginaLink {
          externalLink: link {
            value
          }
        }
      }
    }
    list {
      liferayFields {
        title
        articleId
      }
      detailPage {
        link
      }
      externalLink {
        value
      }
      featuredImage {
        node {
          gatsbyImageData(width: 715)
          publicURL
        }
      }
    }
  }
`;
