export const dataLayerStr2Obj = (string) => {
  return (
    string &&
    JSON.parse(
      string
        .replace("<p>", "")
        .replace("</p>", "")
        .replace(/'([^'"]+)'/g, '"$1"')
    )
  );
};

export const dataLayerPush = (event) => {
  //console.info("Push on dataLayer:", event);
  if (window.dataLayer && event) {
    window.dataLayer.push(event);
  }
};
