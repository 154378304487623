import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image";
import classNames from "classnames";
import withPreview from "../../utility/with-preview";
import SeeMore from "../elements/see-more";

const ContattiIntro = ({ data }) => {
  const { title, box } = data;
  return (
    <section className="section contatti-intro wrapper-container">
      <div className="container-fluid">
        {title?.value && (
          <div className="row">
            <div className="col-12">
              <div className="contatti-intro__title">{title.value}</div>
            </div>
          </div>
        )}
        <div className="row">
          {box?.map((item, i) => (
            <div
              className={classNames("d-flex flex-column px-0", {
                "col-md-3": box.length >= 4,
                "col-md-4": box.length === 3,
                "col-md-6": box.length === 2,
                "col-12": box.length === 1,
              })}
              key={i}
            >
              <div className="d-flex justify-content-center">
                <Img
                  className="contatti-intro__img w-100"
                  image={getImage(item.content.image.node)}
                  alt={item.content.image.value?.description || ""}
                />
              </div>
              <div className="d-flex flex-column flex-fill align-items-start align-items-md-center px-3 px-md-0 py-4">
                <h2
                  className={classNames(
                    "contatti-intro__title-box",
                    item.content.color?.value?.[0]
                  )}
                >
                  {item.value}
                </h2>
                <div className="pt-2 mt-auto">
                  <SeeMore
                    link={item.content.page.value.link || item.content.page.value.externalLink}
                    color={item.content.color?.value?.[0]}
                    aria-label={item.value}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default withPreview(ContattiIntro);
export const contattiIntroFragment = graphql`
  fragment JskContattiIntroFragment on LiferayJskContattiIntro {
    liferayFields {
      siteId
      articleId
    }
    title {
      value
    }
    box {
      value
      content {
        image {
          node {
            gatsbyImageData
          }
          value {
            description
          }
        }
        page {
          value {
            ... on LiferayJskLayoutPaginaGenerica {
              link
            }
            ... on LiferayJskPaginaLink {
              externalLink: link {
                value
              }
            }
          }
        }
        color {
          value
        }
      }
    }
  }
`;
