import React from "react";
import { graphql } from "gatsby";
import withPreview from "../../utility/with-preview";

const textCol = (offsetData) => {
  const offset = offsetData && offsetData.length ? offsetData[0] : 1;
  return (
    {
      0: "col-12",
      1: "col-lg-10 offset-lg-1",
      2: "col-lg-8 offset-lg-2",
    }[offset] || "col-lg-10 offset-lg-1"
  );
};

const IntroSimple = ({ data: { mainTitle } }) => {
  return (
    <div className="intro-simple">
      <div className="container">
        {/* {mainTitle && mainTitle.value ? (
          <div className="row">
            <div
              className={textCol(
                mainTitle.content &&
                  mainTitle.content.sideOffset &&
                  mainTitle.content.sideOffset.value
              )}
            >
              <h3 className="section__title">{mainTitle.value}</h3>
            </div>
          </div>
        ) : null} */}
        {mainTitle && mainTitle.content && mainTitle.content.mainText ? (
          <div className="row">
            <div
              className={textCol(
                mainTitle.content &&
                  mainTitle.content.sideOffset &&
                  mainTitle.content.sideOffset.value
              )}
            >
              <div
                className="intro-simple__content"
                dangerouslySetInnerHTML={{ __html: mainTitle.content.mainText.value }}
              ></div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default withPreview(IntroSimple);
export const introSimpleFragment = graphql`
  fragment IntrosimpleFragment on LiferayIntroSimpleNew {
    liferayFields {
      siteId
      articleId
    }
    mainTitle {
      value
      content {
        mainText {
          value
        }
      }
    }
  }
`;
