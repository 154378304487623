import React from "react";
import { graphql } from "gatsby";
import withPreview from "../../utility/with-preview";
import HubProdotto from "../elements/hub-prodotto";

const HubProdottoPlacet = ({ data }) => {
  const { titolointro, descrizioneintro, linkpagina } = data;

  return (
    <HubProdotto
      titolointro={titolointro}
      descrizioneintro={descrizioneintro}
      linkpagina={linkpagina}
      placet={true}
    />
  );
};

export default withPreview(HubProdottoPlacet);
export const fragment = graphql`
  fragment HubProdottoPlacetFragment on LiferayHubProdottoPlacet {
    liferayFields {
      articleId
      siteId
    }
    titolointro {
      value
    }
    descrizioneintro {
      value
    }
    linkpagina {
      content {
        prodottoCRM {
          dataFineValidita
          corrispettivoEnergia
          corrispettivoF1
          corrispettivoF2
          corrispettivoF3
          quotaFissaLuce
          quotaFissaGas
          componentePrezzoFisso
          quotaVariabileGas
          quotaVariabileLuce
          spreadGas
          spreadLuce
          corrispettivoBiorariaF1
          corrispettivoBiorariaF23
          quotaCommFissaAltriUsiGas
          quotaCommFissaAltriUsiBtLuce
          quotaCommVariabileAltriUsiGas
          quotaCommVariabileAltriUsiBtLuce
          quotaPvolSmc
          quotaPvolkWh
          quotaPvolF1kWh
          quotaPvolF2kWh
          quotaPvolF3kWh
          quotaAlfakWh
          quotaAlfaSmc
          quotaAlfaF1kWh
          quotaAlfaF2kWh
          quotaAlfaF3kWh
        }
        immagine {
          node {
            gatsbyImageData
          }
        }
        nomeprodotto {
          value
        }
        descrizioneprodotto {
          value
        }
        caratteristicheprodotto {
          value
        }
        codiceprodotto {
          value
        }
        prezzoprodotto {
          value
        }
        ricontatto {
          value
          content {
            formId {
              value
            }
          }
        }
      }
      value
    }
  }
`;
