import React, { useCallback, useMemo } from "react";
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image";
import SeeMore from "../elements/see-more";
import { Link } from "../link";
import classNames from "classnames";

const HubProdotto = ({ titolointro, descrizioneintro, linkpagina, placet = false }) => {
  const handleClick = useCallback(
    (e) => {
      const id = e.currentTarget.id;
      const formId = linkpagina[id].content?.ricontatto?.content?.formId?.value;
      const fluxId = linkpagina[id].content.codiceprodotto?.value;
      if (formId && e.currentTarget.name === "form") {
        const formEvent = new CustomEvent("start-form", {
          detail: {
            formId,
          },
        });
        console.log("Dispatching event", formEvent);
        document.dispatchEvent(formEvent);
      } else if (fluxId && e.currentTarget.name === "flusso-switch-in") {
        const fluxEvent = new CustomEvent("start-flusso-switch-in", {
          detail: {
            productName: fluxId,
          },
        });
        console.log("Dispatching event", fluxEvent);
        document.dispatchEvent(fluxEvent);
      }
    },
    [linkpagina]
  );

  const descrizioneValues = useMemo(
    () =>
      linkpagina?.map((item) =>
        item.content?.descrizioneprodotto?.value
          ?.replace(/@corrispettivoEnergia/g, item?.content?.prodottoCRM?.corrispettivoEnergia)
          ?.replace(/@quotaFissaLuce/g, item?.content?.prodottoCRM?.quotaFissaLuce)
          ?.replace(/@quotaFissaGas/g, item?.content?.prodottoCRM?.quotaFissaGas)
          ?.replace(/@componentePrezzoFisso/g, item?.content?.prodottoCRM?.componentePrezzoFisso)
          ?.replace(/@quotaVariabileGas/g, item?.content?.prodottoCRM?.quotaFissaGas)
          ?.replace(/@quotaVariabileLuce/g, item?.content?.prodottoCRM?.quotaFissaGas)
          ?.replace(/@corrispettivoF1/g, item?.content?.prodottoCRM?.corrispettivoF1)
          ?.replace(/@corrispettivoF2/g, item?.content?.prodottoCRM?.corrispettivoF2)
          ?.replace(/@corrispettivoF3/g, item?.content?.prodottoCRM?.corrispettivoF3)
          ?.replace(/@dataFineValidita/g, item?.content?.prodottoCRM?.dataFineValidita)
          ?.replace(/@spreadGas/g, item?.content?.prodottoCRM?.spreadGas)
          ?.replace(/@spreadLuce/g, item?.content?.prodottoCRM?.spreadLuce)
          ?.replace(
            /@corrispettivoBiorariaF1/g,
            item?.content?.prodottoCRM?.corrispettivoBiorariaF1
          )
          ?.replace(
            /@corrispettivoBiorariaF23/g,
            item?.content?.prodottoCRM?.corrispettivoBiorariaF23
          )
          ?.replace(
            /@quotaCommFissaAltriUsiGas/g,
            item?.content?.prodottoCRM?.quotaCommFissaAltriUsiGas
          )
          ?.replace(
            /@quotaCommFissaAltriUsiBtLuce/g,
            item?.content?.prodottoCRM?.quotaCommFissaAltriUsiBtLuce
          )
          ?.replace(
            /@quotaCommVariabileAltriUsiGas/g,
            item?.content?.prodottoCRM?.quotaCommVariabileAltriUsiGas
          )
          ?.replace(
            /@quotaCommVariabileAltriUsiBtLuce/g,
            item?.content?.prodottoCRM?.quotaCommVariabileAltriUsiBtLuce
          )
          ?.replace(/@quotaPvolSmc/g, item?.content?.prodottoCRM?.quotaPvolSmc)
          ?.replace(/@quotaPvolkWh/g, item?.content?.prodottoCRM?.quotaPvolkWh)
          ?.replace(/@quotaPvolF1kWh/g, item?.content?.prodottoCRM?.quotaPvolF1kWh)
          ?.replace(/@quotaPvolF2kWh/g, item?.content?.prodottoCRM?.quotaPvolF2kWh)
          ?.replace(/@quotaPvolF3kWh/g, item?.content?.prodottoCRM?.quotaPvolF3kWh)
          ?.replace(/@quotaAlfakWh/g, item?.content?.prodottoCRM?.quotaAlfakWh)
          ?.replace(/@quotaAlfaSmc/g, item?.content?.prodottoCRM?.quotaAlfaSmc)
          ?.replace(/@quotaAlfaF1kWh/g, item?.content?.prodottoCRM?.quotaAlfaF1kWh)
          ?.replace(/@quotaAlfaF2kWh/g, item?.content?.prodottoCRM?.quotaAlfaF2kWh)
          ?.replace(/@quotaAlfaF3kWh/g, item?.content?.prodottoCRM?.quotaAlfaF3kWh)
      ),
    [linkpagina]
  );

  const caratteristicheValues = useMemo(
    () =>
      linkpagina?.map((item) =>
        item.content?.caratteristicheprodotto?.value
          ?.replace(/@corrispettivoEnergia/g, item?.content?.prodottoCRM?.corrispettivoEnergia)
          ?.replace(/@quotaFissaLuce/g, item?.content?.prodottoCRM?.quotaFissaLuce)
          ?.replace(/@quotaFissaGas/g, item?.content?.prodottoCRM?.quotaFissaGas)
          ?.replace(/@componentePrezzoFisso/g, item?.content?.prodottoCRM?.componentePrezzoFisso)
          ?.replace(/@quotaVariabileGas/g, item?.content?.prodottoCRM?.quotaFissaGas)
          ?.replace(/@quotaVariabileLuce/g, item?.content?.prodottoCRM?.quotaFissaGas)
          ?.replace(/@corrispettivoF1/g, item?.content?.prodottoCRM?.corrispettivoF1)
          ?.replace(/@corrispettivoF2/g, item?.content?.prodottoCRM?.corrispettivoF2)
          ?.replace(/@corrispettivoF3/g, item?.content?.prodottoCRM?.corrispettivoF3)
          ?.replace(/@dataFineValidita/g, item?.content?.prodottoCRM?.dataFineValidita)
          ?.replace(/@spreadGas/g, item?.content?.prodottoCRM?.spreadGas)
          ?.replace(/@spreadLuce/g, item?.content?.prodottoCRM?.spreadLuce)
          ?.replace(
            /@corrispettivoBiorariaF1/g,
            item?.content?.prodottoCRM?.corrispettivoBiorariaF1
          )
          ?.replace(
            /@corrispettivoBiorariaF23/g,
            item?.content?.prodottoCRM?.corrispettivoBiorariaF23
          )
          ?.replace(
            /@quotaCommFissaAltriUsiGas/g,
            item?.content?.prodottoCRM?.quotaCommFissaAltriUsiGas
          )
          ?.replace(
            /@quotaCommFissaAltriUsiBtLuce/g,
            item?.content?.prodottoCRM?.quotaCommFissaAltriUsiBtLuce
          )
          ?.replace(
            /@quotaCommVariabileAltriUsiGas/g,
            item?.content?.prodottoCRM?.quotaCommVariabileAltriUsiGas
          )
          ?.replace(
            /@quotaCommVariabileAltriUsiBtLuce/g,
            item?.content?.prodottoCRM?.quotaCommVariabileAltriUsiBtLuce
          )
          ?.replace(/@quotaPvolSmc/g, item?.content?.prodottoCRM?.quotaPvolSmc)
          ?.replace(/@quotaPvolkWh/g, item?.content?.prodottoCRM?.quotaPvolkWh)
          ?.replace(/@quotaPvolF1kWh/g, item?.content?.prodottoCRM?.quotaPvolF1kWh)
          ?.replace(/@quotaPvolF2kWh/g, item?.content?.prodottoCRM?.quotaPvolF2kWh)
          ?.replace(/@quotaPvolF3kWh/g, item?.content?.prodottoCRM?.quotaPvolF3kWh)
          ?.replace(/@quotaAlfakWh/g, item?.content?.prodottoCRM?.quotaAlfakWh)
          ?.replace(/@quotaAlfaSmc/g, item?.content?.prodottoCRM?.quotaAlfaSmc)
          ?.replace(/@quotaAlfaF1kWh/g, item?.content?.prodottoCRM?.quotaAlfaF1kWh)
          ?.replace(/@quotaAlfaF2kWh/g, item?.content?.prodottoCRM?.quotaAlfaF2kWh)
          ?.replace(/@quotaAlfaF3kWh/g, item?.content?.prodottoCRM?.quotaAlfaF3kWh)
      ),
    [linkpagina]
  );

  return (
    <section
      className={classNames("section wrapper-container", placet && "hub-prodotto-item--placet")}
    >
      <div className="hub-prodotto-item">
        {titolointro?.value && (
          <div
            className="hub-prodotto-item__titolo"
            dangerouslySetInnerHTML={{ __html: titolointro.value }}
          />
        )}
        {descrizioneintro?.value && (
          <div
            className="hub-prodotto-item__descrizione"
            dangerouslySetInnerHTML={{ __html: descrizioneintro.value }}
          />
        )}

        <div>
          {linkpagina?.map((item, i) => (
            <div key={i} className="hub-prodotto-item__box">
              <div className="hub-prodotto-item__box-img">
                <Img
                  image={getImage(item.content.immagine.node)}
                  alt={item.content.immagine.node.description || ""}
                />
              </div>
              <div className="hub-prodotto-item__box-content">
                <div className="hub-prodotto-item__box-text">
                  {item?.content?.nomeprodotto?.value && (
                    <div
                      className="hub-prodotto-item__box-name"
                      dangerouslySetInnerHTML={{ __html: item.content.nomeprodotto.value }}
                    />
                  )}
                  {item?.content?.descrizioneprodotto?.value && (
                    <div
                      className="hub-prodotto-item__box-desc"
                      dangerouslySetInnerHTML={{ __html: descrizioneValues[i] }}
                    />
                  )}
                  {item?.content?.caratteristicheprodotto?.value && (
                    <div className="flex-stretch white-bottom">
                      <div
                        className="hub-prodotto-item__box-caratt col-12 col-md-9 m-all"
                        dangerouslySetInnerHTML={{
                          __html: caratteristicheValues[i],
                        }}
                      />
                      <div className="col-0 col-md-3 m-all" />
                    </div>
                  )}
                </div>
                {placet ? (
                  <div className="hub-prodotto-item__buttons">
                    {item?.content?.ricontatto?.value &&
                      item?.content?.ricontatto?.content?.formId?.value && (
                        <button
                          className="hub-prodotto-item__button"
                          onClick={handleClick}
                          id={i}
                          name="form"
                        >
                          <span>Richiedi un contatto</span> <SeeMore color="white" />
                        </button>
                      )}
                    {/* RIMOZIONE BUTTON "ATTIVA SUBITO L'OFFERTA" RICHIESTO DA TASK NEDH-3073 */}
                    {/* <button
                      className="hub-prodotto-item__button hub-prodotto-item__button--magenta"
                      onClick={handleClick}
                      id={i}
                      name="flusso-switch-in"
                    >
                      <span>Attiva subito l'offerta</span> <SeeMore color="white" />
                    </button> */}
                  </div>
                ) : (
                  (item.content.linkedContent?.value?.link ||
                    item.content.linkedContent?.value?.externalLink?.value) && (
                    <div className="hub-prodotto-item__box-linkwrapper">
                      <div className="hub-prodotto-item__box-link">
                        <Link
                          to={
                            item.content.linkedContent?.value?.link ||
                            item.content.linkedContent?.value?.externalLink?.value
                          }
                        >
                          <span>Scopri di più</span>
                          <SeeMore color="white" />
                        </Link>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HubProdotto;
