import React from "react";
import { graphql } from "gatsby";
import classNames from "classnames";
import HubItem from "../elements/hub-item";
import withPreview from "../../utility/with-preview";

const HubGridItem = ({ data }) => {
  const { linkpagina } = data;
  return (
    <section className="hub-grid-item wrapper-container px-0">
      <div className="container-fluid">
        <div className="row">
          {linkpagina?.map((item, i) => (
            <div
              className={classNames("px-0", {
                "col-sm-6": i % 5 < 2,
                "col-sm-4": i % 5 >= 2,
              })}
              key={i}
            >
              <HubItem
                background={item?.content?.immagine?.node}
                titolo={item?.content?.titolo?.value}
                descr={item?.content?.descrizione?.value}
                link={
                  item?.content?.linkedContent?.value?.link ||
                  item?.content?.linkedContent?.value?.externalLink?.value
                }
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default withPreview(HubGridItem);
export const fragment = graphql`
  fragment HubGridItemFragment on LiferayHubGridItem {
    liferayFields {
      siteId
      articleId
    }
    linkpagina {
      value
      content {
        titolo {
          value
        }
        descrizione {
          value
        }
        immagine {
          node {
            gatsbyImageData(width: 720)
          }
        }
        linkedContent {
          value {
            ... on LiferayJskLayoutPaginaGenerica {
              link
            }
            ... on LiferayJskLayoutPaginaPrivacy {
              link
            }
            ... on LiferayJskLayoutPaginaNews {
              link
            }
            ... on LiferayJskPaginaLink {
              externalLink: link {
                value
              }
            }
          }
        }
      }
    }
  }
`;
