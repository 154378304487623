import React, { useRef } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import SeeMore from "./see-more";

const HubItem = ({ background, titolo, descr, link, bar }) => {
  const ctaRef = useRef(null);
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className="hub-item" onClick={() => ctaRef.current?.click()}>
      {background && (
        <GatsbyImage
          className="hub-item__background"
          image={getImage(background)}
          alt={titolo || "hub-item"}
        />
      )}
      <div className="hub-item__text d-flex flex-column justify-content-end">
        {bar && <div className="hub-item__bar" />}
        {titolo && <div className="hub-item__title" dangerouslySetInnerHTML={{ __html: titolo }} />}
        {descr && <div className="hub-item__descr" dangerouslySetInnerHTML={{ __html: descr }} />}
        <div>
          <SeeMore ref={ctaRef} link={link} color="white" />
        </div>
      </div>
    </div>
  );
};

export default HubItem;
