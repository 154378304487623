import React, { useCallback } from "react";
import { graphql } from "gatsby";
import withPreview from "../../utility/with-preview";

const HeaderButtons = ({ data }) => {
  const { label } = data;

  const handleClick = useCallback(
    (e) => {
      const id = e.target.id;
      const formId = label[id].content.formId?.value;
      const fluxId = label[id].content.nomeProdottoLiferay?.value;
      if (formId) {
        const formEvent = new CustomEvent("start-form", {
          detail: {
            formId,
          },
        });
        console.log("Dispatching event", formEvent);
        document.dispatchEvent(formEvent);
      } else if (fluxId) {
        const fluxEvent = new CustomEvent("start-flusso-switch-in", {
          detail: {
            productName: fluxId,
          },
        });
        console.log("Dispatching event", fluxEvent);
        document.dispatchEvent(fluxEvent);
      }
    },
    [label]
  );

  return (
    <section className="section header-buttons wrapper-container">
      <div className="header-buttons__wrapper">
        {label?.map(({ value, content }, i) => (
          <button
            className="header-buttons__button"
            onClick={handleClick}
            style={{ backgroundColor: content?.coloreBackground?.value || "#00aaff" }}
            key={i}
            id={i}
          >
            {value}
          </button>
        ))}
      </div>
    </section>
  );
};

export default withPreview(HeaderButtons);
export const fragment = graphql`
  fragment JskHeaderButtonsFragment on LiferayJskHeaderButtons {
    liferayFields {
      siteId
      articleId
    }
    label {
      value
      content {
        coloreBackground {
          value
        }
        nomeProdottoLiferay {
          value
        }
        formId {
          value
        }
      }
    }
  }
`;
