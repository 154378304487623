import React, { useState } from "react";
import { graphql } from "gatsby";
import classNames from "classnames";
import Cookies from "js-cookie";
import SeeMore from "../elements/see-more";
import withPreview from "../../utility/with-preview";
import faceImg from "../../images/face.png";
import Icon from "../elements/icon-svg";

const CREA_SPAZIO_CLIENTI_LINK =
  "https://imprese.engie.it/piccole-medie-imprese?p_p_id=CustomerLogin_WAR_CustomerLoginportlet&p_p_lifecycle=1&p_p_state=normal&p_p_mode=view&_CustomerLogin_WAR_CustomerLoginportlet_javax.portlet.action=sforcePreferences&_CustomerLogin_WAR_CustomerLoginportlet_surl=https%3A%2F%2Fwebecrm.force.com%2Ftiger%2FCommunitiesRegisterNEW&p_auth=qOV3dQgx";

const FORM_ACTION =
  "/login-in-action-tiger?p_p_id=CustomerLogin_WAR_CustomerLoginportlet&p_p_lifecycle=1&p_p_state=normal&_CustomerLogin_WAR_CustomerLoginportlet_javax.portlet.action=sforceLogin&_CustomerLogin_WAR_CustomerLoginportlet_login_from=tiger";

const HeaderButtonsSpazioClienti = ({ data }) => {
  const [formShow, setFormShow] = useState(false);

  const onSubmit = () => Cookies.set("ecrm", "tiger");

  return (
    <section className="section header-buttons header-buttons--spazio-clienti wrapper-container">
      <div className="header-buttons__wrapper">
        <div className="header-buttons__header">
          {formShow ? (
            <>
              <img src={faceImg} alt="User" />
              <span className="header-buttons__form-title">Spazio clienti</span>
              <form
                className="form header-buttons__form"
                method="post"
                action={FORM_ACTION}
                onSubmit={onSubmit}
              >
                <input label="login_from" name="login_from" type="hidden" value="tiger" />
                <input name="login_profile" type="hidden" value="" />
                <input name="realAncestorURL" type="hidden" value="/landing-pages" />
                <div className="form__element">
                  <input
                    id="_CustomerLogin_WAR_CustomerLoginportlet_username"
                    className="form__input"
                    name="_CustomerLogin_WAR_CustomerLoginportlet_username"
                    placeholder="Username"
                  />
                  <label
                    htmlFor="_CustomerLogin_WAR_CustomerLoginportlet_username"
                    className="form__label"
                  >
                    Username
                  </label>
                </div>
                <div className="form__element">
                  <input
                    type="password"
                    id="_CustomerLogin_WAR_CustomerLoginportlet_password"
                    className="form__input"
                    name="_CustomerLogin_WAR_CustomerLoginportlet_password"
                    placeholder="Password"
                  />
                  <label
                    htmlFor="_CustomerLogin_WAR_CustomerLoginportlet_password"
                    className="form__label"
                  >
                    Password
                  </label>
                </div>
                <a
                  href="https://webecrm.force.com/tiger/CommunitiesRecCredenziali"
                  className="form__link"
                >
                  Recupera
                </a>
                <button type="submit" className="form__submit">
                  Invia
                </button>
              </form>
            </>
          ) : (
            <>
              <Icon name="luggage" /> <span>Seleziona il tuo spazio clienti</span>
            </>
          )}
        </div>
        <div className="header-buttons__content">
          <button
            onClick={() => setFormShow((current) => !current)}
            className={classNames("header-buttons__button", formShow && "active")}
          >
            {!formShow && <span>Spazio clienti</span>} <SeeMore />
          </button>
          {formShow ? (
            <a href={CREA_SPAZIO_CLIENTI_LINK} className="header-buttons__button">
              <span>Crea il tuo spazio clienti</span> <SeeMore />
            </a>
          ) : (
            <a
              href="https://nomine-powerb2b.gdfsuez.it/E4Cdm/"
              className="header-buttons__button header-buttons__button--green"
            >
              Portale Nomine Power <SeeMore />
            </a>
          )}
        </div>
      </div>
    </section>
  );
};

export default withPreview(HeaderButtonsSpazioClienti);
export const fragment = graphql`
  fragment JskFormSpazioClientiFragment on LiferayJskFormSpazioClienti {
    liferayFields {
      siteId
      articleId
    }
    formId {
      value
    }
  }
`;
