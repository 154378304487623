import React, { useMemo } from "react";
import { graphql } from "gatsby";
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { Link } from "../link";
import withPreview from "../../utility/with-preview";
import SeeMore from "../elements/see-more";

const CallActionItemsCircle = ({ data, location }) => {
  const { webContent, showDisabledLinks } = data;

  const isChildPage = useMemo(
    () => webContent?.map((item) => item.value.link).includes(location.pathname),
    [webContent, location.pathname]
  );

  return (
    <section className="section call-action-items-circle wrapper-container">
      <div className="container d-flex flex-wrap">
        {webContent?.map((item, i) => (
          <Link
            to={`${item.value.link || item.value.externalLink?.value}`}
            key={i}
            className={classNames(
              "call-action-items-circle__card d-flex flex-column align-items-center",
              ((isChildPage &&
                location.pathname !== item.value?.link &&
                location.pathname !== item.value.externalLink?.value) ||
                showDisabledLinks?.value) &&
                "call-action-items-circle__card--disabled"
            )}
          >
            <div
              className={classNames(
                "call-action-items-circle__icon-wrapper",
                `bg-${item.content.color?.value?.[0]}`
              )}
            >
              <Img
                className="call-action-items-circle__icon"
                image={getImage(item.content.icon.node)}
                alt={item.content.icon.value?.description || ""}
              />
            </div>

            <h4
              className={classNames(
                "call-action-items-circle__text",
                item.content.color?.value?.[0]
              )}
            >
              {item.content?.label?.value}
            </h4>
            <SeeMore color={item.content.color?.value?.[0]} />
          </Link>
        ))}
      </div>
    </section>
  );
};

export default withPreview(CallActionItemsCircle);
export const fragment = graphql`
  fragment JskCallActionItemsCircleFragment on LiferayJskCallActionItemsCircle {
    liferayFields {
      siteId
      articleId
    }
    showDisabledLinks {
      value
    }
    webContent {
      value {
        ... on LiferayJskLayoutPaginaGenerica {
          link
        }
        ... on LiferayJskPaginaLink {
          externalLink: link {
            value
          }
        }
      }
      content {
        label {
          value
        }
        icon {
          node {
            gatsbyImageData
          }
          value {
            description
          }
        }
        color {
          value
        }
      }
    }
  }
`;
