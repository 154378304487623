import React, { useState, useCallback, useEffect } from "react";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import ControlledInput from "./controlled-input";
import elencoComuni from "../../data/gi_db_comuni/gi_comuni.json";

const FORM_VENDIBILITA_BASE_URL = process.env.GATSBY_FORM_VENDIBILITA;


const FormComune = ({ handleComune, tipologia = "b2c", title }) => {
  const [showNonCoperto, setShowNonCoperto] = useState(false);
  const [comuni, setComuni] = useState([]);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, touchedFields },
  } = useForm({
    mode: "onChange",
    delayError: 600,
  });
  const comuneWatch = watch("comune");
  const [codiciComuni, setCodiciComuni] = useState(new Map());

  useEffect(() => {
    const fetchComuni = async () => {
      const listaComuni = [];      
      Array.from(elencoComuni).forEach(function (_comune) {     
          let optionKey = _comune.denominazione_ita.toUpperCase()+" ("+_comune.sigla_provincia+")";
          listaComuni.push(optionKey);          
          setCodiciComuni(codiciComuni.set(optionKey,_comune.codice_istat));
      });
      setComuni(listaComuni);
    };
    fetchComuni();
  }, [tipologia]);

  const checkCoperturaComune = async (values) => {
    try {
      const codiceComune = codiciComuni.get(values.comune.toUpperCase());
      const url = `${FORM_VENDIBILITA_BASE_URL}/contatti.contact/get-copertura-condomini/params/${codiceComune}`;
      const response = await fetch(url);
      if (response.ok) {
        const coperturaResp = await response.json();
        const comperturaData = JSON.parse(coperturaResp);
        if (comperturaData.copertura === "Si") {
          handleComune(values.comune.toUpperCase());
        } else {
          setShowNonCoperto(true);
        }
        reset();
      }
      return null;
    } catch (err) {
      console.error(err);
    }
  };

  const validateComune = useCallback(
    (value) => {
      if (value?.length > 1) {
        return (
          comuni.includes(value.trim().toUpperCase()) ||
          "Ci spiace, il comune che hai inserito sembra non esistere o è una frazione"
        );
      }
      return "Inserire un comune valido";
    },
    [comuni]
  );
  return !showNonCoperto ? (
    <>
      {title && (
        <div className="row">
          <div
            className="form__title col-md-11 offset-md-1 px-3 px-md-0"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </div>
      )}
      <div className="row">
        <div className="col-12">
          <form
            className="form-contatti__form form-contatti__form--comune pt-0"
            onSubmit={handleSubmit(checkCoperturaComune)}
            noValidate
          >
            <div className="row">
              <div className="col-md-8 offset-md-1">
                <ControlledInput
                  name="comune"
                  control={control}
                  validate={validateComune}
                  options={comuni}
                  movingLabel={false}
                  minChars={2}
                  label="Comune"
                  iconName="map-marker-alt"
                  className="form-contatti--comune mb-4"
                  error={errors.comune}
                  touched={touchedFields.comune}
                  isDirty={comuneWatch}
                  check={true}
                />
              </div>
              <div className="col-md-2 text-center mb-4">
                <button
                  className={classNames("form__submit")}
                  disabled={!comuneWatch || comuneWatch?.length < 3}
                >
                  Verifica
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  ) : (
    <div className="row">
      <div className="col-md-12 text-center">
        <div className="form-contatti__formTitle">
          Ci spiace, non siamo presenti nel tuo comune.
        </div>
        <div className="text-center">
          <button
            className="form-contatti__retry"
            onClick={() => {
              reset({ comune: "" });
              setShowNonCoperto(false);
            }}
          >
            Vuoi verificare la disponibilità per un comune diverso? Clicca qui.
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormComune;
