import React from "react";
import { graphql } from "gatsby";
import classNames from "classnames";
import { useInView } from "react-intersection-observer";
import { Link } from "../link";
import withPreview from "../../utility/with-preview";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const WidgetTestuale = ({ data }) => {
  const {
    titolo,
    sottotitolo,
    contenuto,
    cta,
    ctaType,
    img: immagine,
    effect,
    ratio,
    backColor,
  } = data;
  const halfRatio = ratio?.value?.[0] === "half";
  const thirdRatio = ratio?.value?.[0] === "third";
  const ctaPosition =
    immagine?.node || immagine?.content?.videoEmbedd?.value
      ? "text-left"
      : cta?.content?.ctaAlign?.value?.[0];

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <section
      ref={ref}
      className={classNames("section widget-testuale ", {
        "section--left-effect": inView && effect?.value?.[0] === "leftEffect",
        "section--right-effect": inView && effect?.value?.[0] === "rightEffect",
        "section--fade-in-up-effect": inView && effect?.value?.[0] === "fadeInUpEffect",
      })}
      style={{ backgroundColor: backColor?.value }}
    >
      <div className="container-fluid">
        {titolo?.value && (
          <div className="row">
            <div className="col-12">
              <h2
                className={classNames(
                  "section__title",
                  titolo.content.titlePosition.value?.[0] || "align-center"
                )}
                style={{ color: titolo.content.titleColor?.value }}
                dangerouslySetInnerHTML={{ __html: titolo.value }}
              />
            </div>
          </div>
        )}
        {sottotitolo?.value && (
          <div className="row">
            <div className="col-12">
              <h2
                className={classNames(
                  "mb-3",
                  sottotitolo?.content.allineamentoSottotiolo?.value?.[0] || "align-center"
                )}
                dangerouslySetInnerHTML={{ __html: sottotitolo.value }}
              />
            </div>
          </div>
        )}
        {contenuto?.value && (
          <div className="row">
            <div
              className={classNames("d-flex flex-column justify-content-center", {
                "col-md-6": (immagine?.node || immagine?.content?.videoEmbedd?.value) && halfRatio,
                "col-md-8": (immagine?.node || immagine?.content?.videoEmbedd?.value) && thirdRatio,
                "col-12": !immagine?.node || !immagine?.content?.videoEmbedd?.value,
                "order-md-2": immagine?.content?.posImage?.value?.[0] === "sx",
              })}
            >
              <div
                className={classNames("widget-testuale__content", {
                  "text-left": contenuto.content?.allineamento?.value?.[0] === "sx",
                  "text-right": contenuto.content?.allineamento?.value?.[0] === "dx",
                  "text-center": contenuto.content?.allineamento?.value?.[0] === "cx",
                  "text-justified": contenuto.content?.allineamento?.value?.[0] === "ju",
                })}
                dangerouslySetInnerHTML={{ __html: contenuto?.value }}
              />
              {cta?.value &&
                cta.content?.ctaLink?.value &&
                cta.content.ctaLink.value !== "#" &&
                ["text-left", "text-right"].includes(ctaPosition) && (
                  <div className={classNames("d-none d-md-block ", ctaPosition)}>
                    <Link
                      to={cta.content.ctaLink.value}
                      title={cta.value}
                      className={classNames(
                        "mt-2 cta-link",
                        `btn-color-${cta.content?.ctaColore?.value}`,
                        {
                          "cta-link--secondary": ctaType?.value?.[0] === "second-cta",
                        }
                      )}
                    >
                      {cta.value}
                    </Link>
                  </div>
                )}
            </div>
            {immagine?.node ? (
              <div
                className={classNames("d-flex justify-content-center align-items-center", {
                  "col-md-6": halfRatio,
                  "col-md-4": thirdRatio,
                })}
              >
                <GatsbyImage
                  image={getImage(immagine.node)}
                  alt={immagine.value?.description}
                  style={{
                    maxWidth: "100%",
                    maxHeight: immagine.node.probeInfo?.height,
                  }}
                  imgStyle={{
                    maxWidth: immagine.node.probeInfo?.width,
                    maxHeight: immagine.node.probeInfo?.height,
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </div>
            ) : (
              immagine?.content?.videoEmbedd?.value && (
                <div
                  className={classNames("d-flex justify-content-center align-items-center", {
                    "col-md-6": halfRatio,
                    "col-md-4": thirdRatio,
                  })}
                >
                  <iframe
                    src={immagine.content.videoEmbedd.value}
                    frameBorder="0"
                    title="Video"
                    height="400"
                    width="600"
                    allowFullScreen={true}
                  ></iframe>
                </div>
              )
            )}
          </div>
        )}
        {cta?.value && cta.content?.ctaLink?.value && cta.content.ctaLink.value !== "#" && (
          <div className={classNames("row", ctaPosition !== "text-center" && "d-md-none")}>
            <div className="col-12 text-center">
              <Link
                to={cta.content.ctaLink.value}
                title={cta.value}
                className={classNames(
                  "mt-4 cta-link",
                  `btn-color-${cta.content?.ctaColore?.value}`,
                  {
                    "cta-link--secondary": ctaType?.value?.[0] === "second-cta",
                  }
                )}
              >
                {cta.value}
              </Link>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default withPreview(WidgetTestuale);
export const fragment = graphql`
  fragment JskWidgetTestualeFragment on LiferayJskWidgetTestuale {
    liferayFields {
      siteId
      articleId
    }
    titolo {
      value
      content {
        titleColor {
          value
        }
        titlePosition {
          value
        }
      }
    }
    sottotitolo {
      content {
        allineamentoSottotitolo {
          value
        }
      }
      value
    }
    contenuto {
      value
      content {
        allineamento {
          value
        }
      }
    }
    cta {
      value
      content {
        ctaColore {
          value
        }
        ctaLink {
          value
        }
        ctaAlign {
          value
        }
      }
    }
    ctaType {
      value
    }
    img: immagine {
      value {
        description
      }
      node {
        publicURL
        gatsbyImageData(width: 640, layout: CONSTRAINED)
        probeInfo {
          width
          height
        }
      }
      content {
        videoEmbedd {
          value
        }
        posImage {
          value
        }
      }
    }
    backColor {
      value
    }
    ratio {
      value
    }
    effect {
      value
    }
  }
`;
