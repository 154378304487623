import React, { useEffect, useMemo, useReducer, useState } from "react";
import { graphql } from "gatsby";
import Cookies from "js-cookie";
import Loadable from "@loadable/component";
import flussiData from "../../data/prodotti/index.json";
import {
  stepCookies,
  offerteMapping,
  flussiInit,
  elencoProdottiBreadcrumb,
  fornituraCategoriaMapping,
} from "../../utility/config-flussi-switch-in";
import withPreview from "../../utility/with-preview";
import { dataLayerPush } from "../../utility/dataLayerUtils";

const FlussoModal = Loadable(() => import("../flussi-switch-in/flusso-modal"));

const flussoReducer = (state, action) => {
  const { type, payload } = action;
  //console.log("action: ", action);
  switch (type) {
    case "START_FLUSSO": {
      const { flussoName, flussoConfig, location, codCoupon, utmParams, codicePromo } = payload;
      const initFunc = flussiInit[flussoName];
      const initState = initFunc(location, flussoConfig);
      return {
        nomeFlusso: flussoConfig.nome,
        utmParams,
        flussoConfig,
        data: {},
        cookies: {
          swPaginaPartenza: encodeURIComponent(location.href),
          switchinProdotto: flussoConfig.codice,
          switchinOfferta: flussoConfig.nome,
          switchinNomeProdotto: flussoConfig.nome,
          switchinTipoCliente: "azienda",
          ecommerce: "y",
          codiceAmico: codCoupon,
          utmParamCookie: utmParams
            ? new URLSearchParams(Object.entries(utmParams)).toString()
            : "",
          switchinCodicePromo: codicePromo,
        },
        stepFlusso: 0,
        ...initState,
      };
    }
    case "PREV": {
      // Salto lo step "tariffa-luce" se ho selezionato "gas"
      const prevStep = state.listaStep[state.stepFlusso - 1];
      if (prevStep === "tariffa-luce" && state.data.fornitura === "gas") {
        return {
          ...state,
          stepFlusso: Math.min(state.stepFlusso - 2, state.listaStep.length - 1),
        };
      }
      return {
        ...state,
        stepFlusso: Math.max(0, state.stepFlusso - 1),
      };
    }
    case "NEXT": {
      // Salto lo step "tariffa-luce" e azzero eventuali valori se ho selezionato "gas"
      const nextStep = state.listaStep[state.stepFlusso + 1];
      if (nextStep === "tariffa-luce" && state.data.fornitura === "gas") {
        const newData = { ...state.data };
        delete newData["tariffa-luce"];
        return {
          ...state,
          data: {
            ...newData,
          },
          cookies: {
            ...state.cookies,
            switchinNomeProdotto: state.flussoConfig.nome,
          },
          stepFlusso: Math.min(state.stepFlusso + 2, state.listaStep.length - 1),
          endFlusso: !nextStep,
        };
      }
      return {
        ...state,
        stepFlusso: Math.min(state.stepFlusso + 1, state.listaStep.length - 1),
        endFlusso: !nextStep,
      };
    }
    case "CANCEL": {
      return {
        ...state,
        stepFlusso: -1,
      };
    }
    case "SET_PARAMETER": {
      const { key, value } = payload;
      return {
        ...state,
        data: {
          ...state.data,
          [key]: value,
        },
      };
    }
    case "PUSH_DATA": {
      const { fornitura, flussoConfig } = payload;
      dataLayerPush({
        event: "addToCart",
        ecommerce: {
          currencyCode: "EUR",
          add: {
            products: [
              {
                id: flussoConfig.codice,
                name: elencoProdottiBreadcrumb.get(flussoConfig.nome),
                brand: "",
                category: flussoConfig.tipologia,
                variant: fornituraCategoriaMapping[fornitura],
                quantity: 1,
              },
            ],
          },
        },
      });
      return state;
    }
    default:
      return state;
  }
};

const UTM_PARAMS = [
  "endtoend",
  "subscription",
  "tduid",
  "utm_medium",
  "utm_source",
  "utm_campaign",
  "utm_content",
];

const FlussoSwitchIn = ({ data, location }) => {
  const { nomeProdottoLiferay } = data;

  const flussiMap = useMemo(() => {
    return nomeProdottoLiferay?.reduce((res, item) => {
      if (item.content?.nomeFlusso?.value?.[0]) {
        res.set(item.value, item.content.nomeFlusso.value[0]);
      }
      return res;
    }, new Map());
  }, [nomeProdottoLiferay]);

  const [loadedParams, setLoadedParams] = useState();
  const [coupon, setCoupon] = useState();
  const [utmObj, setUtmObj] = useState();
  const [codicePromo, setCodicePromo] = useState();

  useEffect(() => {
    if (!loadedParams) {
      const params = new URLSearchParams(location?.search);
      const utmObj = UTM_PARAMS.reduce((res, name) => {
        const value = params.get(name);
        return value ? { ...res, [name]: value } : res;
      }, {});
      setUtmObj(utmObj);
      const codCoupon = params.get("codCoupon");
      setCoupon(codCoupon);
      const id = params.get("id");
      const endtoend = params.get("endtoend");
      const subscription = params.get("subscription");
      if (id || endtoend) {
        const codicePromo =
          subscription && endtoend ? `${endtoend}_${subscription}` : endtoend ? endtoend : id;
        setCodicePromo(codicePromo);
      }
      setLoadedParams(true);
    }
  }, [location, loadedParams]);

  const [state, dispatch] = useReducer(flussoReducer, { data: {}, stepFlusso: -1 });
  const {
    data: flussoData,
    cookies: flussoCookies,
    endFlusso,
    utmParams,
    listaStep,
    stepFlusso,
  } = state;
  const currentStep = listaStep?.[stepFlusso];

  useEffect(() => {
    const listener = (e) => {
      const { productName } = e.detail || {};
      const flussoConfig = flussiData.find((item) => item.nome === productName);
      const flussoName = flussiMap.get(productName);
      if (flussoConfig && flussoName) {
        dispatch({
          type: "START_FLUSSO",
          payload: {
            productName,
            flussoName,
            flussoConfig,
            location,
            codCoupon: coupon,
            utmParams: utmObj,
            codicePromo,
          },
        });
      }
    };
    document.addEventListener("start-flusso-switch-in", listener);
    return () => document.removeEventListener("start-flusso-switch-in", listener);
  }, [flussiMap, location, coupon, utmObj, codicePromo]);

  useEffect(() => {
    if (flussoData && flussoCookies) {
      const offerta = flussoCookies?.switchinOfferta;
      const mapping = offerteMapping[offerta];
      Object.entries(flussoCookies)
        .map(([key, value]) => ({
          cookie: key,
          value,
        }))
        .concat(
          Object.entries(flussoData).map(([key, value]) => ({
            cookie: stepCookies[key],
            value: key === "tariffa-luce" && mapping ? mapping[value] : value,
          }))
        )
        .forEach(({ cookie, value }) => {
          console.info("Update flusso cookie: ", cookie, value);
          if (value) {
            Cookies.set(cookie, value);
          } else {
            Cookies.remove(cookie);
          }
        });
    }
  }, [flussoData, flussoCookies]);

  useEffect(() => {
    if (endFlusso || currentStep === "spinner") {
      const paramEntries = Object.entries(utmParams);
      window.location.href = `/ecommerce-attivazione-offerta/${
        paramEntries.length ? "?" + new URLSearchParams(paramEntries).toString() : ""
      }`;
    }
  }, [endFlusso, currentStep, utmParams]);

  return (
    <section className="flusso-switch-in">
      {state.stepFlusso >= 0 && <FlussoModal state={state} dispatch={dispatch} />}
    </section>
  );
};

export default withPreview(FlussoSwitchIn);
export const fragment = graphql`
  fragment JskFlussoSwitchInFragment on LiferayJskFlussoSwitchIn {
    liferayFields {
      siteId
      articleId
    }
    nomeProdottoLiferay {
      value
      content {
        nomeFlusso {
          value
        }
      }
    }
  }
`;
