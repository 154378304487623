import React from "react";
import { graphql } from "gatsby";
import Hero from "./fasce/hero";
import NumberBoxes from "./fasce/number-boxes";
import TwoColumns from "./fasce/two-columns";
import BoxFull from "./fasce/box-full";
import CorrelatedArticle2 from "./fasce/correlated-article2";
import LaunchBox from "./fasce/launch-box";
import HeroIcons from "./fasce/hero-icons";
import IntroSimple from "./fasce/intro-simple";
import ContactForm from "./fasce/contact-form";
import TessereParticulier from "./fasce/tessere-particulier";
import BottoneCtaIsolato from "./fasce/bottone-cta-isolato";
import Accordion from "./fasce/accordion";
import ElencoDocumenti from "./fasce/elenco-documenti";
import SlideHeader from "./fasce/hero-slideheader";
import SlideHeaderBis from "./fasce/slide-header-bis";
import Highlights3Items from "./fasce/highlights3items";
import Intro from "./fasce/intro";
import Highpoints4Items from "./fasce/highpoints4items";
import InfoTecnicheProdotto from "./fasce/info-tecniche-prodotto";
import HubProdottoPlacet from "./fasce/hub-prodotto-placet";
import EditorialeGenerico from "./fasce/editoriale-generico";
import ProdottiCorrelati from "./fasce/prodotti-correlati";
import Motivazioni from "./fasce/motivazioni";
import Faq from "./fasce/faq";
import OpzioniProdotto from "./fasce/opzioni-prodotto";
import SlideHeaderMobileView from "./fasce/slide-header-mobile-view";
import ScopriDiPiu from "./fasce/scopri-di-piu";
import ContattiIntro from "./fasce/contatti-intro";
import ContattiFaq from "./fasce/contatti-faq";
import HeaderButtons from "./fasce/header-buttons";
import InformationForm from "./fasce/information-form";
import PartnerCommercialeForm from "./fasce/partner-commerciale-form";
import FormConsulenza from "./fasce/consulenza-form";
import ContattiCanali from "./fasce/contatti-canali";
import TabsModule from "./fasce/tabs-module";
import FormContattiPmi from "./fasce/pmi-contact-form";
import CallActionItemsCircle from "./fasce/call-action-items-circle";
import VerificaCopertura from "./fasce/verifica-copertura";
import LinkIcons from "./fasce/link-icons";
import TilesList from "./fasce/tiles-list";
import SliderHeaderHomeCta from "./fasce/slider-header-home-cta";
import HeaderButtonsSpazioClienti from "./fasce/header-buttons-spazio-clienti";
import HubGridItem from "./fasce/hub-grid-item";
import CallActionItems from "./fasce/call-action-items";
import HubProdottoItem from "./fasce/hub-prodotto-item";
import WidgetTestuale from "./fasce/widget-testuale";
import Timeline from "./fasce/timeline";
import IconListParagraph from "./fasce/icon-list-paragraph";
import Hub1Item from "./fasce/hub-1-item";
import AggregatoreNews from "./fasce/aggregatore-news";
import FlussoSwitchIn from "./fasce/flusso-switch-in";
import SlideHeaderSameB2c from "./fasce/slide-header-same-b2c";

const FasciaPaginaGenerica = ({ fascia, ...other }) => {
  switch (fascia.__typename) {
    case "LiferaySlideHeaderHpImpreseNew":
      return <Hero data={fascia} {...other} />;
    case "LiferayBoxNumeri":
      return <NumberBoxes data={fascia} {...other} />;
    case "LiferayProdottoInformativoNew":
      return <TwoColumns data={fascia} {...other} />;
    case "LiferayBannerVideoNew":
      return <BoxFull data={fascia} {...other} />;
    case "LiferayBannerInformativoNew":
      return <CorrelatedArticle2 data={fascia} {...other} />;
    case "LiferayChoiceSelector":
      return <LaunchBox data={fascia} {...other} />;
    case "LiferaySlideHeaderIconeNew":
      return <HeroIcons data={fascia} {...other} />;
    case "LiferayIntroSimpleNew":
      return <IntroSimple data={fascia} {...other} />;
    case "LiferayContattiImpreseNew":
      return <ContactForm data={fascia} {...other} />;
    case "LiferayTessereParticulier":
      return <TessereParticulier data={fascia} {...other} />;
    case "LiferayBottoneCtaIsolato":
      return <BottoneCtaIsolato data={fascia} {...other} />;
    case "LiferayAccordion":
      return <Accordion data={fascia} {...other} />;
    case "LiferayElencoDocumenti":
      return <ElencoDocumenti data={fascia} {...other} />;
    case "LiferaySlideHeader":
      return <SlideHeader data={fascia} {...other} />;
    case "LiferaySlideHeaderBis":
      return <SlideHeaderBis data={fascia} {...other} />;
    case "LiferayHighlights3Items":
      return <Highlights3Items data={fascia} {...other} />;
    case "LiferayIntro":
      return <Intro data={fascia} {...other} />;
    case "LiferayHighpoints4Items":
      return <Highpoints4Items data={fascia} {...other} />;
    case "LiferayInfoTecnicheProdotto":
      return <InfoTecnicheProdotto data={fascia} {...other} />;
    case "LiferayHubProdottoPlacet":
      return <HubProdottoPlacet data={fascia} {...other} />;
    case "LiferayEditorialeGenerico":
      return <EditorialeGenerico data={fascia} {...other} />;
    case "LiferayProdottiCorrelati":
      return <ProdottiCorrelati data={fascia} {...other} />;
    case "LiferayMotivazioni":
      return <Motivazioni data={fascia} {...other} />;
    case "LiferayJskSlideHeaderMobileView":
      return <SlideHeaderMobileView data={fascia} {...other} />;
    case "LiferayJskFaq":
      return <Faq data={fascia} {...other} />;
    case "LiferayJskOpzioniProdotto":
      return <OpzioniProdotto data={fascia} {...other} />;
    case "LiferayJskScopriDiPiu":
      return <ScopriDiPiu data={fascia} {...other} />;
    case "LiferayJskContattiIntro":
      return <ContattiIntro data={fascia} {...other} />;
    case "LiferayJskContattiFaq":
      return <ContattiFaq data={fascia} {...other} />;
    case "LiferayJskHeaderButtons":
      return <HeaderButtons data={fascia} {...other} />;
    case "LiferayJskFormInformazioni":
      return <InformationForm data={fascia} {...other} />;
    case "LiferayJskFormPartnerCommerciale":
      return <PartnerCommercialeForm data={fascia} {...other} />;
    case "LiferayJskFormConsulenza":
      return <FormConsulenza data={fascia} {...other} />;
    case "LiferayJskContattiCanali":
      return <ContattiCanali data={fascia} {...other} />;
    case "LiferayJskTabsModule":
      return <TabsModule data={fascia} {...other} />;
    case "LiferayJskFormContattiPmi":
      return <FormContattiPmi data={fascia} {...other} />;
    case "LiferayJskCallActionItemsCircle":
      return <CallActionItemsCircle data={fascia} {...other} />;
    case "LiferayJskVerificaCopertura":
      return <VerificaCopertura data={fascia} {...other} />;
    case "LiferayJskLinkIcons":
      return <LinkIcons data={fascia} {...other} />;
    case "LiferayTilesList":
      return <TilesList data={fascia} {...other} />;
    case "LiferaySliderHeaderHomeCta":
      return <SliderHeaderHomeCta data={fascia} {...other} />;
    case "LiferayJskFormSpazioClienti":
      return <HeaderButtonsSpazioClienti data={fascia} {...other} />;
    case "LiferaySlideHeaderSameB2C":
      return <SlideHeaderSameB2c data={fascia} {...other} />;
    case "LiferayHubGridItem":
      return <HubGridItem data={fascia} {...other} />;
    case "LiferayCallAction3Items":
      return <CallActionItems data={fascia} {...other} />;
    case "LiferayHubProdottoItem":
      return <HubProdottoItem data={fascia} {...other} />;
    case "LiferayJskWidgetTestuale":
      return <WidgetTestuale data={fascia} {...other} />;
    case "LiferayJskTimeline":
      return <Timeline data={fascia} {...other} />;
    case "LiferayJskIconListParagraph":
      return <IconListParagraph data={fascia} {...other} />;
    case "LiferayHub1Item":
      return <Hub1Item data={fascia} {...other} />;
    case "LiferayJskAggregatoreNews":
      return <AggregatoreNews data={fascia} {...other} />;
    case "LiferayJskFlussoSwitchIn":
      return <FlussoSwitchIn data={fascia} {...other} />;
    default:
      console.warn(`Cannot handle ${fascia.__typename} type for pagina generica`);
      return null;
  }
};

export default FasciaPaginaGenerica;
export const query = graphql`
  fragment FasciaPaginaGenericaFragment on LiferayWebContent {
    __typename
    ...SiteHeaderHeroFragment
    ...BoxnumeriFragment
    ...ProdottoinformativoFragment
    ...BannervideoFragment
    ...BannerinformativoFragment
    ...ChoiceselectorFragment
    ...SlideHeaderIconeNewFragment
    ...IntrosimpleFragment
    ...ContactFormFragment
    ...TessereParticulierFragment
    ...BottoneCtaIsolatoFragment
    ...AccordionFragment
    ...ElencoDocumentiFragment
    ...Highlights3ItemsFragment
    ...SlideHeaderFragment
    ...SlideHeaderBisFragment
    ...IntroFragment
    ...Highpoints4ItemsFragment
    ...InfoTecnicheProdottoFragment
    ...HubProdottoPlacetFragment
    ...EditorialeGenericoFragment
    ...ProdottiCorrelatiFragment
    ...MotivazioniFragment
    ...JskSlideHeaderMobileViewFragment
    ...JskFaqFragment
    ...JskOpzioniProdottoFragment
    ...JskScopriDiPiuFragment
    ...JskContattiIntroFragment
    ...JskContattiFaqFragment
    ...JskHeaderButtonsFragment
    ...JskFormInformazioniFragment
    ...JskFormPartnerCommercialeFragment
    ...JskFormConsulenzaFragment
    ...JskContattiCanaliFragment
    ...JskTabsModuleFragment
    ...JskFormContattiPmiFragment
    ...JskCallActionItemsCircleFragment
    ...JskVerificaCoperturaFragment
    ...JskLinkIconsFragment
    ...TilesListFragment
    ...SliderHeaderHomeCtaFragment
    ...JskFormSpazioClientiFragment
    ...SlideHeaderSameB2CFragment
    ...HubGridItemFragment
    ...CallAction3ItemsFragment
    ...HubProdottoItemFragment
    ...JskWidgetTestualeFragment
    ...JskTimelineFragment
    ...JskIconListParagraphFragment
    ...Hub1ItemFragment
    ...AggregatoreNewsFragment
    ...JskFlussoSwitchInFragment
  }
`;
