const FORM_CONSULENZA = process.env.GATSBY_FORM_PMI_CONTATTI_ENDPOINT;

export const submitFormConsulenza = async (values, location) => {
  const companyId = "10201";
  const groupId = "2441430";
  const { name, surname, email, tel, comune, messaggio = "", privacy, grecaptcha } = values;
  const submitData = {
    resourceId: "condomini",
    categoria: "ContattiCondominio",
    companyId,
    groupId,
    nome: name,
    cognome: surname,
    comune,
    telefono: tel,
    email,
    messaggio,
    urlProv: encodeURIComponent(location.pathname),
    privacy,
    ...(grecaptcha ? { grecaptcha: grecaptcha } : null),
  };
  console.log("submit params: ", submitData);
  const fetchUrl = FORM_CONSULENZA;
  const dataParams = new URLSearchParams();
  dataParams.append("request", JSON.stringify(submitData));
  const res = await fetch(fetchUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: dataParams,
  });
  if (!res.ok) {
    throw new Error(`Error: ${res.status} ${res.statusText}`);
  }
  const result = await res.json();
  const resultData = JSON.parse(result);
  console.log("Result: ", resultData);
  if (resultData.respCode === "ok") {
    return resultData;
  } else {
    throw new Error(`Form response not ok`);
  }
};
