import { useEffect } from "react";

const useBlockScroll = (block) => {
  useEffect(() => {
    if (block) {
      document.body.classList.add("block-scroll-modal");
    } else {
      document.body.classList.remove("block-scroll-modal");
    }
  }, [block]);
};

export default useBlockScroll;
