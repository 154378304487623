import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image";
import withPreview from "../../utility/with-preview";
import { Link } from "../link";
import SeeMore from "../elements/see-more";

const IconCard = ({ i, icon, children }) => {
  const linkDest =
    icon?.content?.webContent?.value?.link ||
    icon?.content?.externalLink?.value ||
    "/" + icon?.content?.webContent?.value?.externalLink?.value;

  return linkDest ? (
    <Link className="link-icons__icon-card" to={linkDest}>
      <>{children}</>
    </Link>
  ) : (
    <div className="link-icons__icon-card">
      <>{children}</>
    </div>
  );
};

const LinkIcons = ({ data }) => {
  const { title, icon } = data;

  return (
    <section className="section link-icons wrapper-container">
      <div className="container">
        <div className="row">
          {(title?.value || title?.content?.subtitle?.value) && (
            <div className="col-md-3">
              <div className="d-flex flex-column justify-content-center h-100">
                {title?.value && <h4>{title.value}</h4>}
                {title?.content?.subtitle?.value && (
                  <h5 dangerouslySetInnerHTML={{ __html: title.content.subtitle.value }} />
                )}
              </div>
            </div>
          )}
          <div className="col-md-9">
            <div className="link-icons__wrapper d-flex justify-content-between">
              {icon?.map((icon, i) => (
                <IconCard icon={icon} i={i} key={i}>
                  {icon?.node && (
                    <Img
                      className="link-icons__icon"
                      image={getImage(icon.node)}
                      alt={icon.value?.description || ""}
                    />
                  )}
                  {icon?.content.iconTitle?.value && (
                    <div className="link-icons__icon-title">{icon.content.iconTitle.value}</div>
                  )}
                  {icon?.content.iconSubtitle?.value && (
                    <div className="link-icons__icon-subtitle">
                      {icon.content.iconSubtitle.value}
                    </div>
                  )}
                  <SeeMore color="blu" />
                </IconCard>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(LinkIcons);
export const linkIconsFragment = graphql`
  fragment JskLinkIconsFragment on LiferayJskLinkIcons {
    liferayFields {
      siteId
      articleId
    }
    title {
      value
      content {
        subtitle {
          value
        }
      }
    }
    icon {
      node {
        gatsbyImageData
      }
      value {
        description
      }
      content {
        externalLink {
          value
        }
        iconSubtitle {
          value
        }
        iconTitle {
          value
        }
        webContent {
          value {
            ... on LiferayJskLayoutPaginaGenerica {
              link
            }
            ... on LiferayJskPaginaLink {
              externalLink: link {
                value
              }
            }
          }
        }
      }
    }
  }
`;
