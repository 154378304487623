import React from "react";
import { graphql } from "gatsby";
import withPreview from "../../utility/with-preview";
import SlideHeaderContent from "../slide-header-content";

const SliderHeaderHomeCta = ({ data }) => {
  const { immagine, posizionebgmobile } = data;

  return (
    <section className="section slider-header-home-cta">
      <SlideHeaderContent
        slide={immagine?.[0]}
        titolo={immagine?.[0]?.content?.titolo}
        descrizione={immagine?.[0]?.content?.descrizione}
        variant={posizionebgmobile?.value && `bg-mobile-${posizionebgmobile.value}`}
        cta={{
          value: immagine?.[0]?.content?.etichettalink?.value || "Scopri di più",
          content: {
            colorebottone: "magenta",
            ctaLink: {
              value:
                immagine?.[0]?.content?.link?.value || immagine?.[0]?.content?.ancoraAlLink?.value,
            },
            webContent: immagine?.[0]?.content?.linkedContent,
          },
        }}
      />
    </section>
  );
};

export default withPreview(SliderHeaderHomeCta);
export const fragment = graphql`
  fragment SliderHeaderHomeCtaFragment on LiferaySliderHeaderHomeCta {
    liferayFields {
      siteId
      articleId
    }
    immagine {
      node {
        gatsbyImageData(layout: FULL_WIDTH)
        description
      }
      content {
        titolo {
          value
        }
        linkedContent {
          value {
            ... on LiferayJskLayoutPaginaGenerica {
              link
            }
            ... on LiferayJskLayoutPaginaPrivacy {
              link
            }
            ... on LiferayJskPaginaLink {
              externalLink: link {
                value
              }
            }
          }
        }
        link {
          value
        }
        ancoraAlLink {
          value
        }
        descrizione {
          value
        }
        etichettalink {
          value
        }
      }
    }
    posizionebgmobile {
      value
    }
  }
`;
