import React, { useEffect, useRef, useState } from "react";
import { graphql } from "gatsby";
import classNames from "classnames";
import withPreview from "../../utility/with-preview";
import ContattiCanali from "./contatti-canali";
import Intro from "./intro";
import VerificaCopertura from "./verifica-copertura";
import ConsulenzaForm from "./consulenza-form";

const FasciaTab = ({ fascia, ...other }) => {
  switch (fascia.__typename) {
    case "LiferayIntro":
      return <Intro data={fascia} {...other} />;
    case "LiferayJskContattiCanali":
      return <ContattiCanali data={fascia} {...other} />;
    case "LiferayJskVerificaCopertura":
      return <VerificaCopertura data={fascia} {...other} />;
    case "LiferayJskFormConsulenza":
      return <ConsulenzaForm data={fascia} {...other} />;
    case "LiferayJskTabsModule":
      return <TabsModule data={fascia} {...other} />;
    default:
      console.warn(`Cannot handle ${fascia.__typename} type for tab module`);
      return null;
  }
};

const hasColor = (color, active, tab, variantType) =>
  variantType === "big" && !active && tab.content?.color?.value?.[0] === color;
const hasBgColor = (color, active, tab, variantType) =>
  ((variantType === "big" && active) || variantType === "small") &&
  tab.content?.color?.value?.[0] === color;

const TabsModule = ({ data, location }) => {
  const {
    title,
    variant,
    tabs,
    liferayFields: { articleId },
  } = data;
  const variantType = variant?.value?.[0] || "big";

  const tablistRef = useRef();
  const [tabFocus, setTabFocus] = useState(-1);
  const handleKeyDown = (e) => {
    const tabsCount = tablistRef.current.children.length;
    let newFocus = tabFocus >= 0 ? tabFocus : 0;
    if (e.keyCode === 39 || e.keyCode === 37) {
      // Move right
      if (e.keyCode === 39) {
        // If we're at the end, go to the start
        newFocus = (tabFocus + 1) % tabsCount;
        // Move left
      } else if (e.keyCode === 37) {
        // If we're at the start, move to the end
        newFocus = (tabFocus + tabsCount - 1) % tabsCount;
      }
      tablistRef.current.children[newFocus].children[0].focus();
    }
  };
  const handleFocus = (e) => setTabFocus(parseInt(e.target.name));
  const handleBlur = (e) => setTabFocus(-1);
  const [activeTab, setActiveTab] = useState(-1);
  const selectTab = (event) => {
    const tabId = parseInt(event.currentTarget.name);
    setActiveTab(tabId);
    setTabFocus(tabId);
  };
  const scrollRef = useRef(false);
  useEffect(() => {
    if (scrollRef.current) {
      const targetElement = document.querySelector(`#tab-${articleId}-${activeTab}-anchor`);
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: "smooth",
        });
      }
    } else {
      scrollRef.current = true;
    }
  }, [articleId, activeTab]);

  return (
    <section
      className={classNames("section tabs-module wrapper-container", {
        "tabs-module--big": variantType === "big",
        "tabs-module--small": variantType === "small",
        "tabs-module--mono": variantType === "mono",
      })}
    >
      <div className="container-fluid">
        {title?.value && (
          <div className="row">
            <div className="col-12">
              <div className="tabs-module__title">{title.value}</div>
            </div>
          </div>
        )}
        <div className="tabs-module__tabs">
          <div
            ref={tablistRef}
            className="row"
            role="tablist"
            onKeyDown={handleKeyDown}
            aria-label={title?.value}
            tabIndex={0}
          >
            {tabs.slice(0, 3).map((tab, i) => (
              <div
                className={classNames("px-0", {
                  "col-sm-4": tabs.length >= 3,
                  "col-sm-6": variantType === "big" && tabs.length < 3,
                  "col-sm-5":
                    (variantType === "small" || variantType === "mono") && tabs.length < 3,
                  "offset-sm-1":
                    (variantType === "small" || variantType === "mono") &&
                    i === 0 &&
                    tabs.length < 3,
                })}
                key={i}
              >
                <button
                  role="tab"
                  aria-selected={activeTab === i}
                  aria-controls={`panel-${articleId}-${i}`}
                  id={`tab-${articleId}-${i}`}
                  tabIndex={activeTab === i || tabFocus === i ? 0 : -1}
                  name={i}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  className={classNames("tabs-module__tab", {
                    fucsia: hasColor("fucsia", activeTab === i, tab, variantType),
                    yellow: hasColor("yellow", activeTab === i, tab, variantType),
                    blu: hasColor("blu", activeTab === i, tab, variantType),
                    "bg-fucsia": hasBgColor("fucsia", activeTab === i, tab, variantType),
                    "bg-yellow": hasBgColor("yellow", activeTab === i, tab, variantType),
                    "bg-blu": hasBgColor("blu", activeTab === i, tab, variantType),
                    active: activeTab === i,
                  })}
                  onClick={selectTab}
                >
                  {tab.value}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      {tabs.slice(0, 3).map((tab, i) => (
        <div
          id={`panel-${articleId}-${i}`}
          role="tabpanel"
          aria-labelledby={`tab-${articleId}-${i}`}
          hidden={activeTab !== i}
          key={i}
        >
          <span className="scroll-anchor" id={`tab-${articleId}-${i}-anchor`}></span>
          <FasciaTab fascia={tab.content.component.value} location={location} />
        </div>
      ))}
    </section>
  );
};

export default withPreview(TabsModule);
export const tabsModuleFragment = graphql`
  fragment JskTabsModuleFragment on LiferayJskTabsModule {
    liferayFields {
      siteId
      articleId
    }
    variant {
      value
    }
    title {
      value
    }
    tabs {
      value
      content {
        color {
          value
        }
        component {
          value {
            __typename
            ...IntroFragment
            ...JskContattiCanaliFragment
            ...JskVerificaCoperturaFragment
            ...JskFormConsulenzaFragment
            ... on LiferayJskTabsModule {
              liferayFields {
                siteId
                articleId
              }
              variant {
                value
              }
              title {
                value
              }
              tabs {
                value
                content {
                  color {
                    value
                  }
                  component {
                    value {
                      __typename
                      ...IntroFragment
                      ...JskContattiCanaliFragment
                      ...JskVerificaCoperturaFragment
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
