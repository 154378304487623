import React from "react";
import Modal from "react-overlays/Modal";
import useBlockScroll from "../utility/useBlockScroll";

const renderBackdrop = (props) => <div className="info-dialog__backdrop" {...props} />;

const InfoDialog = ({ show, setShow, text, link, label }) => {
  useBlockScroll(show);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      className="info-dialog"
      renderBackdrop={renderBackdrop}
    >
      <>
        <div className="info-dialog__content">
          <button className="info-dialog__xBtn" onClick={() => setShow(false)}>
            X
          </button>
          {text && <div dangerouslySetInnerHTML={{ __html: text }}></div>}
          {link && (
            <div>
              <a href={link} className="info-dialog__link">
                {label || link}
              </a>
            </div>
          )}
        </div>
      </>
    </Modal>
  );
};

export default InfoDialog;
