import React from "react";
import { graphql } from "gatsby";
import withPreview from "../../utility/with-preview";

const Intro = ({ data: { titolo, sottotitolo, intro } }) => {
  return (
    <section className="wrapper-container">
      <div className="intro">
        {titolo?.value && (
          <div
            className="section__title intro__title text-center"
            dangerouslySetInnerHTML={{ __html: titolo.value }}
          ></div>
        )}
        {sottotitolo?.value && <div className="intro__sottotitolo">{sottotitolo.value}</div>}
        <div dangerouslySetInnerHTML={{ __html: intro.value }} className="intro__text"></div>
      </div>
    </section>
  );
};

export default withPreview(Intro);
export const introFragment = graphql`
  fragment IntroFragment on LiferayIntro {
    liferayFields {
      articleId
      siteId
    }
    titolo {
      value
    }
    sottotitolo {
      value
    }
    intro {
      value
    }
  }
`;
