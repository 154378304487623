import React, { useState, useCallback } from "react";
import Icon from "../elements/icon-svg";

const NjSelect = ({
  id = "njSelect",
  name = "",
  warning,
  label,
  options,
  option,
  placeHolder = "Seleziona",
  register,
  errorMessage,
}) => {
  const [focus, setFocus] = useState(false);
  const onFocus = useCallback(() => setFocus(true), []);
  const onBlur = useCallback(() => setFocus(false), []);

  return (
    <div
      className={`nj-form-group${option ? " is-filled" : ""}${focus ? " is-focused" : ""}${
        warning ? " has-warning" : ""
      }`}
    >
      <select
        id={id}
        className="nj-form-control"
        name={name}
        onFocus={onFocus}
        onBlur={onBlur}
        {...register(name, {
          required: {
            value: true,
            message: errorMessage,
          },
        })}
      >
        <option value="" disabled={true}>
          {placeHolder}
        </option>
        {options.map((option) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <label htmlFor={id} className="nj-label-static">
        {label}
      </label>
      <Icon name="keyboard-arrow-down" />
    </div>
  );
};

export default NjSelect;
