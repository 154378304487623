import React from "react";
import { graphql } from "gatsby";
import Cta from "../elements/cta";
import ImageWrapper from "../elements/image-wrapper";
import withPreview from "../../utility/with-preview";

const IconListParagraph = ({ data }) => {
  const { title, detail, cta, coloreSfondo } = data;

  return (
    <section className="section pt-5" style={{ backgroundColor: coloreSfondo?.value }}>
      <div className="wrapper-container">
        <div className="container-fluid">
          {title?.value && (
            <div className="row">
              <div className="col-md-12 text-center">
                <h2 className="section__title">{title.value}</h2>
              </div>
            </div>
          )}
          {detail?.length ? (
            <div className="row">
              {detail.map((item, i) => (
                <div key={i} className="col-md-6">
                  <div className="box-icon d-flex align-items-center">
                    {item.content?.icon?.node && (
                      <ImageWrapper
                        image={item.content.icon.node}
                        alt={item.content?.description?.value || ""}
                        className="box-icon__img"
                      />
                    )}
                    <div
                      className="box-icon__desc"
                      dangerouslySetInnerHTML={{ __html: item.content?.description?.value }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
          {cta?.value && cta?.content?.ctaLink?.value && cta?.content?.ctaLink?.value !== "#" && (
            <Cta cta={cta} />
          )}
        </div>
      </div>
    </section>
  );
};

export default withPreview(IconListParagraph);
export const fragment = graphql`
  fragment JskIconListParagraphFragment on LiferayJskIconListParagraph {
    liferayFields {
      siteId
      articleId
    }
    title {
      value
    }
    detail {
      value
      content {
        description {
          value
        }
        icon {
          node {
            gatsbyImageData(formats: PNG, width: 66, layout: FIXED)
            publicURL
          }
        }
      }
    }
    cta {
      value
      content {
        colorebottone {
          value
        }
        ctaLink {
          value
        }
      }
    }
    coloreSfondo {
      value
    }
  }
`;
