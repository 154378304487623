import React from "react";
import classNames from "classnames";
import Icon from "../elements/icon-svg";

export const FormTextInput = ({
  name,
  id,
  label,
  placeholder,
  validation,
  register,
  iconName,
  readOnly,
  error,
  password = false,
  labelPlaceholder = false,
  filled,
  ...others
}) => {
  return (
    <div
      className={classNames("form__element", {
        "form__element--label-ph": labelPlaceholder,
        "form__element--icon": iconName,
        "form__element--filled": labelPlaceholder && filled,
      })}
      name={name}
    >
      <input
        type={password ? "password" : "text"}
        className="form__input"
        name={name}
        id={id}
        placeholder={labelPlaceholder ? undefined : placeholder}
        {...register(name, validation)}
        disabled={readOnly}
        {...others}
      />
      {iconName && <Icon name={iconName} />}
      <label htmlFor={id} className="form__label">
        {labelPlaceholder ? label : placeholder}
      </label>
      {error && <p className="form__error">{error.message}</p>}
    </div>
  );
};

export const FormSelect = ({
  name,
  id,
  placeholder,
  validation,
  register,
  error,
  options,
  ...others
}) => {
  return (
    <div className="form__element" name={name}>
      <select
        defaultValue=""
        className="form__select"
        {...register(name, validation)}
        name={name}
        id={id}
        {...others}
      >
        <option disabled={true} value="">
          {placeholder}
        </option>
        {options.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
      <label htmlFor={id} className="form__label">
        {placeholder}
      </label>
      <Icon name="chevron-left" />
      {error && <p className="form__error">{error.message}</p>}
    </div>
  );
};

export const FormFileInput = ({
  name,
  id,
  placeholder,
  validation,
  register,
  iconName,
  error,
  ...others
}) => {
  return (
    <div className="form__element" name={name}>
      <input
        type="file"
        className="form__input"
        name={name}
        id={id}
        placeholder={placeholder}
        {...register(name, validation)}
        error={error}
        {...others}
      />
      {iconName && <Icon name={iconName} />}
      <label htmlFor={id} className="form__label">
        {placeholder}
      </label>
      {error && <p className="form__error">{error.message}</p>}
    </div>
  );
};

export const FormTextArea = ({
  name,
  id,
  placeholder,
  validation,
  register,
  iconName,
  error,
  options,
  ...others
}) => {
  return (
    <div className="form__element form__element--area" name={name}>
      {iconName && (
        <Icon
          name={iconName}
          cls="form__icon form__icon--left"
          style={{ "--width": "20px", "--height": "20px" }}
        />
      )}
      <textarea
        className="form__input"
        name={name}
        id={id}
        placeholder={placeholder}
        {...register(name, validation)}
        error={error}
        {...others}
      />
      <label htmlFor={id} className="form__label">
        {placeholder}
      </label>
      {error && <p className="form__error">{error.message}</p>}
    </div>
  );
};

export const FormCheckbox = ({ name, label, validation, register, error, ...others }) => {
  return (
    <div className="form__element form__element--checkbox">
      <label className="form__label">
        <input type="checkbox" name={name} {...register(name, validation)} {...others} />
        {label}
      </label>
      {error && <p className="form__error">{error.message}</p>}
    </div>
  );
};
