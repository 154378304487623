import React, { useCallback, useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image";
import withPreview from "../../utility/with-preview";
import { Link } from "../link";
import classNames from "classnames";
// import { Link } from "../link";
// import VideoModal from "../video-modal";
// import Icon from "../elements/icon-svg";

const TwoColumns = ({ data: { mainImage, mainText, mainTitle, imgPositionSx, btnText } }) => {
  const [hover, setHover] = useState(false);
  const onHover = useCallback(() => setHover(true), []);
  const onOut = useCallback(() => setHover(false), []);

  return (
    <section className={classNames("section section--padded two-columns", hover && "hover")}>
      <div className="container">
        <div className={`row mb-4 pb-4`}>
          <div
            className={`col-12 order-2 offset-lg-1 ${
              imgPositionSx?.value ? "col-lg-4 order-lg-1" : "col-lg-4 order-lg-2"
            }`}
          >
            <div className="d-flex flex-column h-100  justify-content-center">
              {mainTitle ? <h3 className="mb-4">{mainTitle.value}</h3> : null}
              {(mainTitle.content.mainSubtitle.value || mainText?.value) && (
                <div className="two-columns__text-container mb-4">
                  {mainTitle.content.mainSubtitle.value ? (
                    <p className="text-blue-corporate font-weight-bold two-columns__subtitle">
                      {mainTitle.content.mainSubtitle.value}
                    </p>
                  ) : null}
                  {mainText?.value ? (
                    <div
                      className="two-coumns__text mb-3"
                      dangerouslySetInnerHTML={{ __html: mainText.value }}
                    ></div>
                  ) : null}
                  {mainText.content.textDetails ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: mainText.content.textDetails.value }}
                    ></div>
                  ) : null}
                </div>
              )}
              <div>
                {btnText?.content?.btnLink?.value ? (
                  <Link
                    to={btnText.content.btnLink.value}
                    className="nj-btn nj-btn--lg nj-btn--primary align-self-start"
                    onMouseOver={onHover}
                    onFocus={onHover}
                    onMouseOut={onOut}
                    onBlur={onOut}
                  >
                    {btnText.value}
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 order-1 px-0 px-sm-2 mb-2 mb-lg-0">
            <Img
              className="two-columns__img"
              image={getImage(mainImage.node)}
              alt={mainImage.node.description || ""}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(TwoColumns);
export const twoColumnsFragment = graphql`
  fragment ProdottoinformativoFragment on LiferayProdottoInformativoNew {
    liferayFields {
      siteId
      articleId
    }
    mainImage {
      node {
        gatsbyImageData(width: 688)
      }
    }
    imgPositionSx {
      value
    }
    mainTitle {
      value
      content {
        mainSubtitle {
          value
        }
      }
    }
    mainText {
      value
      content {
        textDetails {
          value
        }
      }
    }
    btnText {
      value
      content {
        btnLink {
          value
        }
      }
    }
  }
`;
