import React, { useCallback, useState } from "react";
import { Controller } from "react-hook-form";
import classNames from "classnames";
import TextInput from "react-autocomplete-input";
import Icon from "../elements/icon-svg";

const ControlledInput = ({
  name,
  control,
  required,
  validate,
  isSubmitted,
  error,
  label,
  iconName,
  isDirty,
  movingLabel = true,
  touched,
  check,
  className,
  ...others
}) => {
  const [hasFocus, setHasFocus] = useState(false);
  const handleFocus = useCallback(() => setHasFocus(true), []);
  const handleBlur = useCallback(() => setHasFocus(false), []);
  return (
    <div
      className={classNames("form__element", className, {
        "form__element--focus": hasFocus || isDirty,
      })}
    >
      {movingLabel ? (
        <>
          <Controller
            name={name}
            control={control}
            rules={{
              required,
              validate,
            }}
            render={({ field: { onBlur, ...field } }) => (
              <TextInput
                {...field}
                Component="input"
                trigger=""
                spacer=""
                autoComplete="off"
                maxOptions={4}
                onBlur={(e) => {
                  onBlur(e);
                  handleBlur(e);
                }}
                onFocus={handleFocus}
                className={classNames("form__input-text", error && "form__input-text--error")}
                id={name}
                {...others}
              />
            )}
          />
          <label className="form__label-input-text" htmlFor={name}>
            <Icon name={iconName} cls="mr-1" />
            {label}
          </label>
          <span
            className={classNames(
              "form__errorMsg form__errorMsg--block",
              error ? "d-block" : "d-none"
            )}
          >
            {error?.message}
          </span>
        </>
      ) : (
        <>
          <Controller
            name={name}
            control={control}
            rules={{
              required,
              validate,
            }}
            render={({ field: { onBlur, ...field } }) => (
              <span
                className={classNames("form__input-text-container", {
                  "form__input-text-container--error": error,
                  "form__input-text-container--ok": touched && field.value && !error,
                })}
              >
                <Icon
                  name={iconName}
                  cls="form__icon form__icon--left"
                  style={{ "--width": "18px", "--height": "18px" }}
                />
                <label className="d-none" htmlFor={name}>
                  {label || name}
                </label>
                <TextInput
                  {...field}
                  Component="input"
                  trigger=""
                  spacer=""
                  regex="^[a-zA-Z0-9_\-\s]+$"
                  maxOptions={4}
                  className={classNames(
                    "form__input-text form__input-text--fixed-icon",
                    error && "form__input-text--error"
                  )}
                  onBlur={(e) => {
                    onBlur(e);
                    handleBlur(e);
                  }}
                  onFocus={handleFocus}
                  autoComplete="off"
                  placeholder={label}
                  id={name}
                  {...others}
                />
                {check && touched && field.value && !error && (
                  <Icon
                    name="check"
                    cls="form__icon form__icon--right"
                    style={{ "--width": "18px", "--height": "18px" }}
                  />
                )}
              </span>
            )}
          />
          <span
            className={classNames(
              "form__errorMsg form__errorMsg--block",
              error ? "d-block" : "d-none"
            )}
          >
            {error?.message}
          </span>
        </>
      )}
    </div>
  );
};

export default ControlledInput;
