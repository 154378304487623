import React, { useState } from "react";
import { graphql } from "gatsby";
import classNames from "classnames";
import SeeMore from "../elements/see-more";
import withPreview from "../../utility/with-preview";

const FaqList = ({ list, show = true, startOpen = -1 }) => {
  const [openIdx, setOpenIdx] = useState(startOpen);
  const toggle = (event) => {
    const idx = parseInt(event.currentTarget.name);
    setOpenIdx((current) => (current === idx ? -1 : idx));
  };
  return (
    <div className={show ? "d-block" : "d-none"}>
      {list?.map((faq, i) => (
        <div className={classNames("contatti-faq__box", i === openIdx && "active")} key={i}>
          <button
            className="contatti-faq__faq d-flex align-items-center justify-content-between"
            name={i}
            onClick={toggle}
          >
            <span className="contatti-faq__faq-title">{faq.value}</span>
            <SeeMore className="mt-0 ml-3" />
          </button>
          <div
            className="contatti-faq__description"
            dangerouslySetInnerHTML={{ __html: faq.content.faqDescription.value }}
          ></div>
        </div>
      ))}
    </div>
  );
};

const ContattiFaq = ({ data }) => {
  const { title, section, liferayFields } = data;
  const [openSection, setOpensection] = useState(0);
  const showSection = (event) => {
    const targetElement = document.querySelector(`#faq-${liferayFields.articleId}`);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
      });
    }
    setOpensection(parseInt(event.currentTarget.name));
  };
  return (
    <section className="section contatti-faq wrapper-container px-0">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
            <div className="contatti-faq__title">{title?.value}</div>
          </div>
        </div>
        {section?.length > 1 ? (
          <div className="row">
            <div className="col-sm-5 px-0 pl-sm-0 pr-sm-3">
              {section?.map((item, i) => (
                <button
                  className={classNames(
                    "contatti-faq__section-title d-flex justify-content-between align-items-center",
                    openSection === i && "active"
                  )}
                  name={i}
                  onClick={showSection}
                  key={i}
                >
                  <span>{item.value}</span>
                  <SeeMore className="mt-0 ml-3" />
                </button>
              ))}
            </div>
            <div className="col-sm-7 px-1 px-sm-3">
              <span className="scroll-anchor" id={`faq-${liferayFields.articleId}`}></span>
              {section?.map((item, i) => (
                <FaqList
                  list={item.content?.faqTitle}
                  show={openSection === i}
                  startOpen={0}
                  key={i}
                />
              ))}
            </div>
          </div>
        ) : (
          section[0] && (
            <div className="row">
              <div className="col-12 px-3 px-md-5 pb-5 pt-3">
                <FaqList list={section[0].content?.faqTitle} startOpen={0} />
              </div>
            </div>
          )
        )}
      </div>
    </section>
  );
};

export default withPreview(ContattiFaq);
export const contattiFaqFragment = graphql`
  fragment JskContattiFaqFragment on LiferayJskContattiFaq {
    liferayFields {
      siteId
      articleId
    }
    title {
      value
    }
    section {
      value
      content {
        faqTitle {
          value
          content {
            faqDescription {
              value
            }
          }
        }
      }
    }
  }
`;
