import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage as Img, getImage, withArtDirection } from "gatsby-plugin-image";
import classNames from "classnames";
import withPreview from "../../utility/with-preview";

const SlideHeaderMobileView = ({ data }) => {

  const { slide, slideTablet, slideMobile,  titolo, descrizione, descrizioneMobile } = data;
  const headerImg =  withArtDirection(
    getImage(slide?.node),
    [
      slideMobile?.node && {
        media: "(max-width: 768px)",
        image: getImage(slideMobile.node),
      },
      slideTablet?.node && {
        media: "(max-width: 992px)",
        image: getImage(slideTablet.node),
      },
      slide?.node && {
        media: "(max-width: 1200px)",
        image: getImage(slide.node),
      },
    ].filter(Boolean)
  );
  return (
    <div className="slide-header-mobile-view">
      <Img
        image={headerImg}
        objectPosition="100% 0"
        alt={slide.value?.description || ""}
        className="slide-header-mobile-view__img"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <h1
              className="slide-header-mobile-view__title"
              dangerouslySetInnerHTML={{ __html: titolo?.value }}
            ></h1>
            <div
              className={classNames(
                "slide-header-mobile-view__desc",
                descrizioneMobile?.value && "d-none d-md-block"
              )}
              dangerouslySetInnerHTML={{ __html: descrizione?.value }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withPreview(SlideHeaderMobileView);
export const fragment = graphql`
  fragment JskSlideHeaderMobileViewFragment on LiferayJskSlideHeaderMobileView {
    liferayFields {
      siteId
      articleId
    }
    slide {
      value {
        description
      }
      node {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    slideTablet {
      value {
        description
      }
      node {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    slideMobile {
      value {
        description
      }
      node {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    titolo {
      value
    }
    descrizione {
      value
    }
    descrizioneMobile {
      value
    }
    textShort {
      value
    }
    breadcrumb {
      value
    }
    textShort {
      value
    }
    imageCut {
      value
    }
    cdownDate {
      value
    }
    cta {
      value
      content {
        ctaColor {
          value
        }
        ctaLink {
          value
        }
        newTab {
          value
        }
      }
    }
    showProductStars {
      value
      content {
        dataProductId {
          value
        }
      }
    }
  }
`;
