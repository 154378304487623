const FORM_CONSULENZA = process.env.GATSBY_FORM_CONTATTI_ENDPOINT;

export const submitFormContatti = async (values, location) => {
  const companyId = "10201";
  const groupId = "2441430";
  const { nome, cognome, ragionesociale, email, telefono, provincia, servizio, piva, privacy, grecaptcha } = values;
  const submitData = {
    companyId,
    groupId,
    categoria: servizio,
    nome,
    cognome,
    "Ragione Sociale": ragionesociale,
    "Provincia di fornitura": provincia,
    "PartitaIVA": piva,
    telefono,
    email,
    urlProv: encodeURIComponent(location.pathname),
    privacy: privacy ? "on" : "",
    ...(grecaptcha ? { grecaptcha: grecaptcha } : null),
  };
  console.log("submit params: ", submitData);
  const fetchUrl = FORM_CONSULENZA;
  const dataParams = new URLSearchParams();
  dataParams.append("params", JSON.stringify(submitData));
  const res = await fetch(fetchUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: dataParams,
  });
  if (!res.ok) {
    throw new Error(`Error: ${res.status} ${res.statusText}`);
  }
  const result = await res.json();
  console.log("Result: ", result);
  if (result.contactId) {
    return result;
  } else {
    throw new Error(`Form response not ok`);
  } 
}
