import React, { useMemo } from "react";
import { graphql } from "gatsby";
import withPreview from "../../utility/with-preview";
import ImageWrapper from "../elements/image-wrapper";

const Highpoints4Items = ({ data }) => {
  const { intestazione } = data;

  const boxes = useMemo(() => {
    const {
      immagine1,
      titolo1,
      sottotitolo1,
      immagine2,
      titolo2,
      sottotitolo2,
      immagine3,
      titolo3,
      sottotitolo3,
      immagine4,
      titolo4,
      sottotitolo4,
      // ctaText,
    } = data;
    return [
      titolo1?.value && {
        titolo: titolo1?.value,
        sottotitolo: sottotitolo1?.value,
        immagine: immagine1?.node,
      },
      titolo2?.value && {
        titolo: titolo2?.value,
        sottotitolo: sottotitolo2?.value,
        immagine: immagine2?.node,
      },
      titolo3?.value && {
        titolo: titolo3?.value,
        sottotitolo: sottotitolo3?.value,
        immagine: immagine3?.node,
      },
      titolo4?.value && {
        titolo: titolo4?.value,
        sottotitolo: sottotitolo4?.value,
        immagine: immagine4?.node,
      },
    ].filter(Boolean);
  }, [data]);

  return (
    <div className="highpoints">
      {intestazione?.value && (
        <section className="section wrapper-container">
          <h1
            className="highpoints__intestazione align-center"
            dangerouslySetInnerHTML={{ __html: intestazione.value }}
          />
        </section>
      )}
      <section className="section wrapper-container">
        <div className="container-fluid">
          <div className="row">
            {boxes.map((box, i) => (
              <div className="col-md-6 pr-0" key={i}>
                <div className="highpoints__box border-b border-r d-flex flex-column flex-lg-row align-items-center">
                  {box.immagine && (
                    <div>
                      <ImageWrapper
                        image={box.immagine}
                        alt={box.immagine.description || ""}
                        className="highpoints__img"
                      />
                    </div>
                  )}
                  <div className="text-center text-lg-left">
                    <div
                      className="highpoints__title"
                      dangerouslySetInnerHTML={{ __html: box.titolo }}
                    />
                    {box.sottotitolo && (
                      <div
                        className="highpoints__sottotitolo"
                        dangerouslySetInnerHTML={{ __html: box.sottotitolo }}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default withPreview(Highpoints4Items);
export const fragment = graphql`
  fragment Highpoints4ItemsFragment on LiferayHighpoints4Items {
    liferayFields {
      articleId
      siteId
    }
    intestazione {
      value
    }
    immagine1 {
      node {
        publicURL
        gatsbyImageData
        description
      }
    }
    titolo1 {
      value
    }
    sottotitolo1 {
      value
    }
    immagine2 {
      node {
        publicURL
        gatsbyImageData
        description
      }
    }
    titolo2 {
      value
    }
    sottotitolo2 {
      value
    }
    immagine3 {
      node {
        publicURL
        gatsbyImageData
        description
      }
    }
    titolo3 {
      value
    }
    sottotitolo3 {
      value
    }
    immagine4 {
      node {
        publicURL
        gatsbyImageData
        description
      }
    }
    titolo4 {
      value
    }
    sottotitolo4 {
      value
    }
    ctaText {
      value
    }
  }
`;
