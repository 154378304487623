import React, { useCallback, useEffect, useState } from "react";
import { graphql } from "gatsby";
import withPreview from "../../utility/with-preview";
import Modal from "react-overlays/Modal";
import { useForm } from "react-hook-form";
import PrivacyModal from "../form/privacy-modal";
import { FormTextInput } from "../form/form-input";
import PrivacyCheckbox from "../form/privacy-checkbox";
import { submitFormInformation } from "../../utility/config-form-information";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const FormInformazioni = ({ data, location }) => {
  const { formId, title, ctaLabel, testoFlagPrivacy, successMessage } = data;
  const [submitted, setSubmitted] = useState();
  const [remoteError, setRemoteError] = useState();
  const [show, setShow] = useState(false);
  const renderBackdrop = (props) => (
    <button
      className="privacy-modal__backdrop"
      open={show}
      onClick={() => {
        setShow(false);
      }}
      {...props}
    />
  );
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [privacyModalVisible, setPrivacyModalVisible] = useState(false);

  useEffect(() => {
    const listener = (e) => {
      if (e.detail.formId === formId.value) {
        setShow(true);
      }
    };
    document.addEventListener("start-form", listener);
    return () => document.removeEventListener("start-form", listener);
  }, [formId]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const submit = useCallback(async (values) => {
    console.log(values);
    if (!executeRecaptcha) {
      console.warn("Execute recaptcha not yet available");
      return;
    }
     const token = await executeRecaptcha("formImprese");
    
    try {
      const result = await submitFormInformation({ ...values, grecaptcha: token }, data, location);
      console.log("Submit result: ", result);
      //typLeadStep(prodottoInteressato?.value, location);
      setSubmitted(true);
    } catch (err) {
      console.error("Error submit: ", err);
      setSubmitted(false);
      setRemoteError(true);
    }

  }, [location, data, executeRecaptcha]);

  return (
    <section className="section information-form wrapper-container">
      <Modal
        show={show}
        onHide={() => setShow(false)}
        className="information-form__modal"
        renderBackdrop={renderBackdrop}
      >
        <div className="information-form__modal-wrapper">
          <button className="information-form__closeBtn" onClick={() => setShow(false)}>
            X
          </button>
          <div className="row">
            <div className="col-12">
              <h3>{title?.value}</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p>{title?.content?.subtitle?.value}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {submitted ? (
                successMessage?.value ? (
                  <div
                    className="information-form__message"
                    dangerouslySetInnerHTML={{
                      __html: successMessage?.value,
                    }}
                  />
                ) : (
                  <div className="information-form__message">
                    Grazie per aver lasciato i tuoi dati.
                  </div>
                )
              ) : remoteError ? (
                <div className="information-form__message">
                  Si è verificato un errore nell'invio dei dati, riprova più tardi.
                </div>
              ) : (
                <form onSubmit={handleSubmit(submit)} className="form">
                  <div className="row">
                    <div className="col-md-6">
                      <FormTextInput
                        register={register}
                        name="nome"
                        id="informationFormNome"
                        placeholder="Nome"
                        validation={{
                          required: {
                            value: true,
                            message: "Specificare un nome.",
                          },
                          pattern: {
                            value: /^[a-zA-Z\s]*$/,
                            message: "Specificare un nome (solo caratteri e/o spazi).",
                          },
                        }}
                        error={errors.nome}
                      />
                    </div>
                    <div className="col-md-6">
                      <FormTextInput
                        register={register}
                        name="cognome"
                        id="informationFormCognome"
                        placeholder="Cognome"
                        validation={{
                          required: {
                            value: true,
                            message: "Specificare un cognome.",
                          },
                          pattern: {
                            value: /^[a-zA-Z\s]*$/,
                            message: "Specificare un cognome (solo caratteri e/o spazi).",
                          },
                        }}
                        error={errors.cognome}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <FormTextInput
                        register={register}
                        name="telefono"
                        id="informationFormTelefono"
                        placeholder="Numero di telefono"
                        validation={{
                          required: {
                            value: true,
                            message: "Specificare un numero di telefono.",
                          },
                          pattern: {
                            value: /^[0-9]*$/,
                            message: "Specificare un numero di telefono valido.",
                          },
                        }}
                        error={errors.telefono}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <PrivacyCheckbox
                        register={register}
                        error={errors.privacy}
                        setPrivacyModalVisible={setPrivacyModalVisible}
                        text={testoFlagPrivacy?.value}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form__submit">
                        <button type="submit">{ctaLabel?.value}</button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
          <PrivacyModal
            show={privacyModalVisible}
            setShow={setPrivacyModalVisible}
            text={testoFlagPrivacy?.content?.privacyDin?.value}
            small={true}
          />
        </div>
      </Modal>
    </section>
  );
};

export default withPreview(FormInformazioni);
export const fragment = graphql`
  fragment JskFormInformazioniFragment on LiferayJskFormInformazioni {
    liferayFields {
      siteId
      articleId
    }
    formId {
      value
    }
    title {
      value
      content {
        subtitle {
          value
        }
      }
    }
    ctaLabel {
      value
    }
    advName {
      value
    }
    testoFlagPrivacy {
      value
      content {
        privacyDin {
          value
        }
      }
    }
    successMessage {
      value
    }
  }
`;
