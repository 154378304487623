import React from "react";
import classNames from "classnames";
import { graphql } from "gatsby";
import { Link } from "../link";
import withPreview from "../../utility/with-preview";
import { handleContentAccessibility } from "../../utility/accessibility";
import SeeMore from "../elements/see-more";
import ImageWrapper from "../elements/image-wrapper";

const CallActionItem = ({ immagine, titolo, sottotitolo, link, ancora, is2items }) => {
  return titolo?.value ? (
    <Link to={link} className="call-action-items__box d-flex align-items-end">
      {immagine?.node && (
        <ImageWrapper
          className="call-action-items__box-image"
          image={immagine?.node}
          alt={titolo.value || ""}
        />
      )}
      <div className="call-action-items__text-wrapper align-self-end position-relative">
        <div className="call-action-items__box-title">
          {handleContentAccessibility(titolo.value)}
        </div>
        <div className="call-action-items__box-subtitle-wrapper">
          <h4
            className="call-action-items__box-subtitle mt-1"
            dangerouslySetInnerHTML={{ __html: sottotitolo.value }}
          ></h4>
          {ancora?.value && <span className="call-action-items__ancora">{ancora.value}</span>}
          <SeeMore color="white" />
        </div>
      </div>
    </Link>
  ) : null;
};

const CallActionItems = ({ data }) => {
  const {
    mainTitle,
    sfondoTitoloCardTrasparente,
    titolo1,
    sottotitolo1,
    immagine1,
    linkedContent1,
    customLink1,
    ancora1,
    titolo2,
    sottotitolo2,
    immagine2,
    linkedContent2,
    customLink2,
    ancora2,
    titolo3,
    sottotitolo3,
    immagine3,
    linkedContent3,
    customLink3,
    ancora3,
    seeAllLink,
  } = data;

  const itemsData = [
    {
      immagine: immagine1,
      titolo: titolo1,
      sottotitolo: sottotitolo1,
      link:
        linkedContent1?.value?.link ||
        linkedContent1?.value?.externalLink?.value ||
        (customLink1?.value !== "" ? customLink1.value : null),
      ancora: ancora1,
    },
    {
      immagine: immagine2,
      titolo: titolo2,
      sottotitolo: sottotitolo2,
      link:
        linkedContent2?.value?.link ||
        linkedContent2?.value?.externalLink?.value ||
        (customLink2?.value !== "" ? customLink2.value : null),
      ancora: ancora2,
    },
    {
      immagine: immagine3,
      titolo: titolo3,
      sottotitolo: sottotitolo3,
      link:
        linkedContent3?.value?.link ||
        linkedContent3?.value?.externalLink?.value ||
        (customLink3?.value !== "" ? customLink3.value : null),
      ancora: ancora3,
    },
  ];

  const is2items =
    [titolo1?.value, titolo2?.value, titolo3?.value].filter((title) => Boolean(title))?.length ===
    2;

  return (
    <section
      className={classNames(
        "section call-action-items wrapper-container px-0",
        is2items && "call-action-items--2-items",
        sfondoTitoloCardTrasparente?.value && "call-action-items--transparent"
      )}
    >
      {mainTitle?.value && (
        <div className="row mx-0">
          <div className="col-md-12">
            <h2
              className={classNames(
                "call-action-items__title",
                mainTitle.content?.titleDecoration?.value && "call-action-items__title--decoration"
              )}
            >
              {mainTitle.value}
            </h2>
          </div>
        </div>
      )}
      <div className="row mx-0">
        {itemsData?.map((itemData, i) => (
          <div
            className={classNames(
              "call-action-items__box-wrapper px-0",
              is2items ? "col-lg-6" : "col-lg-4"
            )}
            key={i}
          >
            <CallActionItem {...itemData} is2items={is2items} />
          </div>
        ))}
      </div>
      {seeAllLink?.value && (
        <div className="row mx-0">
          <div className="col-md-6 offset-md-6 px-0">
            <Link to={seeAllLink.value} className="call-action-items__see-all">
              <span>Vedi tutti</span>
              <SeeMore color="white" />
            </Link>
          </div>
        </div>
      )}
    </section>
  );
};

export default withPreview(CallActionItems);

export const fragment = graphql`
  fragment CallActionPageLinkFragment on LiferayWebContent {
    ... on LiferayJskLayoutPaginaGenerica {
      link
    }
    ... on LiferayJskPaginaLink {
      externalLink: link {
        value
      }
    }
    ... on LiferayJskLayoutPaginaNews {
      link
    }
  }
  fragment CallAction3ItemsFragment on LiferayCallAction3Items {
    id
    liferayFields {
      siteId
      articleId
    }
    mainTitle {
      value
      content {
        titleDecoration {
          value
        }
      }
    }
    sfondoTitoloCardTrasparente {
      value
    }
    titolo1 {
      value
    }
    sottotitolo1 {
      value
    }
    immagine1 {
      node {
        publicURL
        gatsbyImageData(width: 772)
      }
    }
    linkedContent1 {
      value {
        ...CallActionPageLinkFragment
      }
    }
    customLink1 {
      value
    }
    ancora1 {
      value
    }
    titolo2 {
      value
    }
    sottotitolo2 {
      value
    }
    immagine2 {
      node {
        publicURL
        gatsbyImageData(width: 772)
      }
    }
    linkedContent2 {
      value {
        ...CallActionPageLinkFragment
      }
    }
    customLink2 {
      value
    }
    ancora2 {
      value
    }
    titolo3 {
      value
    }
    sottotitolo3 {
      value
    }
    immagine3 {
      node {
        publicURL
        gatsbyImageData(width: 772)
      }
    }
    linkedContent3 {
      value {
        ...CallActionPageLinkFragment
      }
    }
    customLink3 {
      value
    }
    ancora3 {
      value
    }
    seeAllLink {
      value
    }
  }
`;
