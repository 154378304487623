import React, { useState, useRef, useCallback } from "react";
import { graphql } from "gatsby";
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import NjSelect from "../form/nj-select";
import PrivacyModal from "../form/privacy-modal";
import provinceList from "../../data/contact-form/province/province.json";
import serviziList from "../../data/contact-form/servizi/servizi.json";
import withPreview from "../../utility/with-preview";
import { useForm } from "react-hook-form";
import Icon from "../elements/icon-svg";
import PrivacyCheckbox from "../form/privacy-checkbox";
import { submitFormContatti } from "../../utility/config-form-contatti";

/* const FORM_ENDPOINT = process.env.GATSBY_FORM_ENDPOINT; */
const FORM_GROUPID = process.env.GATSBY_FORM_GROUPID || "5879978";

const ContactForm = ({ data: { mainTitle, mainImg }, location }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const watchValues = watch();

  const [privacyVisible, setPrivacyVisible] = useState(false);

  const formRef = useRef(null);

  const { executeRecaptcha } = useGoogleReCaptcha(); 

  const [submitted, setSubmitted] = useState(false);
  const [remoteError, setRemoteError] = useState(null);

  const [focusMap, setFocusMap] = useState({});
  const onFocus = useCallback((e) => {
    const { name } = e.target;
    setFocusMap((map) => ({ ...map, [name]: true }));
  }, []);
  const onBlur = useCallback((e) => {
    const { name } = e.target;
    setFocusMap((map) => ({ ...map, [name]: false }));
  }, []);

  const [loading, setLoading] = useState(false);
  const submit = useCallback(async (values) => {
    console.log(values);
    if (!executeRecaptcha) {
      console.warn("Execute recaptcha not yet available");
      return;
    }
    const token = await executeRecaptcha("formImprese");
  
   
    try {
      setLoading(true);
      const result = await submitFormContatti({ ...values, grecaptcha: token }, location);
      console.log("Submit result: ", result);
      //typLeadStep(prodottoInteressato?.value, location);
      setSubmitted(true);
    } catch (err) {
      console.error("Error submit: ", err);
      setSubmitted(false);
      setRemoteError("Si è verificato un errore");
    }
    setLoading(false);
  }, [location, executeRecaptcha]);

  const groupClass = (name) =>
    `nj-form-group nj-form-group--icon-right${focusMap[name] ? " is-focused" : ""}${
      watchValues[name] ? " is-filled" : ""
    }${errors[name] ? " has-warning" : ""}`;

  return (
    <div className="contact-form" id="contatti">
      <div className="container my-5 pb-5">
        <div className="row">
          <div className="col-lg-5 px-md-0">
            <div className="contact-form__wrapper p-3 p-md-6 d-flex flex-column h-100">
              {mainImg && mainImg.node ? (
                <Img
                  image={getImage(mainImg.node)}
                  alt={mainImg.node.description || ""}
                  className="contact-form__img"
                />
              ) : null}
              {/* {mainImg.content ? (
                <div className="contact-form__gradient-box position-relative text-white d-flex flex-column justify-content-end pl-4 pb-5 pr-4 pt-4 mt-auto">
                  {mainImg.content.boxTitle && mainImg.content.boxTitle.value ? (
                    <div className="contact-form__box-title contact-form__box-title--big mt-4">
                      {mainImg.content.boxTitle.value}
                    </div>
                  ) : null}
                  {mainImg.content.boxText && mainImg.content.boxText.value ? (
                    <div
                      className="contact-form__box-subtitle mt-2"
                      dangerouslySetInnerHTML={{ __html: mainImg.content.boxText.value }}
                    ></div>
                  ) : null}
                </div>
              ) : null} */}
            </div>
          </div>
          <div ref={formRef} className="col-lg-7 px-md-0">
            <div className="contact-form__content py-5 px-3 px-md-5 pr-md-7 h-100">
              <div className="row">
                <div className="col-12">
                  <h3 className="contact-form__title">
                    {mainTitle ? mainTitle.value : "Richiesta di contatto"}
                  </h3>
                  <div className="contact-form__subtitle">
                    {mainTitle.content && mainTitle.content.mainSubtitle.value && (
                      <div
                        dangerouslySetInnerHTML={{ __html: mainTitle.content.mainSubtitle.value }}
                      ></div>
                    )}
                  </div>
                </div>
              </div>
              {submitted ? (
                <div className="row">
                  <div className="col-12 text-center mt-lg-5 pt-lg-5">
                    <h3>Grazie per averci lasciato i tuoi dati</h3>
                  </div>
                </div>
              ) : (
                <form onSubmit={handleSubmit(submit)}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className={groupClass("nome")}>
                        <div className="contact-form__text" name="nome">
                          <input
                            type="text"
                            className="nj-form-control"
                            name="nome"
                            id="contactFormNome"
                            onFocus={onFocus}
                            onBlur={onBlur}
                            {...register("nome", {
                              required: {
                                value: true,
                                message: "Specificare un nome.",
                              },
                              pattern: {
                                value: /^[a-zA-Z\s]*$/,
                                message: "Specificare un nome (solo caratteri e/o spazi).",
                              },
                            })}
                            error={errors.nome}
                          />
                          <Icon name="user" />
                          <label htmlFor="contactFormNome" className="nj-label-floating">
                            Nome
                          </label>
                        </div>
                      </div>
                      {errors.nome ? (
                        <span className="contact-form__warning">{errors.nome.message}</span>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <div className={groupClass("cognome")}>
                        <div className="contact-form__text">
                          <input
                            type="text"
                            className="nj-form-control"
                            name="cognome"
                            id="contactFormCognome"
                            onFocus={onFocus}
                            onBlur={onBlur}
                            {...register("cognome", {
                              required: {
                                value: true,
                                message: "Specificare un cognome.",
                              },
                              pattern: {
                                value: /^[a-zA-Z\s]*$/,
                                message: "Specificare un cognome (solo caratteri e/o spazi).",
                              },
                            })}
                            error={errors.cognome}
                          />
                          <Icon name="user" />
                          <label htmlFor="contactFormCognome" className="nj-label-floating">
                            Cognome
                          </label>
                        </div>
                      </div>
                      {errors.cognome ? (
                        <span className="contact-form__warning">{errors.cognome.message}</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className={groupClass("telefono")}>
                        <div className="contact-form__text">
                          <input
                            type="text"
                            className="nj-form-control"
                            name="telefono"
                            id="contactFormTelefono"
                            onFocus={onFocus}
                            onBlur={onBlur}
                            {...register("telefono", {
                              required: {
                                value: true,
                                message: "Specificare un numero di telefono.",
                              },
                              pattern: {
                                value: /^[0-9]*$/,
                                message: "Specificare un numero di telefono valido.",
                              },
                            })}
                            error={errors.telefono}
                          />
                          <Icon name="phone" />
                          <label htmlFor="contactFormTelefono" className="nj-label-floating">
                            Telefono
                          </label>
                        </div>
                      </div>
                      {errors.telefono ? (
                        <span className="contact-form__warning">{errors.telefono.message}</span>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <div className={groupClass("email")}>
                        <div className="contact-form__text">
                          <input
                            type="email"
                            className="nj-form-control"
                            name="email"
                            id="contactFormEmail"
                            autoComplete="email"
                            onFocus={onFocus}
                            onBlur={onBlur}
                            {...register("email", {
                              required: {
                                value: true,
                                message: "Specificare un indirizzo email.",
                              },
                              pattern: {
                                value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                message: "Specificare un indirizzo email valido.",
                              },
                            })}
                            error={errors.email}
                          />
                          <Icon name="envelope" />
                          <label htmlFor="contactFormEmail" className="nj-label-floating">
                            Indirizzo e-mail
                          </label>
                        </div>
                      </div>
                      {errors.email ? (
                        <span className="contact-form__warning">{errors.email.message}</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className={groupClass("ragionesociale")}>
                        <div className="contact-form__text">
                          <input
                            type="text"
                            className="nj-form-control"
                            name="ragionesociale"
                            id="contactFormRagioneSociale"
                            onFocus={onFocus}
                            onBlur={onBlur}
                            {...register("ragionesociale", {
                              required: {
                                value: true,
                                message: "Specificare una ragione sociale.",
                              },
                            })}
                            error={errors.ragionesociale}
                          />
                          <label htmlFor="contactFormRagioneSociale" className="nj-label-floating">
                            Ragione Sociale
                          </label>
                        </div>
                      </div>
                      {errors.ragionesociale ? (
                        <span className="contact-form__warning">
                          {errors.ragionesociale.message}
                        </span>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <div className={groupClass("piva")}>
                        <div className="contact-form__text">
                          <input
                            type="text"
                            className="nj-form-control"
                            name="piva"
                            id="contactFormPiva"
                            onFocus={onFocus}
                            onBlur={onBlur}
                            {...register("piva", {
                              required: {
                                value: true,
                                message: "Specificare una partita IVA.",
                              },
                            })}
                            error={errors.piva}
                          />
                          <label htmlFor="contactFormPiva" className="nj-label-floating">
                            Partita IVA
                          </label>
                        </div>
                      </div>
                      {errors.piva ? (
                        <span className="contact-form__warning">{errors.piva.message}</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      {/* <div className={groupClass('provincia')}>
                        <div className="contact-form__text">
                          <input type="text" className="nj-form-control" name="provincia" id="contactFormProvincia"
                            onFocus={onFocus} onBlur={onBlur} onChange={onChange} value={formData.provincia} />
                          <label htmlFor="contactFormProvincia" className="nj-label-floating">Provincia</label>
                        </div>
                      </div> */}
                      <NjSelect
                        id="contactFormProvincia"
                        placeHolder="Provincia"
                        name="provincia"
                        warning={errors.provincia}
                        options={provinceList.province}
                        option={watchValues.provincia}
                        register={register}
                        errorMessage="Specificare la provincia di fornitura."
                      />
                      {errors.provincia ? (
                        <span className="contact-form__warning">{errors.provincia?.message}</span>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      {/* <div className={groupClass('servizio')}>
                        <div className="contact-form__text">
                          <input type="text" className="nj-form-control" name="servizio" id="contactFormServizio"
                            onFocus={onFocus} onBlur={onBlur} onChange={onChange} value={formData.serivizo} />
                          <label htmlFor="contactFormServizio" className="nj-label-floating">Servizio Richiesto</label>
                        </div>
                      </div> */}
                      <NjSelect
                        id="contactFormServizio"
                        placeHolder="Servizio Richiesto"
                        name="servizio"
                        warning={errors.serivizo}
                        options={serviziList.servizi}
                        option={watchValues.servizio}
                        register={register}
                        errorMessage="Specificare il servizio richiesto."
                      />
                      {errors.servizio ? (
                        <span className="contact-form__warning">{errors.servizio.message}</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <PrivacyCheckbox
                        register={register}
                        error={errors.privacy}
                        setPrivacyModalVisible={setPrivacyVisible}
                        text={mainImg?.content?.testoFlagPrivacy?.value}
                        theme="nj"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="nj-form-group">
                        <input type="hidden" name="groupId" value={FORM_GROUPID} />
                        <input type="hidden" name="companyId" value="10201" />
                        <input type="hidden" name="categoria" value="" />
                        <button type="submit" className="nj-btn nj-btn--primary" disabled={loading}>
                          {loading ? (
                            <>
                              Loading...
                              <div className="nj-spinner nj-spinner--xs ml-1" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            </>
                          ) : (
                            <span>Invia</span>
                          )}
                        </button>
                        {remoteError ? (
                          <span className="ml-3 contact-form__warning">{remoteError}</span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <PrivacyModal
                    show={privacyVisible}
                    setShow={setPrivacyVisible}
                    text={mainImg?.content?.testoFlagPrivacy?.content?.privacyDin?.value}
                  />
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withPreview(ContactForm);
export const contactFormFragment = graphql`
  fragment ContactFormFragment on LiferayContattiImpreseNew {
    liferayFields {
      siteId
      articleId
    }
    mainTitle {
      content {
        mainSubtitle {
          value
        }
      }
      value
    }
    mainImg {
      content {
        mailSx {
          value
        }
        subtitleSx {
          value
        }
        telephoneSx {
          value
        }
        titleSx {
          value
        }
        testoFlagPrivacy {
          value
          content {
            privacyDin {
              value
            }
          }
        }
      }
      node {
        gatsbyImageData(width: 343)
      }
    }
  }
`;
