import React from "react";
import { graphql } from "gatsby";
import HubItem from "../elements/hub-item";
import withPreview from "../../utility/with-preview";

const Hub1Item = ({ data }) => {
  const { titolo, img: immagine, linkedContent } = data;

  return (
    <section className="hub-1-item hub wrapper-container px-0">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <HubItem
              background={immagine?.node}
              titolo={titolo?.value}
              link={linkedContent?.value?.link || "/" + linkedContent?.value?.externalLink?.value}
              bar={true}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(Hub1Item);
export const fragment = graphql`
  fragment Hub1ItemFragment on LiferayHub1Item {
    liferayFields {
      siteId
      articleId
    }
    titolo {
      value
    }
    img: immagine {
      node {
        gatsbyImageData(width: 720)
      }
    }
    linkedContent {
      value {
        ... on LiferayJskLayoutPaginaGenerica {
          link
        }
        ... on LiferayJskLayoutPaginaPrivacy {
          link
        }
        ... on LiferayJskPaginaLink {
          externalLink: link {
            value
          }
        }
      }
    }
  }
`;
