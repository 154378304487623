import React, { useState, useEffect, useMemo } from "react";
import { graphql } from "gatsby";
import InfoDialog from "../info-dialog";
import withPreview from "../../utility/with-preview";
import SelectAutocomplete from "../elements/select-autocomplete";
import elencoComuni from "../../data/gi_db_comuni/gi_comuni.json";

const FORM_VENDIBILITA_BASE_URL = process.env.GATSBY_FORM_VENDIBILITA;

const VerificaCopertura = ({ data }) => {
  const { title, btnLabel, successMessage, failMessage } = data;
  const [comuni, setComuni] = useState([]);
  const [comune, setComune] = useState("");
  const [modalMsg, setModalMsg] = useState();
  const [copertura, setCopertura] = useState(false);
  const [codiciComuni, setCodiciComuni] = useState(new Map());

  useEffect(() => {
  
    const fetchComuni = async () => {

      const listaComuni = [];      
      Array.from(elencoComuni).forEach(function (_comune) {     
          let optionKey = _comune.denominazione_ita.toUpperCase()+" ("+_comune.sigla_provincia+")";
          listaComuni.push(optionKey);          
          setCodiciComuni(codiciComuni.set(optionKey,_comune.codice_istat));
      });
      setComuni(listaComuni);
    };
    fetchComuni();
  }, []);

  const verificaCopertura = async () => {
    try {
      
      const codiceComune = codiciComuni.get(comune.toUpperCase());
      
      setCopertura(false);
      const url = `${FORM_VENDIBILITA_BASE_URL}/contatti.contact/get-copertura-b2-c/params/${codiceComune}`;
      const response = await fetch(url);
      if (response.ok) {
        const coperturaResp = await response.json();
        const comperturaData = JSON.parse(coperturaResp);
        if (comperturaData.copertura === "Si") {
          setCopertura(true);
          setModalMsg(successMessage.value);
        } else {
          setCopertura(false);
          setModalMsg(failMessage.value);
        }
      } else {
        throw new Error(`Error calling ${url}: ${response.status} ${response.statusText}`);
      }
    } catch (err) {
      console.error("err in verificaCopertura: ", err);
    }
  };

  const options = useMemo(
    () =>
      comuni.map((item) => ({
        value: item,
        label: item,
      })),
    [comuni]
  );

  return (
    <section className="section verifica-copertura">
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <div className="verifica-copertura__title">{title.value}</div>
          </div>
          <div className="col-sm-4 d-flex align-items-center">
            {!!comuni?.length && (
              <SelectAutocomplete
                value={comune}
                onChange={(e) => setComune(e)}
                options={options}
                placeholder="Il mio comune"
              />
            )}
            <button className="verifica-copertura__cta ml-2" onClick={verificaCopertura}>
              {btnLabel.value}
            </button>
          </div>
        </div>
      </div>
      <InfoDialog
        show={!!modalMsg}
        setShow={setModalMsg}
        text={modalMsg}
        link={copertura && successMessage.content?.link?.value}
        label={copertura && successMessage.content?.labelLink?.value}
      />
    </section>
  );
};

export default withPreview(VerificaCopertura);
export const verificaCoperturaFragment = graphql`
  fragment JskVerificaCoperturaFragment on LiferayJskVerificaCopertura {
    liferayFields {
      siteId
      articleId
    }
    title {
      value
    }
    btnLabel {
      value
    }
    successMessage {
      value
      content {
        link {
          value
        }
        labelLink {
          value
        }
      }
    }
    failMessage {
      value
    }
  }
`;
