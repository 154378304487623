import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image";
import classNames from "classnames";
import Slider from "react-slick";
import { Link } from "../link";
import Icon from "../elements/icon-svg";
import withPreview from "../../utility/with-preview";

const settings = {
  arrows: false,
  dots: false,
  infinite: true,
  // autoplay: true,
  slidesToShow: 4,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2.3,
        slidesToScroll: 2,
      },
    },
  ],
};

const Hero = ({ data: { mainImage } }) => {
  const slides = mainImage.content.boxTitle;

  return (
    <div
      className={classNames("hero d-flex flex-column justify-content-center", {
        "hero--menu": slides,
      })}
    >
      <Img
        image={getImage(mainImage.node)}
        alt={mainImage.node.description || ""}
        className="hero__img"
      />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="hero__content">
              <h1
                dangerouslySetInnerHTML={{ __html: mainImage.content.mainTitle.value }}
                className="hero__title text-white"
              ></h1>
            </div>
          </div>
        </div>
      </div>
      {slides ? (
        <div className="hero__menu w-100 mx-auto">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 pr-0 pr-lg-2">
                <Slider className="hero__menu-carousel" {...settings}>
                  {slides.map((item, i) => (
                    <div key={i}>
                      <div
                        className={classNames("hero__menuitem", {
                          "hero__menuitem--first": i === 0,
                        })}
                      >
                        {item.value === "sport" ? (
                          <Icon name="sport" cls="hero__icon mb-lg-1" />
                        ) : (
                          <i
                            className={`nj-icon nj-icon-${item.content.boxIcon.value} mb-lg-1`}
                          ></i>
                        )}
                        <h4 className="hero__menutitle">
                          <Link to={item.content.boxLink.value}>{item.value}</Link>
                        </h4>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.content.boxDescription.value }}
                          className="hero__menutext"
                        ></div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default withPreview(Hero);
export const heroFragment = graphql`
  fragment SiteHeaderHeroFragment on LiferaySlideHeaderHpImpreseNew {
    liferayFields {
      siteId
      articleId
    }
    mainImage {
      node {
        description
        gatsbyImageData(width: 1440)
      }
      content {
        mainTitle {
          value
        }
        boxTitle {
          value
          content {
            boxIcon {
              value
            }
            boxDescription {
              value
            }
            boxLink {
              value
            }
          }
        }
      }
    }
  }
`;
