import React from "react";
import classNames from "classnames";

const NavTabs = ({ tabs, selectedTab, setTab, className }) => {
  return (
    <ul className={classNames("nav-tabs d-flex justify-content-center", className)}>
      {tabs?.slice(0, 2)?.map((item, i) => (
        <li className="nav-tabs__item" key={i}>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <button
            className={classNames("nav-tabs__link", {
              "nav-tabs__link--selected": selectedTab === i,
              "border-left-0": i > 0,
              "nav-tabs__link--gradient":
                item?.content?.coloreBottone1?.value && item?.content?.coloreBottone2?.value,
            })}
            name={i}
            dangerouslySetInnerHTML={{ __html: item?.value }}
            onClick={() => setTab(i)}
            style={{
              "--borderColor": item?.content?.coloreBordoBottone?.value,
              "--color1": item?.content?.coloreBottone1?.value,
              "--color2": item?.content?.coloreBottone2?.value,
            }}
          />
        </li>
      ))}
    </ul>
  );
};

export default NavTabs;
