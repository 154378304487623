import React, { useState } from "react";
import { graphql } from "gatsby";
import classNames from "classnames";
import withPreview from "../../utility/with-preview";

const Accordion = ({ data }) => {
  const { intestazione, accordionTitolo } = data;
  const [open, setOpen] = useState(-1);

  return (
    <section className="section wrap">
      <div className="container-fluid">
        {intestazione?.value && (
          <div className="row">
            <div className="col-12">
              <div
                className="section__title text-center"
                dangerouslySetInnerHTML={{ __html: intestazione.value }}
              />
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12">
            <ul className="accordion">
              {accordionTitolo?.map((item, i) => (
                <li
                  key={i}
                  className={classNames("accordion__item", {
                    "accordion__item--open": open === i,
                  })}
                >
                  <button
                    className="accordion__title"
                    onClick={() => setOpen(open === i ? -1 : i)}
                    aria-label={open === i ? "Chiudi" : "Apri"}
                  >
                    <span className="accordion__icon"></span>
                    <span dangerouslySetInnerHTML={{ __html: item?.value }} />
                  </button>
                  <div className="accordion__content">
                    <div dangerouslySetInnerHTML={{ __html: item?.content?.corpo?.value }} />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(Accordion);
export const fragment = graphql`
  fragment AccordionFragment on LiferayAccordion {
    liferayFields {
      siteId
      articleId
    }
    intestazione {
      value
    }
    accordionTitolo: titolo {
      value
      content {
        corpo {
          value
        }
      }
    }
  }
`;
