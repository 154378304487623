import React, { useState } from "react";
import { graphql } from "gatsby";
import classNames from "classnames";
import withPreview from "../../utility/with-preview";

const InfoTecnicheProdotto = ({ data }) => {
  const { introduzione, titolo1, info1, titolo2, info2, titolo3, info3 } = data;
  const [activeTab, setActiveTab] = useState(0);

  return (
    <section className="section wrapper-container">
      <div className="info-tecniche-prodotto">
        <div className="info-tecniche-prodotto__header">
          {introduzione?.value && (
            <div
              className="info-tecniche-prodotto__introduzione"
              dangerouslySetInnerHTML={{ __html: introduzione.value }}
            />
          )}
          <div className="info-tecniche-prodotto__tabs">
            {titolo1?.value && (
              <button
                className={classNames(
                  "info-tecniche-prodotto__tab",
                  activeTab === 0 && "info-tecniche-prodotto__tab--active"
                )}
                onClick={() => {
                  setActiveTab(0);
                }}
              >
                {titolo1.value}
              </button>
            )}
            {titolo2?.value && (
              <button
                className={classNames(
                  "info-tecniche-prodotto__tab",
                  activeTab === 1 && "info-tecniche-prodotto__tab--active"
                )}
                onClick={() => {
                  setActiveTab(1);
                }}
              >
                {titolo2.value}
              </button>
            )}
            {titolo3?.value && (
              <button
                className={classNames(
                  "info-tecniche-prodotto__tab",
                  activeTab === 2 && "info-tecniche-prodotto__tab--active"
                )}
                onClick={() => {
                  setActiveTab(2);
                }}
              >
                {titolo3.value}
              </button>
            )}
          </div>
        </div>
        {info1?.value && (
          <div
            className={classNames(
              "info-tecniche-prodotto__tabs-content",
              activeTab !== 0 && "info-tecniche-prodotto__tabs-content--hide"
            )}
          >
            <div dangerouslySetInnerHTML={{ __html: info1.value }} />
          </div>
        )}
        {info2?.value && (
          <div
            className={classNames(
              "info-tecniche-prodotto__tabs-content",
              activeTab !== 1 && "info-tecniche-prodotto__tabs-content--hide"
            )}
          >
            <div dangerouslySetInnerHTML={{ __html: info2.value }} />
          </div>
        )}
        {info3?.value && (
          <div
            className={classNames(
              "info-tecniche-prodotto__tabs-content",
              activeTab !== 2 && "info-tecniche-prodotto__tabs-content--hide"
            )}
          >
            <div dangerouslySetInnerHTML={{ __html: info3.value }} />
          </div>
        )}
      </div>
    </section>
  );
};

export default withPreview(InfoTecnicheProdotto);
export const fragment = graphql`
  fragment InfoTecnicheProdottoFragment on LiferayInfoTecnicheProdotto {
    liferayFields {
      articleId
      siteId
    }
    introduzione {
      value
    }
    titolo1 {
      value
    }
    info1 {
      value
    }
    titolo2 {
      value
    }
    info2 {
      value
    }
    titolo3 {
      value
    }
    info3 {
      value
    }
  }
`;
