import React from "react";
import Modal from "react-overlays/Modal";
import useBlockScroll from "../utility/useBlockScroll";

const isYoutubeEmbed = (video) => {
  return (
    /http[s]?:\/\/www\.youtube\.com\/embed\/(.+)?/.test(video) ||
    /http[s]?:\/\/youtu\.be\/(.+)[/]?/.test(video)
  );
};
const formatYoutubeEmbed = (video) => {
  const matches1 = /http[s]?:\/\/www\.youtube\.com\/embed\/(.+)[/]?/.exec(video);
  const matches2 = /http[s]?:\/\/youtu\.be\/(.+)[/]?/.exec(video);
  const videoCode =
    matches1 && matches1.length ? matches1[1] : matches2 && matches2.length ? matches2[1] : null;
  if (videoCode) {
    return `https://www.youtube.com/embed/${videoCode}?autoplay=1`;
  }
};

const VideoModal = ({ show, handleClose, video }) => {
  useBlockScroll(show);

  return (
    <Modal show={show} onHide={handleClose} className="video-modal">
      <div className="video-modal__content d-flex justify-content-center align-items-center">
        <button onClick={handleClose} className="video-modal__close icon-btn" aria-label="Chiudi">
          <i className="nj-icon nj-icon-cross nj-icon--white size-48"></i>
        </button>
        {isYoutubeEmbed(video) ? (
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              width="100%"
              height="100%"
              src={formatYoutubeEmbed(video)}
              frameBorder="0"
              title="Video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={true}
            ></iframe>
          </div>
        ) : (
          <video
            muted={true}
            playsInline={true}
            autoPlay={true}
            onEnded={handleClose}
            className="video-modal__video"
          >
            <source type="video/mp4" src={video} />
          </video>
        )}
      </div>
    </Modal>
  );
};

export default VideoModal;
