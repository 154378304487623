import React, { useEffect, useRef, useState } from "react";
import { graphql } from "gatsby";
import { useForm } from "react-hook-form";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import withPreview from "../../utility/with-preview";
import FormComune from "../form/form-comune";
import PrivacyModal from "../form/privacy-modal";
import { FormTextArea } from "../form/form-input";
import Input from "../form/form-input-casa";
import PrivacyCheckbox from "../form/privacy-checkbox";
import { submitFormConsulenza } from "../../utility/config-form-consulenza";
//const FORM_VENDIBILITA_BASE_URL = process.env.GATSBY_FORM_VENDIBILITA;

const FormConsulenza = ({ data, location }) => {
  const {
    titolo,
    sottotitolo,
    sottotitoloDopoVerificaComune,
    image,
    testoFlagPrivacy,
    thankYouMessage,
  } = data;
  const [comune, setComune] = useState();
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [response, setResponse] = useState();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, touchedFields },
  } = useForm({ mode: "onTouched" });
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [shownComune, setShownComune] = useState(false);

  useEffect(() => {
    setValue("comune", comune);
    if (!shownComune && comune) {
      setShownComune(true);
    }
  }, [setValue, comune, shownComune]);

  const responseRef = useRef();

  const submit = async (values) => {
    console.log(values);
    if (!executeRecaptcha) {
      console.warn("Execute recaptcha not yet available");
      return;
    }
    const token = await executeRecaptcha("formImprese");

    try {
      const result = await submitFormConsulenza({ ...values, grecaptcha: token }, location);
      console.log("Submit result: ", result);
      //typLeadStep(prodottoInteressato?.value, location);
      setResponse("ok");
    } catch (err) {
      console.error("Error submit: ", err);
      setResponse("ko");
    }
    if (responseRef.current) {
      responseRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <span className="scroll-anchor" id="FormContattiAnchor" />
      <section className="section form-contatti section--lightblue section--style">
        <div className="container-fluid wrapper-container px-3">
          {titolo?.value && (
            <div className="row">
              <div className="col-12 text-center">
                <h2 className="section__title section__title--banded">{titolo.value}</h2>
              </div>
            </div>
          )}
          {!comune ? (
            <FormComune title={sottotitolo?.value} handleComune={setComune} tipologia="b2c" />
          ) : (
            <>
              {response !== "ok" && (
                <div className="row mt-3">
                  <div className="col-md-9">
                    <form
                      className="form form-contatti__form form-contatti__form--guasto-caldaia pt-0"
                      onSubmit={handleSubmit(submit)}
                    >
                      {sottotitoloDopoVerificaComune?.value && (
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="form__title"
                              dangerouslySetInnerHTML={{
                                __html: sottotitoloDopoVerificaComune.value,
                              }}
                            />
                          </div>
                        </div>
                      )}
                      <div className="row">
                        <div className="col-md-6 mb-md-0">
                          <Input
                            name="name"
                            register={register}
                            validation={{
                              required: {
                                value: true,
                                message: "Per favore compila questo campo per farti contattare.",
                              },
                              pattern: {
                                value: /^[a-z\u00C0-\u017F'\s]+$/i,
                                message: "Inserire solo lettere e spazi e non più di 40 caratteri",
                              },
                              maxLength: {
                                value: 40,
                                message: "Inserire solo lettere e spazi e non più di 40 caratteri",
                              },
                            }}
                            placeholder="Nome"
                            iconName="user-field"
                            error={errors.name}
                            touched={touchedFields.name}
                            check={true}
                            checkError={true}
                          />
                        </div>
                        <div className="col-md-6">
                          <Input
                            name="surname"
                            register={register}
                            validation={{
                              required: {
                                value: true,
                                message: "Per favore compila questo campo per farti contattare.",
                              },
                              pattern: {
                                value: /^[a-z\u00C0-\u017F'\s]+$/i,
                                message: "Inserire solo lettere e spazi e non più di 40 caratteri",
                              },
                              maxLength: {
                                value: 40,
                                message: "Inserire solo lettere e spazi e non più di 40 caratteri",
                              },
                            }}
                            placeholder="Cognome"
                            iconName="user-field"
                            error={errors.surname}
                            touched={touchedFields.surname}
                            check={true}
                            checkError={true}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mb-md-0">
                          <Input
                            register={register}
                            name="email"
                            id="partnerFormEmail"
                            placeholder="Indirizzo E-Mail"
                            iconName="envelope"
                            validation={{
                              required: {
                                value: true,
                                message: "Specificare un indirizzo email.",
                              },
                              pattern: {
                                value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                message: "Specificare un indirizzo email valido.",
                              },
                            }}
                            error={errors.email}
                            touched={touchedFields.email}
                            check={true}
                            checkError={true}
                          />
                        </div>
                        <div className="col-md-6">
                          <Input
                            name="tel"
                            register={register}
                            validation={{
                              required: {
                                value: true,
                                message: "Specificare un numero di telefono.",
                              },
                              pattern: {
                                value: /^[0-9]*$/,
                                message: "Specificare un numero di telefono valido.",
                              },
                            }}
                            placeholder="Telefono"
                            iconName="phone"
                            error={errors.tel}
                            touched={touchedFields.tel}
                            check={true}
                            checkError={true}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Input
                            name="comune"
                            register={register}
                            iconName="map-marker-alt"
                            readOnly={true}
                            touched={true}
                            check={true}
                            checkError={true}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <FormTextArea
                            name="messaggio"
                            placeholder="Messaggio"
                            register={register}
                            iconName="align-left"
                            validation={{
                              required: {
                                value: true,
                                message: "Lasciare un messagio.",
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <PrivacyCheckbox
                            register={register}
                            error={errors.privacy}
                            setPrivacyModalVisible={setShowPrivacyModal}
                            text={testoFlagPrivacy?.value}
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12 text-center">
                          <button type="submit" className="form__submit">
                            Ricontattami
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  {image?.node && (
                    <div className="d-none d-md-block col-md-3">
                      <GatsbyImage image={getImage(image.node)} alt="" />
                    </div>
                  )}
                </div>
              )}
              {response && (
                <div className="row mt-3">
                  <div className="col-12 text-center">
                    <span ref={responseRef} className="scroll-anchor"></span>
                    {response === "ok" ? (
                      thankYouMessage?.value ? (
                        <div
                          className="form-contatti__response"
                          dangerouslySetInnerHTML={{
                            __html: thankYouMessage.value,
                          }}
                        />
                      ) : (
                        <div className="form-contatti__response">
                          <p>Grazie per averci lasciato i tuoi dati.</p>
                          <p>Ti contatteremo a breve.</p>
                        </div>
                      )
                    ) : (
                      <div className="form-contatti__response">
                        <p>Servizio non disponibile, riprova più tardi.</p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
          <PrivacyModal
            show={showPrivacyModal}
            setShow={setShowPrivacyModal}
            text={testoFlagPrivacy?.content?.privacyDin?.value}
          />
        </div>
      </section>
    </>
  );
};

export default withPreview(FormConsulenza);
export const fragment = graphql`
  fragment JskFormConsulenzaFragment on LiferayJskFormConsulenza {
    liferayFields {
      siteId
      articleId
    }
    titolo {
      value
    }
    sottotitolo {
      value
    }
    sottotitoloDopoVerificaComune {
      value
    }
    testoFlagPrivacy {
      value
      content {
        privacyDin {
          value
        }
      }
    }
    thankYouMessage {
      value
    }
    image {
      node {
        gatsbyImageData(width: 326)
      }
    }
  }
`;
