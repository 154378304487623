import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image";
import withPreview from "../../utility/with-preview";
import { Link } from "../link";
import classNames from "classnames";

const CardWrapper = ({ link, children }) => {
  return link && link !== "#" ? (
    <Link to={link} className="tiles-list__card p-5 d-flex flex-column">
      {children}
    </Link>
  ) : (
    <div className="tiles-list__card p-5 d-flex flex-column">{children}</div>
  );
};

const TilesList = ({ data: { mainTitle, cardImage } }) => {
  return (
    <section className={classNames("section section--padded tiles-list")}>
      <div className="container-fluid">
        {mainTitle?.value && (
          <div className="row">
            <div className="col-12">
              <div className="section__title text-center">{mainTitle.value}</div>
            </div>
          </div>
        )}
        <div className="row">
          {cardImage?.map((card, i) => (
            <div className="col-sm-6 col-lg-4 px-0 text-white" key={i}>
              <CardWrapper link={card?.content?.cardLink?.value}>
                {card?.node && (
                  <Img
                    className="tiles-list__img"
                    image={getImage(card.node)}
                    alt={card?.node?.description || ""}
                  />
                )}
                <div className="tiles-list__content">
                  {card?.content?.cardDescription?.value && (
                    <h3>{card.content.cardDescription.value}</h3>
                  )}
                  {card?.content?.cardLink?.value && card.content.cardLink.value !== "#" && (
                    <button className="nj-btn position-relative nj-btn--light mt-5">
                      {card?.content?.btnText?.value || "Leggi"}
                    </button>
                  )}
                </div>
              </CardWrapper>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default withPreview(TilesList);
export const tilesListFragment = graphql`
  fragment TilesListFragment on LiferayTilesList {
    liferayFields {
      siteId
      articleId
    }
    mainTitle {
      value
    }
    cardImage {
      node {
        gatsbyImageData(width: 480)
        description
      }
      content {
        cardMinitext {
          value
        }
        cardLink {
          value
        }
        cardDescription {
          value
        }
        btnText {
          value
        }
      }
    }
  }
`;
