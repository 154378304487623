import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { Link } from "../link";
import withPreview from "../../utility/with-preview";

const HeroIcons = ({ data: { mainImage, mainTitle, iconCode } }) => {
  return (
    <div className="hero-icons">
      <div className="hero-icons__wrapper d-flex flex-column justify-content-center pt-lg-5 pb-6">
        <Img
          image={getImage(mainImage.node)}
          alt={mainImage.node.description || ""}
          className="hero-icons__img"
        />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1
                className="hero-icons__title mb-2"
                dangerouslySetInnerHTML={{ __html: mainTitle.value }}
              ></h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5">
              {mainTitle.content && mainTitle.content.mainSubtitle ? (
                <div
                  className="hero-icons__subtitle pb-4"
                  dangerouslySetInnerHTML={{ __html: mainTitle.content.mainSubtitle.value }}
                ></div>
              ) : null}
              {mainTitle.content && mainTitle.content.btnText && mainTitle.content.btnText.value ? (
                <div className="d-flex">
                  <Link
                    to={mainTitle.content.btnText.content.btnLink.value}
                    className="nj-btn nj-btn--primary nj-btn--lg"
                  >
                    {mainTitle.content.btnText.value}
                  </Link>
                </div>
              ) : null}
            </div>
            {iconCode ? (
              <div className="col-lg-6 offset-lg-1 d-flex flex-wrap justify-content-between justify-content-lg-start mt-4 mt-lg-0 pr-md-0">
                <div className="row w-100">
                  {iconCode.map((icon, i) => (
                    <div
                      key={i}
                      className={`hero-icons__icon mt-3 col-6 col-md-3 col-lg-4 px-lg-1${
                        i === 0 ? " offset-lg-4" : ""
                      }`}
                    >
                      <div
                        className={classNames(
                          "t-kilo font-weight-bold text-blue-corporate mb-2",
                          !icon.content?.overtitleIcon?.value && "mt-2 pt-1"
                        )}
                        dangerouslySetInnerHTML={{
                          __html: icon.content?.overtitleIcon?.value
                            ? icon.content.overtitleIcon.value
                            : "",
                        }}
                      />
                      {icon.value && (
                        <i className={`nj-icon nj-icon-${icon.value} nj-icon--white size-72`}></i>
                      )}
                      {icon.content?.textIcon?.value ? (
                        <div className="">{icon.content.textIcon.value}</div>
                      ) : (
                        <div className="hero-icons__icon-title-placeholder"></div>
                      )}
                      {icon.content?.overtitleIcon ? (
                        <div
                          className="mt-1"
                          dangerouslySetInnerHTML={{ __html: icon.content.overtitleIcon.value }}
                        ></div>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withPreview(HeroIcons);
export const heroIconsFragment = graphql`
  fragment SlideHeaderIconeNewFragment on LiferaySlideHeaderIconeNew {
    liferayFields {
      siteId
      articleId
    }
    mainImage {
      node {
        gatsbyImageData(width: 1440)
      }
    }
    mainTitle {
      content {
        btnText {
          value
          content {
            btnLink {
              value
            }
          }
        }
        mainSubtitle {
          value
        }
      }
      value
    }
    iconCode {
      value
      content {
        textIcon {
          value
        }
        overtitleIcon {
          value
        }
      }
    }
  }
`;
